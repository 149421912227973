<template>
    <el-dialog
        :visible.sync="open"
        :close-on-click-modal="false"
        style="max-width: 600px;"
        class="tc-modal mx-auto"
    >
        <div
            class="container-fluid bg-white card application-card mk-upload-rule p-3 mb-0"
        >
            <div class="row">
                <div class="col-12 position-relative">
                    <div class="app-card-decoration"></div>
                    <h3
                        class="my-3"
                        style="ont-family: aktiv-grotesk-extended, sans-serif;"
                    >
                        ID Verification Photo Requirements
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <ul class="rules-list">
                        <li>
                            Nominated Photo ID
                            <strong
                                >held up next ot your face clearly
                                visible</strong
                            >
                            (not cut off)
                        </li>
                        <li>
                            Clear, focused image with good lighting
                        </li>
                        <li>
                            Face centered and lookign at the camera straight on,
                            not tilted in any direction
                        </li>
                        <li>
                            Hair off your face, so that the edges of your face
                            are visible
                        </li>
                        <li>
                            Eyes open, mouth closed
                        </li>
                        <li>
                            Neutral expression (not smiling, laughing or
                            frowning)
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        openModal: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            open: this.openModal || false
        };
    },
    methods: {},
    watch: {
        open(to, from) {
            this.$emit("update:openModal", to);
        },
        openModal(to, from) {
            this.open = to;
        }
    }
};
</script>

<style lang="sass" scoped>
.dialog-footer
    display: flex
    align-items: center
    justify-content: flex-start

p, li, h3
    overflow-wrap: break-word
    word-wrap: break-word

    -ms-word-break: break-all
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-word

li
    text-align: justify
</style>
