<template>
    <div>
        <div class="container-fluid px-0 mk-leadform">
            <div class="row py-3">
                <div
                    class="col-10 d-flex justify-content-between align-items-center"
                >
                    <h1 class="mb-0">Lead Form</h1>
                </div>
                <div
                    class="col-2 d-flex justify-content-end align-items-center"
                >
                    <!-- <el-button
                        type="danger"
                        @click="deleteLeadForm"
                        v-if="leadForm.id"
                    >
                        Delete
                    </el-button> -->
                    <el-button type="primary" @click="handleSubmit">
                        Save
                    </el-button>
                </div>
            </div>
        </div>

        <div class="container-fluid bg-white card">
            <div class="row py-3">
                <div class="form-group col-md-6">
                    <label for="name">Form Name</label>
                    <el-input
                        placeholder="Name"
                        v-model="leadForm.name"
                    ></el-input>
                </div>
                <!-- <div class="form-group col-md-4">
                    <div class="input-group">
                        <label for="status">Sales Representative</label>
                        <el-input
                            placeholder="Sales Representative Name"
                            v-model="leadForm.sales_representative"
                        ></el-input>
                        <el-select
                            v-model="leadForm.user_id"
                            class="w-100"
                            placeholder="Filter Supplier Status"
                        >
                            <el-option
                                v-for="user in users"
                                :key="user.id"
                                :value="user.id"
                                :label="`${user.first_name} ${user.last_name}`"
                            ></el-option>
                        </el-select>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import Supplier from "../../models/Supplier";
import LeadForm from "../../models/LeadForm";
export default {
    props: {
        users: {
            type: Array,
            required: true
        },
        supplier: {
            type: Object,
            required: true
        },
        initial_lead_form: {
            type: Object,
            default: () => {
                return {
                    name: "",
                    sales_representative: "",
                    user_id: null
                };
            }
        }
    },
    data() {
        return {
            leadForm: this.initial_lead_form
        };
    },
    methods: {
        handleSubmit() {
            new LeadForm(this.leadForm)
                .for(new Supplier(this.supplier))
                .save()
                .then(res => {
                    this.$message({
                        type: "success",
                        message: "Saved"
                    });

                    if (!this.initial_lead_form.id) {
                        window.location.replace(
                            `/admin/suppliers/${this.supplier.id}/lead_forms/${res.id}/edit`
                        );
                        window.history.pushState(
                            null,
                            null,
                            `/admin/suppliers/${this.supplier.id}/lead_forms/${res.id}/edit`
                        );
                    }
                })
                .catch(err => {
                    console.error(err);
                    var errorText = "";
                    _.forEach(err.response.data.errors, function(value) {
                        errorText += "" + value + " ";
                    });
                    this.$message({
                        type: "error",
                        message: errorText
                    });
                });
        },
        async deleteLeadForm() {
            try {
                await new LeadForm(this.leadForm)
                    .for(new Supplier(this.supplier))
                    .delete();
                window.location.replace(
                    `/admin/suppliers/${this.supplier.id}/edit`
                );
                window.history.pushState(
                    null,
                    null,
                    `/admin/suppliers/${this.supplier.id}/edit`
                );
            } catch (err) {
                console.error(err);
            }
        }
    }
};
</script>
