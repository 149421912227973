<template>
    <div class="mk-fe-dash-appDashboard">
        <div class="row">
            <div class="col">
                <h3>Applications</h3>
            </div>
        </div>
        <div class="row mk-fe-dashboard">
            <div class="col-12">
                <paginated-table
                    v-if="applications.data"
                    :tableData="tableData"
                    :paginatedData="applications"
                    :initial_page="5"
                    @pagination-change-page="getData"
                >
                    <template slot="after-search-slot">
                        <div
                            class="d-flex justify-content-end align-items-center ml-3"
                        >
                            <el-date-picker
                                v-model="dateRange"
                                @change="getData(1)"
                                type="daterange"
                                range-separator="-"
                                format="dd/MM/yyyy"
                                value-format="yyyy-MM-dd"
                                start-placeholder="Start date"
                                end-placeholder="End date"
                            >
                            </el-date-picker>
                        </div>
                    </template>
                </paginated-table>
            </div>
        </div>
    </div>
</template>
<script>
import ApplicationDashboard from "../../../supplier/models/ApplicationDashboard";
import Supplier from "../../../supplier/models/Supplier";
export default {
    props: {
        initial_supplier: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            applications: {},
            page: 1,
            loading: false,
            supplier: this.initial_supplier,
            search: null,
            dateRange: null,
            tableData: {
                columns: [
                    {
                        label: "First Name",
                        name: "customer.first_name",
                        sortable: false
                    },
                    {
                        label: "Last Name",
                        name: "customer.last_name",
                        sortable: false
                    },
                    { label: "Email", name: "customer.email", sortable: false },
                    { label: "Phone", name: "customer.phone", sortable: false },
                    {
                        label: "Amount",
                        name: "lead.requested_amount",
                        sortable: false,
                        money: true
                    },
                    {
                        label: "Address",
                        name: "property_address"
                    },
                    {
                        label: "Source",
                        name: "lead.source",
                        badge: true,
                        badge_coloring: value => {
                            return value.lead && value.lead.source
                                ? "#18206F"
                                : "#FF968D";
                        },
                        text_color: "#fff",
                        conditional_value: value => {
                            return value.lead && value.lead.source
                                ? "elepay"
                                : "Fast Track";
                        },
                        sortable: false
                    },
                    {
                        label: "Status",
                        name: "application_status.status",
                        sortable: false
                    },
                    {
                        label: "Estimated Start Date",
                        name: "lead.start_date",
                        whole_date: true,
                        sortable: false
                    },
                    {
                        label: "Created At",
                        name: "created_at",
                        datetime: true,
                        sortable: true
                    }
                ],
                showSearch: true,
                showPerPage: true,
                overrideView: true
            }
        };
    },
    async mounted() {
        this.getData(1);
    },
    methods: {
        getData(
            page = 1,
            sortColumn = null,
            query = null,
            per_page = 5,
            desc = false
        ) {
            this.page = page ? page : 1;
            let applications = ApplicationDashboard.page(this.page)
                .include("customer", "supplier", "application_status", "lead")

                .params({
                    per_page: per_page
                });
            if (sortColumn) {
                applications.orderBy(
                    (desc ? "-" : "") +
                        (sortColumn.sort_name || sortColumn.name)
                );
            } else {
                applications.orderBy("-created_at");
            }

            if (query) applications.where("where_like", query);

            if (this.dateRange)
                applications.where("created_between", this.dateRange.join(","));

            applications.append("property_address");

            applications
                .for(new Supplier(this.supplier))
                .get()
                .then(res => {
                    this.loading = false;
                    this.applications = res;
                })
                .catch(errors => {
                    this.loading = false;
                    console.error(errors);
                });
        }
    }
};
</script>
