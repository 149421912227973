<template >
    <div>
        <div
            class="container-fluid bg-white card application-card mk-terms-conds p-3 mb-0"
        >
            <div class="row">
                <div class="col-12 position-relative">
                    <div class="app-card-decoration"></div>
                    <h3 class="my-3" style="font-family: aktiv-grotesk-extended, sans-serif;">Terms and Conditions</h3>
                </div>
                <div class="col-12">
                    <p class="app-card-intro" style="margin-bottom: 60px;">Kindly read through the terms and conditions carefully, and click the button at the end to accept. You must scroll to the end of the terms and conditions to continue.</p>
                </div>
            </div>

            <div class="row">
                <div
                    class="col-12 col-md-12 tc-body"
                    id="tc_body"
                    ref="tc_body"
                >
                    <p><strong>Elevare – Promote Customer Agreement </strong></p>
                    <p>Standard Terms & Conditions </p>
                    
                    <p><strong>1. THE AGREEMENT BETWEEN ELEVARE AND YOU</strong> </p>
                    <hr />
                    
                    <p>1.1. Your Agreement with Elevare ("the Agreement", "this Agreement" or
                    "your Agreement") comprises the Information Schedule (in
                    which you have provided details and in which you have agreed
                    to the terms in that Information Schedule), Elevare’s
                    Standard Terms and Conditions and Elevare’s Additional
                    Policies relating to privacy, security or otherwise.</p>

                    <p>1.2. Each of Elevare and you may sign this Agreement electronically and
                    you consent to providing your signature electronically for
                    the purposes of this Agreement, any authorities under the
                    Privacy Act and any other document or matter relating to
                    this Agreement. </p>
                    
                    <p><strong>2. INFORMATION AND PROCESS</strong></p>
                    <hr />

                    <p>2.1. You must provide anyinformation or documentation reasonably requested by us or
                    the Supplier. You represent and warrant that the information
                    provided by you is complete, accurate, up to date, and not
                    misleading. If the information provided (including the
                    details in the Information Schedule) becomes inaccurate at
                    any time, then you must notify us of the inaccuracy, and you
                    must provide us with accurate and updated information. </p>
                    
                    <p>2.2. You irrevocably authorise us (and our Team Members) to conduct
                    any assessment, investigation or review of you or your
                    circumstances (including obtaining a credit report or
                    similar about you) for the purpose of checking or verifying
                    information provided by you, deciding whether or not to
                    provide any funding to or for you or for any other purpose
                    relating to this Agreement. </p>
                    
                    <p> 2.3. You irrevocably authorise us to
                    disclose any information relating to you to the extent
                    reasonably required for our purposes or to the extent
                    required by any law. </p>
                    
                    <p> 2.4. You acknowledge and agree that we may
                    report any negative activity by you in relation to moneys
                    owing to us (including late payments, missed payments,
                    defaults etc.) to any credit reporting agency. </p>
                    
                    <p><strong>3. THE ELEVARE PROCESS</strong> </p>
                    <hr />
                    <p>
                         3.1. We may choose to approve or not approve your request
                    for funding of an Elevare Purchase, and we may withdraw any
                    approval (at our discretion) at any time before we provide
                    funding. </p>
                    
                    <p> 3.2. If we approve your request, then you will receive
                    confirmation of such approval (by notice from us) and we
                    will be treated as entering into this Agreement. </p>
                    
                    <p>  3.4. If we approve your request and agree to provide funding for an
                    Elevare Purchase: </p>
                    
                    <p>(a) we may advance the Approved Amount to the
                    Supplier before the Advance Date, pursuant to the terms of
                    an agreement between Elevare and the Supplier; and 
                    </p>

                    <p> (b) we will advance the Approved Amount to you on the Advance Date by
                    paying that amount to the Supplier or Suppliers on your
                    behalf. </p>
                    
                    <p><strong>4. YOU MUST PAY ELEVARE</strong> </p>
                    
                    <hr />
                    
                    <p>4.1. If you Order a product or service from a Supplier and we agree to fund that purchase:
                    (a) that Order will be treated as an Elevare Purchase; 
                    (b) you give us an unconditional and irrevocable direction (and
                    agreement) to pay (or cause its affiliate to pay) the
                    Supplier an amount equal to the Approved Amount for </p>
                    
                    <p>that Elevare Purchase to the Supplier or Suppliers in accordance
                    with the Information Schedule; and 
                    </p>

                    <p>  (c) you must pay the Approved Amount (together with any fees or charges payable by you) to
                    us in accordance with this Agreement. </p>
                    
                    <p> 4.2. You must pay the Approved Amount to us because: </p>
                    
                    <p>  (a) prior to the Advance Date,
                    that amount will be owing to us (as assignee of that debt
                    from the Supplier to us); and </p>
                    
                    <p> (b) after the Advance Date, that
                    amount will be owing to us (as lender of that Approved
                    Amount to you). </p>
                    
                    <p> 4.3. You must pay all moneys owing by you to us
                    on or before the Due Date for such payment and otherwise,
                    when we request you to do so. </p>
                    
                    <p><strong>5. DUE DATE FOR PAYMENT</strong> </p>

                    <hr />
                    
                    <p>
                        5.1. You must pay the Approved Amount together with any fees and charges
                        payable by you to us on or before the earlier of the
                        following: 
                    </p>
                    
                    <p>
                        (a) the Repayment Date (which is sixty-two (62) days
                    after the Advance Date); the date on which the sale of the
                    Property is completed; 
                    </p>
                    
                    <p>
                         (b) the date on which deposit monies
                    relating to sale of the Property are released to you; </p>
                    
                    <p>(c) the date on which the Property is withdrawn from sale; and the
                    date on which the Agency (if any) ceases to act as your
                    Agency. </p>
                    
                    <p>5.2. If you pay the Approved Amount to us before the
                    Advance Date, then that amount is paid on account of the
                    purchase price for your Elevare Purchase. </p>
                    
                    <p>5.3. If you pay the Approved Amount to us after the Advance Date, then that
                    amount is paid in repayment of the advance we make to you on
                    the Advance Date. </p>
                    
                    <p>5.4. You authorise us to charge (or withdraw)
                    the Approved Amount, our fees and any other amounts payable
                    by you to us (to the extent those amounts have not otherwise
                    been paid by you) to (or from) your Nominated Payment Source
                    on the relevant Due Date for payment. </p>
                    
                    <p> 5.5. You must pay all government duties, taxes and charges now, or in the future,
                    charged on or otherwise payable in relation to this
                    Agreement and you must pay any processing or other fees
                    imposed by any third party on or in relation to payments by you. 
                    You agree to make all payments you owe us under this Agreement in full, 
                    without any set-off or counterclaim. </p>


                    <p> 5.6. You irrevocably authorise and direct any agent, 
                        solicitor or other person acting for you in relation to 
                        a sale of the Property to pay all amounts owing to us under this 
                        Agreement to us from any monies any such person holds for you or on 
                        your behalf (relating to a sale of the Property). </p>


                    <p>
                        5.7. You direct any such person to rely upon provision of a signed copy of this
                        Agreement together with a written request for payment of an amount from Elevare
                        as evidence of your irrevocable direction and authority for such person to
                        pay the amount requested by Elevare.
                    </p>


                    <p><strong>6. OUR FEES AND CHARGES</strong> </p>
                    <hr />
                    
                    <p>
                       6.1. If you pay the Approved Amount to us prior to the Advance Date, you must also pay us our fee equal to 2% of the Approved Amount. 
                    </p>

                    <p>
                       6.2. If you pay the Approved Amount to us on or after the Advance Date, you must pay us our fees calculated as follows:
                    </p>

                    <p>
                       (a) the amount equal to 5% of the Approved Amount;
                    </p>

                    <p>
                        (b) subject to clause 4.3, interest in an amount equal to 0.9% multiplied by the Approved Amount; and
                    </p>

                    <p>
                        (c) any late fees and charges in accordance with this Agreement. 
                    </p>

                    <p>
                        6.3. If you repay all moneys you owe to us before the date which is fourteen (14) days after the Advance Date, we will charge our fees but we will not charge any interest under this Agreement.
                    </p>

                    <p><strong>7. SUPPLY OF PRODUCTS OR SERVICES </strong></p>
                    <hr />

                    <p>7.1. You acknowledge and agree that:</p>
                    <p>(a) Elevare does not supply the Supplier's products or services to you;</p>
                    <p>(b) any issue relating to a product or service provided by a Supplier to you is an issue between you and that Supplier and does not involve Elevare; and</p>

                    <p>(c) irrespective of any issue between you and a Supplier, you must pay the Approved Amount to Elevare</p>

                    <p>
                        We are not liable for any refusal or failure of a Supplier to perform its obligations in relation to any
                        transaction you may have with that Supplier. 
                        In particular, we are not liable for the quality, performance, suitability, availability or other matters
                        relating to goods or services purchased by you from a Supplier.
                    </p>

                    <p>
                        We are not responsible for any defects in any goods or services acquired by you through the use of any Elevare
                        Product and you acknowledge and agree that any complaints or issues about goods and services must be addressed
                        to the Supplier who supplied those goods and services to you.
                    </p>


                    <p><strong>8. REFUNDS, REPAYMENTS OR ISSUES</strong> </p>
                    <hr />

                    <p>8.1. If you purchase goods or services from a Supplier (which purchase is treated as an Elevare Purchase)
                        and you may be entitled to make or claim a Loss or Claim,
                        including by way of refund (a Customer Claim) from that Supplier, then:</p>


                    <p>(a) it is your responsibility to make, manage and settle any Customer Claim against or with the Supplier (not with Elevare) but where any refund or other amount is payable to or for you then you must direct that payment to be made to us so as to reduce the amount otherwise payable by you to us; </p>
                    <p>(b) it is your responsibility to make and manage the return of any relevant goods or services to the Supplier;</p>
                    <p>(c) we have no responsibility relating to any such Customer Claim except that the amount owing by you to us may be reduced under this Clause 8; and</p>
                    <p>(d) you must pay the Approved Amount (subject to reduction under this Clause 8) to us, irrespective of any Customer
                        Claim or other right you have or may have in relation to the goods or services or the Supplier of them.</p>

                    <p>
                        8.2. If, for any reason, a Supplier (or any other person) pays (or refunds) money to us on account
                        of any part of the Approved Amount then we will give you the benefit of that payment by applying
                        that payment to reduce any amount owing by you to us and paying the balance (if any) to you.
                    </p>

                    <p>
                        8.3. It is your responsibility to deal with your Supplier or any other person in relation to any Customer Claim (or similar) issue.</p>

                    <p>
                        You must make and we will continue to process any payments relating to the Approved Amount and any fees,
                        even if the goods or services funded by the Approved Amount are subject to a dispute or other issue.</p>


                    <p>
                        <strong>9. EXCLUSIONS OF WARRANTIES AND REPRESENTATIONS</strong></p>
                    <hr />

                    <p>9.1. You have certain rights at law which cannot be limited or excluded. Nothing in this Agreement limits or excludes those rights, but our liability is limited to the maximum extent permitted by law.  If we are liable then our liability will not exceed the total amount that we pay or propose to pay or provide to you under this Agreement.</p>

                    <p>9.2. To the extent permitted by law, we are not liable to you (or any other person) for any direct, special, indirect, consequential or incidental costs, losses, expenses or damages, or lost profits, or any other damages of any kind incurred or suffered by you or any other person in any way which is connected to our Products or any transaction between you and us (including as a result of our negligence or negligence by any of our Team Members). </p>

                    <p>9.3. To the extent permitted by law, we exclude all representations, conditions, guarantees and warranties, whether express or implied (by statute or otherwise) that relate to this Agreement or our Products. </p>

                    <p><strong>10. DEFAULT AND ITS CONSEQUENCES</strong></p>
                    <hr />

                    <p>10.1. You will be in default if:</p>

                    <p>(a) you do not pay any amount owing to us by its Due Date for payment;  </p>

                    <p>(b) you do not comply with any other term or condition of this Agreement; </p> 
                        
                    <p>(c) you are subject to an Insolvency Event; or</p>
                    <p>(d) we believe, on reasonable grounds, that information provided by you was or is misleading or untrue.</p>

                    <p>10.2. If you are in default, we may require you to immediately pay all amounts you owe us, and we may exercise our rights under this Agreement or at law.
                    </p>

                    <p>10.3. If you are in default and you have not paid moneys to us by the Due Date for payment, then:</p>

                    <p>(a) we may charge you late payment interest equal to 0.06% of the overdue amount per day for the period from the Due Date to the date on which those moneys are paid; and</p>

                    <p>(b) you must comply with the terms of our late payment policy and you must pay us any fees which are payable by you under that policy.</p>

                    <p>10.4. You must pay to us any Enforcement Expenses reasonably incurred by us or our agents in exercising our rights because of your default. </p>

                    <p>10.5. You agree to pay to us and to indemnify us for and against any Loss or Claim that we suffer or incur, and which arises from or relates to a default by you under this Agreement.</p>

                    <p>10.6. If you are in default under this Agreement, you acknowledge and agree that: </p>
                    <p>(a) where goods or services you purchase using Elevare's Products are to be delivered after the time  </p>

                    <p>of your default, the Supplier or any other person may, under instructions from us, withhold delivery of those goods or services; and </p>
                    <p>(b) you authorise us to act as your agent to suspend, terminate or cancel your agreement with the Supplier or any other person for delivery of any relevant goods or services. </p>


                    <p><strong>11. OUR SECURITY</strong> </p>
                    <hr />

                    <p>11.1. You charge your interest in the Property in favour of Elevare to secure payment of all moneys owing by you to us and performance of your obligations under this Agreement. </p>
                    <p>11.2. You consent to us lodging a caveat on the title for the Property to protect our rights under this Agreement.  You agree to execute and provide to us any documents we require to record, register or otherwise protect our interests under this Agreement.  We agree to release the Property immediately upon repayment of all money owing to us under this Agreement.</p>
                    <p>11.3. You irrevocably appoint Elevare and each director of Elevare as your attorney to do anything you are required to do under this Agreement (including the execution of any document) but do not do within seven days of request from us.</p>
                    <p>11.4. You authorise and direct all other persons to accept any document signed by an attorney under this clause together with a statutory declaration from that attorney (declaring the validity of that person's power) as sufficient and conclusive evidence of the validity of that person's exercise of that power.</p>


                    <p><strong>12. COMMUNICATION AND NOTICES</strong></p>
                    <hr />

                    <p>12.1. party may give a document or notice to another party by: </p>
                    <p>(a) delivering it to the other party personally;</p>

                    <p>(b) leaving it at or sending it by post or otherwise to the other party's last known residential or postal address; or</p>

                    <p>(c) emailing it to the other party's email address.</p>


                    <p><strong>13. PRIVACY POLICY</strong></p>
                    <hr />
                    
                    <p>13.1. We may collect and use your personal information in accordance with our Privacy Policy (as amended from time to time).  You may view our Privacy Policy on our website, www.elevarepayeasy.com.au</p>
                    <p>13.2. You agree that we may collect your personal information (and share it with our Team Member) to identify you, provide our services to you, for our business development purposes and for any purpose related to this Agreement. Unless you tell us not to, we may use your information to let you know about products or services that may interest you and we may share your information with the Supplier. </p>


                    <p><strong>14. GENERAL PROVISIONS</strong> </p>
                    <hr />

                    <p>14.1. If any provision of the Agreement is or becomes invalid, unenforceable or breaches any mandatory law or applicable code then that provision is treated as severed from this Agreement and the Agreement will continue to be valid and operative without that severed provision.</p>
                    <p>14.2. In the event that you comprise or include (by name or other method) two or more persons:</p>


                    <p>(a) a liability of those persons (or any of them) under this Agreement is a joint liability of all of them and a several liability of each of them;  </p>
                    <p>(b) a right given to those persons under this Agreement is a right given severally to each of them; and</p> 

                    <p>(c) we may act upon the request or direction (or notice) of any one of those persons.</p>

                    <p>14.3. You agree and acknowledge that we can intercept, record, read or view by any means any communication you may have with or make to us by any means.  For that purpose, communications include telephone calls, emails and any other form of electronic or wireless communication.</p>
                    <p>14.4. This Agreement will be governed by the law for the time being in force in the State in which the Property is situated and you agree to submit to the non-exclusive jurisdiction of the courts in that State. </p>

                    <p>14.5. If we do not exercise a right, remedy or power, this does not mean we cannot exercise it later and we are not stopped from doing so. We are not liable for any Loss or Claim caused by exercising, attempting to exercise or failing to exercise, a right, remedy or power under this Agreement (even if caused by our negligence).</p>
                    <p>14.6. You agree that any statement (including, but not limited to, a statement of account or a default notice) that has been given to you by us or our Team Member, is evidence of the facts or matters in the statement and that, unless contrary evidence is established, it is conclusive evidence of its content.</p>
                    <p>14.7. This Agreement (or any of your rights or obligations under this Agreement) may not be assigned by you without our prior written consent. You authorise us to assign, transfer or otherwise deal with our rights under this Agreement without the need to obtain further consent from you. </p>
                    <p>14.8. We may take any action that we decide (in our discretion) in relation to any AML/CTF laws and without limitation, we may delay, limit or stop any payment to you if we are concerned about the application of those laws to this Agreement. </p>

                    <p><strong>15. DEFINITIONS AND INTERPRETATION</strong></p>
                    <hr />

                    <p>In this Agreement, the following terms and expressions have the following meanings, unless the context requires otherwise:</p>
                    <p><strong>Advance Date</strong> means the date on which we will advance the Approved Amount to or for you and which is the date sixty two (62) days before the Repayment Date. </p>


                    <p><strong>Agency</strong> means the Real Estate Agency (if any) specified in the Information Schedule.</p>
                    <p><strong>AML/CTF </strong> means the Anti-Money Laundering and Counter-Terrorism Financing (AML/CTF) laws.</p>


                    <p><strong>Approved Amount</strong> means, in relation to a Supplier or an Elevare Purchase, the Approved Amount set out in the Information Schedule for that Supplier or such other amount that we agree to treat as the Approved Amount for the purposes of this Agreement.
                    </p>


                    <p><strong>Due Date</strong> for payment of moneys due means the day by which you are required to make the payment or pay the moneys.  
                    </p>


                    <p><strong>Elevare's Additional Policies</strong> means Elevare's Privacy Policy, Elevare's Complaints Policy, Elevare's PCI DSS Policy, any other formal policy published by Elevare (on Elevare's website) and any other policy which is incorporated into this Agreement by reference. 
                    </p>

                    <p><strong>Elevare Product</strong> or <strong>Product</strong> means a product offered by Elevare to its customers. </p>

                    <p><strong>Elevare Purchase</strong> means an Order by you where:
                    (a) you apply to Elevare to provide funding, in relation to the Order; and
                    </p>

                    <p>(b) Elevare approves the Order.</p>

                    <p><strong>Information Schedule</strong> means the schedule completed by you in relation to and for the purposes of this Agreement.</p>

                    <p><strong>Insolvency Event</strong> includes but is not limited to:</p>

                    <p>(a) a controller, manager, trustee, administrator or similar officer is appointed in respect of any of your assets;</p>
                    <p>(b) a liquidator or provisional liquidator is appointed to or in respect of you;</p>

                    <p>(c) a voluntary administrator, receiver or receiver and manager is appointed in relation to you or any of your assets;</p>
                    <p>(d) any application (not being an application withdrawn or dismissed within 7 days) is made to a court for an order, or an order is made, or a meeting is convened, or a resolution is passed, for the purpose of:
                    </p>
                    
                    <p>(i) appointing a person referred to in the earlier part of this Clause;</p>
                    <p>(ii) winding up you or your affairs; or</p>
                    <p>(iii) proposing or implementing a scheme of arrangement;</p>
                    <p>(e) any event or conduct occurs which would enable a court to grant a petition, or an order is made, for the bankruptcy of an individual or his or her estate under any insolvency law;
                    </p>

                    <p>(f) a person becomes, or admits in writing that he or she is, is declared to be, or is deemed to be, insolvent or unable to pay that person's debts; or</p>
                    <p>(g) anything analogous to any of the above events.
                    </p>

                    <p><strong>Loss</strong> or <strong>claim</strong> includes any loss, claim, action, damage, liability, cost, charge, expenses, outgoing or payment.</p>


                    <p><strong>Nominated Payment Source</strong> means the bank account (or card) nominated by you for the purpose of making payments to us under this Agreement. </p>


                    <p><strong>Order</strong> means a request, application or order made pursuant to which you (as customer) purchase or propose to purchase goods or services from a Supplier. 
                    </p>


                    <p><strong>Property</strong> means the property (being land) which is described in the Information Schedule. 
                    </p>


                    <p><strong>Repayment Date</strong> means the Repayment Date specified in the Information Schedule.</p>


                    <p><strong>Supplier</strong> means each Supplier described in the Information Schedule (as relevant). 
                    </p>

                    <p><strong>Team Member</strong> means, in relation to a person, any person who is an officer, employee, agent or representative of that person.</p>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    mounted() {
        document
            .getElementById("tc_body")
            .addEventListener("scroll", this.scrollPosition);
    },
    methods: {
        scrollPosition() {
            const scrollHeight = document.getElementById("tc_body")
                .scrollHeight;
            const scrollTop = document.getElementById("tc_body").scrollTop;
            const clientHeight = document.getElementById("tc_body")
                .clientHeight;
           

            if (scrollHeight - scrollTop <= clientHeight + 50) {
                this.$emit("reached", true);
                console.log('reached');
            }
        }
    }
};
</script>

<style lang="sass" scoped>
.tc-body
    max-height: 250px
    overflow-y: scroll
    text-align: justify
    border: 1px solid #DCDFE6
    border-radius: 5px
    padding:
        top: 15px
        bottom: 15px
    
p, span
    overflow-wrap: break-word
    word-wrap: break-word

    -ms-word-break: break-all
    // This is the dangerous one in WebKit, as it breaks things wherever
    word-break: break-all
    // Instead use this non-standard one:
    word-break: break-word
    // Adds a hyphen where the word breaks, if supported (No Blink)
</style>
