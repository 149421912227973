<template>

    <el-dialog
        :visible.sync="open"
        :close-on-click-modal=false
        style="max-width: 600px;"
        class="tc-modal mx-auto"
    >
        <terms-and-conditions @reached="value => (reached = value)">
        </terms-and-conditions>

        <div slot="footer" class="dialog-footer">
            <el-button
                @click="accept"
                :disabled="!reached"
                class="btn btn-primary"
            >I have read and agree to the terms and conditions</el-button>
        </div>
    </el-dialog>

</template>


<script>
export default {
    props: {
        openModal: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            reached: false,
            open: this.openModal || false
        };
    },
    methods: {
        accept() {
            this.open = false;
            this.$emit("accepted", true);
        }
    },
    watch: {
        open(to, from) {
            this.$emit("update:openModal", to);
        },
        openModal(to, from) {
            this.open = to;
        }
    }
};
</script>

<style lang="sass" scoped>
.dialog-footer
    display: flex
    align-items: center
    justify-content: flex-start
</style>
