var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container-fluid bg-white card py-5"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-6"},[_c('label',[_vm._v("First Name")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.application.customer.first_name))])]),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('label',[_vm._v("Last Name")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.application.customer.last_name))])]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('label',[_vm._v("Authorised Supplier")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.supplier.name))])]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('label',[_vm._v("Property Address")]),_vm._v(" "),_c('p',[_vm._v("\n                    "+_vm._s(_vm.application.lead.extra_attributes.address
                            .formatted_address)+"\n                ")])]),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('label',[_vm._v("Agreement Date")]),_vm._v(" "),_c('p',[_vm._v("\n                    "+_vm._s(_vm.moment(new Date(_vm.application.submitted_at)).format(
                            "DD/MM/Y"
                        ))+"\n                ")])]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('label',[_vm._v("Estimated Start Date")]),_vm._v(" "),_c('p',[_vm._v("\n                    "+_vm._s(_vm.moment
                            .utc(_vm.application.lead.start_date, "YYYY-MM-DD")
                            .format("DD/MM/Y"))+"\n                ")])])]),_vm._v(" "),_c('div',{staticClass:"row mt-5"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"col-4"},[_c('label',[_vm._v("\n                    Requested Lead Amount\n                ")]),_vm._v(" "),_c('el-input',{attrs:{"value":_vm.amountString,"disabled":""}},[_c('p',{staticClass:"mb-0",staticStyle:{"padding-top":"10px","padding-left":"10px"},attrs:{"slot":"prefix"},slot:"prefix"},[_vm._v("\n                        $\n                    ")])])],1),_vm._v(" "),_c('div',{staticClass:"col-4"},[_c('label',[_vm._v("\n                    Fee\n                ")]),_vm._v(" "),_c('el-input',{attrs:{"value":"5%","disabled":""}})],1),_vm._v(" "),_c('div',{staticClass:"col-4"},[_c('label',[_vm._v("\n                    Interest\n                ")]),_vm._v(" "),_c('el-input',{attrs:{"value":"0.9%","disabled":""}})],1),_vm._v(" "),_c('div',{staticClass:"col-4 mt-3"},[_c('label',[_vm._v("\n                    Total Amount Payable\n                ")]),_vm._v(" "),_c('el-input',{attrs:{"value":_vm.totalPayable,"disabled":""}},[_c('p',{staticClass:"mb-0",staticStyle:{"padding-top":"10px","padding-left":"10px"},attrs:{"slot":"prefix"},slot:"prefix"},[_vm._v("\n                        $\n                    ")])])],1),_vm._v(" "),_c('div',{staticClass:"col-4 mt-3"},[_c('label',[_vm._v("\n                    107-Day Due Date\n                ")]),_vm._v(" "),_c('el-input',{attrs:{"value":_vm.dueDate,"disabled":""}})],1),_vm._v(" "),_c('div',{staticClass:"col-12 mt-5"},[_c('div',{staticClass:"row"},[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"col-12"},_vm._l((_vm.application.media.filter(
                                function (media) { return media.collection_name ==
                                    'customer_signature'; }
                            )),function(media){return _c('div',{key:media.id,staticClass:"card mt-2",staticStyle:{"border-bottom":"1px solid #efefef"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_c('p',[_vm._v(_vm._s(media.file_name))]),_vm._v(" "),_c('p',[_c('i',{staticClass:"fas fa-tag"}),_vm._v("\n                                        "+_vm._s(_vm._.startCase(
                                                _vm._.replace(
                                                    media.collection_name,
                                                    "_",
                                                    " "
                                                )
                                            ))+"\n                                    ")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"href":media.url,"customer_signature":""}},[_c('i',{staticClass:"fas fa-eye text-blue"})])])])])}),0)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',[_vm._v("Application Summary")])]),_vm._v(" "),_c('div',{staticClass:"col-12 mt-3"},[_c('p',[_vm._v("\n                    We’re almost there. Just before your final submission,\n                    we want to make sure that you are totally happy with the\n                    application and all the details are correct.\n                ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('h4',[_vm._v("Amount Payable & Due Date")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('h4',[_vm._v("\n                            Signature Here\n                        ")])])}]

export { render, staticRenderFns }