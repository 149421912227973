<template>
    <div id="gjs"></div>
</template>
<script>
import Supplier from "@/backend/models/Supplier";
import LandingPage from "@/backend/models/LandingPage";
export default {
    props: {
        supplier: {
            type: Object,
            required: true
        },
        initial_landing_page: {
            type: Object,
            required: false,
            default: () => {
                return {
                    body: null
                };
            }
        }
    },
    components: {},
    data() {
        return {
            landingPage: this.initial_landing_page,
            editor: null
        };
    },
    mounted() {
        this.editor = grapesjs.init({
            container: "#gjs",
            fromElement: true
        });
    },
    methods: {
        async save() {
            try {
                const res = await new LandingPage(this.landingPage)
                    .for(new Supplier(this.supplier))
                    .save();
            } catch (err) {
                console.error(err);
            }
        }
    }
};
</script>
