const state = {
    lead: {
        extra_attributes: {
            address: {
                formatted_address: ''
            }
        },
        fund_purposes: [],
        fund_purpose_ids: [],
        dollar_requested_amount: 0,
        requested_amount: 0,
        start_date: null,
        sales_representative: null,
        disable_email: true,
        source: 'application',
    },
};

const getters = {
    getLead: state => state.lead,
    getSource: state => state.lead.source,
    getDisableEmail: state => state.lead.disable_email,
    getRequestedAmount: state => state.lead.requested_amount,
    getDollarRequestedAmount: state => state.lead.dollar_requested_amount,
    getStartDate: state => state.lead.start_date,
    getFundPurposes: state => state.lead.fund_purposes,
    getFundPurposeIds: state => state.lead.fund_purpose_ids,
    getSalesRepresentative: state => state.lead.sales_representative,
    getExtraAttributes: state => state.lead.extra_attributes,
    getPropertyAddress: state => state.lead.extra_attributes.address
};

const mutations = {
    setLead(state, lead) {
        state.lead = lead;
    },
    setPropertyAddress(state, address) {
        state.lead.extra_attributes.address = address;
    },
    setSource(state, source) {
        state.lead.source = source;
    },
    setDisableEmail(state, disableEmail) {
        state.lead.disable_email = disableEmail;
    },
    setRequestedAmount(state, amount) {
        state.lead.amount = amount;
    },
    setDollarRequestedAmount(state, amount) {
        state.lead.dollar_requested_amount = amount;
        state.lead.amount = amount * 100;
    },
    setStartDate(state, date) {
        state.lead.start_date = date;
    },
    setFundPurposeIds(state, fundPurposeIds = []) {
        state.lead.fund_purpose_ids = fundPurposeIds;
    },
    setSalesRepresentative(state, sales_representative) {
        state.lead.sales_representative = sales_representative;
    },
    setExtraAttributes(state, extra_attributes) {
        state.lead.extra_attributes = extra_attributes;
    }
};

const actions = {
    saveLead(context, lead) {
        context.commit("setLead", lead);
    },
    saveExtraAttributes(context, extra_attributes) {
        context.commit('setExtraAttributes', extra_attributes);
    },
    saveSalesRepresentative(context, sales_representative) {
        context.commit('setSalesRepresentative', sales_representative);
    },
    saveFundPurposeIds(context, fund_purpose_ids) {
        context.commit('setFundPurposeIds', fund_purpose_ids);
    },
    saveStartDate(context, startDate) {
        context.commit('setStartDate', startDate);
    },
    savePropertyAddress(context, address) {
        context.commit('setPropertyAddress', address);
    },
    saveDisableEmail(context, disable_email) {
        context.commit('setDisableEmail', disable_email);
    },
    saveDollarRequestedAmount(context, amount) {
        context.commit('setDollarRequestedAmount', amount);
    },
    saveRequestedAmount(context, amount) {
        context.commit('setRequestedAmount', amount);
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
