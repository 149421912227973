var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "tc-modal mx-auto",
      staticStyle: { "max-width": "600px" },
      attrs: { visible: _vm.open, "close-on-click-modal": false },
      on: {
        "update:visible": function($event) {
          _vm.open = $event
        }
      }
    },
    [
      _c("terms-and-conditions", {
        on: {
          reached: function(value) {
            return (_vm.reached = value)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticClass: "btn btn-primary",
              attrs: { disabled: !_vm.reached },
              on: { click: _vm.accept }
            },
            [_vm._v("I have read and agree to the terms and conditions")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }