<template>
    <div>
        <div class="container-fluid bg-white card application-card mk-property">
            <div class="row">

                <div v-if="form.errors.any()" class="col-12">
                    <div class="show-form-error">
                        <div v-for="(errors, index) in form.errors.all()" :key="index">
                            <ul class="mb-0">
                                <li v-for="(error, i) in errors" :key="i">{{error}}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-12 position-relative">
                    <div class="app-card-decoration"></div>
                    <h1>Your Property</h1>
                </div>
                <div class="col-12">
                    <p class="app-card-intro">
                        This is the property where the funds provided will be
                        invested.
                    </p>
                </div>
            </div>

            <div class="row" style="margin-bottom: 60px;">
                <div class="col-12">
                    <label for="property_address">Property Address</label>
                    <el-input
                        :class="{'is-error': form.errors.has('lead.extra_attributes.address.formatted_address')}"
                        v-model="
                            application.lead.extra_attributes.address
                                .formatted_address
                        "
                    >
                    </el-input>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h3
                        class="text-blue font-heading"
                        style="margin-bottom: 20px;"
                    >
                        Listing Details
                    </h3>
                    <p class="app-card-intro">Please provide us with details for when your property is being listed for sale on the market</p>
                </div>
            </div>

            <div class="row">
                 <div class="col-12 col-lg-8 d-flex flex-column">
                    <label for="date_listed">{{currentDateName}}</label>

                    <div class="d-flex">
                        <el-select 
                            v-model="application.listing_date_type" 
                            :class="{'is-error': form.errors.has('listing_date_type')}" 
                            class="w-100 mr-3"
                        >
                            <el-option
                                v-for="type in listingDateTypes"
                                :key="type.id"
                                :value="type.id"
                                :label="type.name"
                            >
                            </el-option>
                        </el-select>

                        <el-date-picker
                            type="date"
                            :class="{'is-error': form.errors.has('date_listed')}"
                            v-model="application.date_listed"
                            class="w-100"
                            format="dd/MM/yyyy"
                            value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </div>
                </div>

                <div class="col-12 col-lg-4 d-flex justify-content-start justify-content-lg-end align-items-center">
                    <el-checkbox
                        :value="!!application.is_not_listed_as_sale"
                        @change="
                            value => (application.is_not_listed_as_sale = value)
                        "
                        >Property is not being listed for sale</el-checkbox
                    >
                </div>
            </div>

            <div class="row mt-3" v-if="!application.is_not_listed_as_sale">
                <div class="col-12">
                    <label for="sales_representative">Listing Agency</label>
                    <el-input
                        :class="{'is-error': form.errors.has('listing_agency')}"
                        placeholder="Enter Listing Agent"
                        :disabled="!canFill"
                        v-model="application.listing_agency"
                    >
                    </el-input>
                </div>

                <div class="col-12 col-lg-6">
                    <label for="expected_low">Expected Low Range Price</label>
                    <currency-input
                        class="el-input__inner"
                        :class="{'is-error': form.errors.has('expected_low')}"
                        v-model="application.dollar_expected_low"
                        :disabled="!canFill"
                        @change="value => (application.expected_low = value * 100)"
                    />
                </div>

                <div class="col-12 col-lg-6">
                    <label for="expected_low">Expected High Range Price</label>
                    <currency-input
                        class="el-input__inner"
                        :class="{'is-error':form.errors.has('expected_high')}"
                        v-model="application.dollar_expected_high"
                        :disabled="!canFill"
                        @change="value => (application.expected_high = value * 100)"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-12" style="margin-top: 60px;">
                    <h3 class="text-blue font-heading" style="margin-bottom: 20px;">Ownership Declaration</h3>
                    <p class="app-card-intro">Please select the ownership statement that applies to you.</p>
                </div>

                <div class="col-12">
                    <el-radio
                        style="margin-bottom: 30px;"
                        v-model="application.self_ownership"
                        :label="1"
                    >
                        <span class="font-16 text-black">I am the registered owner of the property and my name appears on the current title deed which is held with the Australian Titles Office.</span>
                    </el-radio>

                    <el-radio
                        class="d-flex"
                        v-model="application.self_ownership"
                        :label="2"
                    >
                        <div class="d-flex flex-column has-dropdown-element">
                            <span
                                class="font-16 text-black"
                                style="margin-bottom: 10px;"
                                >No I AM NOT the registered owner of the property, however my relationship with the registered owner is as follows:</span
                            >

                            <el-select
                                style="width: 210px;"
                                :class="{'is-error': form.errors.has('ownership_id')}"
                                v-model="application.ownership_id"
                                v-if="ownerships.length"
                            >
                                <el-option
                                    v-for="ownership in ownerships"
                                    :value="ownership.id"
                                    :label="ownership.name"
                                    :key="ownership.id"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </el-radio>
                </div>
            </div>
        </div>

        <div
            class="d-flex flex-md-row flex-column align-items-center justify-content-between mb-5 px-3 px-md-0"
        >
            <button
                class="btn-white-green-text mutable-mobile-width order-2 order-md-1"
                @click="$emit('previous', true)"
            >
                Back
            </button>
            <el-button class="btn btn-primary mutable-mobile-width order-1 order-md-2 mb-3 mb-md-0" @click="save"
                >Confirm and continue</el-button
            >
        </div>
    </div>
</template>

<script>
import Supplier from "../../../models/Supplier";
import Application from "../../../models/Application";
import Ownerships from "../../../constants/Ownerships";
import Form from 'form-backend-validation';
import ListingDateTypes from '../../../constants/ListingDateTypes';
export default {
    props: {
        initial_supplier: {
            type: Object,
            required: true
        },
        initial_application: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            application: this.initial_application,
            supplier: this.initial_supplier,
            form: new Form(),
        };
    },
    mounted() {
        // set default value;
        if (!this.application.listing_date_type) this.application.listing_date_type = 1;
    },
    methods: {
        async save() {
            try {
                this.form.clear();
                const res = await axios.post(
                    `/api/v1/customer/suppliers/${this.supplier.id}/applications/${this.application.id}/properties`,
                    this.application
                );
                this.$emit('update-application', res.data);
                this.$emit("next", true);
            } catch (err) {
                console.error(err);
                this.form.errors.record(err.response.data.errors);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                // var errorText = "";
                // _.forEach(err.response.data.errors, function(value) {
                //     errorText += "" + value + " ";
                // });
                // this.$message({
                //     type: "error",
                //     message: errorText
                // });
            }
        }
    },
    computed: {
        canFill() {
            return this.application.date_listed && this.application.listing_date_type
        },
        ownerships() {
            return Object.keys(Ownerships).map(key => {
                return Ownerships[key];
            });
        },
        listingDateTypes(){
            return Object.keys(ListingDateTypes).map(key => {
                return ListingDateTypes[key];
            });
        },
        currentDateName(){
            const type = this.listingDateTypes.find(type => type.id == this.application.listing_date_type);
            if (type) return type.name;
            return this.listingDateTypes[0].name;
        }
    }
};
</script>
