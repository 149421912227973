<template>
    <div class="container-fluid px-0 py-5">
        <div class="row" v-if="!done">
            <div class="col-12 py-3 bg-white">
                <div class="row px-5">
                    <div class="col-12">
                        <div
                            class="mb-3"
                            style="margin-top: 36px; width: 60px; height: 4px; background-color: #ff968d;"
                        ></div>

                        <h1 class="mb-3 font-heading">
                            Variation to Plan Details
                        </h1>

                        <p class="mt-5 font-16">
                            Your authorized Elepay Supplier has requested a
                            variation to your existing Elepay Plan.
                        </p>
                    </div>
                </div>

                <div class="row px-5 mt-3">
                    <div class="col-12 col-md-12">
                        <div class="row">
                            <div class="col-md-3 my-2">
                                <p class="font-16">
                                    First Name:
                                </p>
                            </div>
                            <div class="col-md-9 my-2">
                                <p class="font-16">
                                    {{ application.customer.first_name }}
                                </p>
                            </div>

                            <div class="col-md-3 my-2">
                                <p class="font-16">
                                    Last Name:
                                </p>
                            </div>
                            <div class="col-md-9 my-2">
                                <p class="font-16">
                                    {{ application.customer.last_name }}
                                </p>
                            </div>
                            <div class="col-md-3 my-2">
                                <p class="font-16">
                                    Authorised Supplier:
                                </p>
                            </div>
                            <div class="col-md-9 my-2">
                                <p class="font-16">
                                    {{ supplier.name }}
                                </p>
                            </div>
                            <div class="col-md-3 my-2">
                                <p class="font-16">
                                    Property Address:
                                </p>
                            </div>
                            <div class="col-md-9 my-2">
                                <p class="font-16">
                                    {{
                                        application.lead.extra_attributes
                                            .address.formatted_address
                                    }}
                                </p>
                            </div>
                            <div class="col-md-3 my-2">
                                <p>
                                    <strong class="font-16 text-blue">
                                        Original Plan Amount:
                                    </strong>
                                </p>
                            </div>
                            <div class="col-md-9 my-2">
                                <p>
                                    <strong class="font-16 text-blue">
                                        ${{
                                            Number(
                                                application.lead
                                                    .dollar_requested_amount
                                            ).toLocaleString("en-AU", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })
                                        }}
                                    </strong>
                                </p>
                            </div>
                            <div class="col-md-3 my-2">
                                <p>
                                    <strong class="font-16 text-blue">
                                        Original Start Date:
                                    </strong>
                                </p>
                            </div>
                            <div class="col-md-9 my-2">
                                <p>
                                    <strong class="font-16 text-blue">
                                        {{
                                            moment(
                                                application.lead.start_date
                                            ).format("dddd MMMM Do, Y")
                                        }}
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="row px-5 mt-3"
                    style="background:rgba(255, 78, 0, 0.03);"
                >
                    <div class="col-12 col-md-12">
                        <div class="row my-3 py-3">
                            <div class="col-12">
                                <h3
                                    class="font-heading"
                                    style="margin-bottom: 30px;"
                                >
                                    Plan Variation
                                </h3>
                            </div>
                            <div class="col-12">
                                <p class="font-16">
                                    The following is the variation to your
                                    current Elepay plan.
                                </p>
                            </div>
                            <div
                                class="col-md-3 col-12 my-3"
                                v-if="planVariation.dollar_revised_plan_amount"
                            >
                                <div
                                    class="d-inline-block"
                                    style="padding: 5px 10px; background-color: #FF4E00; border-radius: 5px;"
                                >
                                    <p class="mb-0 text-white">
                                        <strong class="text-white font-16">
                                            Revised Plan Value:
                                        </strong>
                                    </p>
                                </div>
                            </div>
                            <div
                                class="col-md-9 col-12 my-3 d-flex align-items-center"
                                v-if="planVariation.dollar_revised_plan_amount"
                            >
                                <p class="mb-0">
                                    <strong class="font-16">
                                        ${{
                                            Number(
                                                planVariation.dollar_revised_plan_amount
                                            ).toLocaleString("en-AU", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })
                                        }}
                                    </strong>
                                </p>
                            </div>

                            <div
                                class="col-md-3 col-12 my-3"
                                v-if="planVariation.revised_start_date"
                            >
                                <div
                                    class="d-inline-block"
                                    style="padding: 5px 10px; background-color: #FF4E00; border-radius: 5px;"
                                >
                                    <p class="mb-0 text-white">
                                        <strong class="text-white font-16">
                                            Revised Start Date:
                                        </strong>
                                    </p>
                                </div>
                            </div>
                            <div
                                class="col-md-9 col-12 my-3 d-flex align-items-center"
                                v-if="planVariation.revised_start_date"
                            >
                                <p class="mb-0">
                                    <strong class="font-16">
                                        {{
                                            planVariation.revised_start_date
                                                ? moment(
                                                      planVariation.revised_start_date
                                                  ).format("dddd MMMM Do, Y")
                                                : "N/A"
                                        }}
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row px-5 mt-3">
                    <div class="col-12 col-md-12">
                        <div class="row mt-5">
                            <div class="col-12">
                                <h3
                                    class="text-blue font-heading"
                                    style="margin-bottom: 30px;"
                                >
                                    Revised Payment Schedule
                                </h3>
                                <p class="mb-1">
                                    <strong class="font-16"
                                        >The quicker you repay your plan, the
                                        lower your fees.</strong
                                    >
                                </p>
                                <p>
                                    Enjoy our low fees when you pay off your
                                    plan earlier.
                                </p>

                                <div
                                    class="mb-3 d-inline-block"
                                    style="padding: 5px 10px; background-color: #FF4E00; border-radius: 5px;"
                                >
                                    <p class="mb-0 text-white">
                                        <strong class="text-white"
                                            >Payment Schedule:</strong
                                        >
                                    </p>
                                </div>

                                <p>
                                    The table below provides you with the fees
                                    applicable if your payment is received by
                                    the designated date.
                                </p>
                            </div>

                            <div class="col-12 col-md-4">
                                <pay-before-card
                                    paydayCardBg="rgb(249, 249, 249)"
                                    :payBeforeDays="45"
                                    payBeforeFee="2.0%"
                                    :payBeforeHighlightFee="true"
                                    payBeforeInterest="0.0%"
                                    :payBeforeAmountPayable="
                                        getTotalPayable(2, 0)
                                    "
                                    :due-date="getDueDate(44)"
                                ></pay-before-card>
                            </div>

                            <div class="col-12 col-md-4">
                                <pay-before-card
                                    paydayCardBg="rgba(255, 78, 0, 0.03)"
                                    :payBeforeDays="60"
                                    payBeforeFee="5.0%"
                                    :payBeforeHighlightFee="false"
                                    payBeforeInterest="0.0%"
                                    :payBeforeAmountPayable="
                                        getTotalPayable(5, 0)
                                    "
                                    :due-date="getDueDate(59)"
                                >
                                </pay-before-card>
                            </div>

                            <div class="col-12 col-md-4 schedule-mobile-card">
                                <pay-before-card
                                    paydayCardBg="rgba(255, 78, 0, 0.12)"
                                    :payBeforeDays="107"
                                    payBeforeFee="5.0%"
                                    :payBeforeHighlightFee="false"
                                    payBeforeInterest="0.9%"
                                    :payBeforeAmountPayable="
                                        getTotalPayable(5, 0.9)
                                    "
                                    :payBeforeIsFinalCard="true"
                                    :due-date="getDueDate(107)"
                                >
                                </pay-before-card>
                            </div>
                        </div>

                        <div class="row mt-5 mb-3">
                            <div class="col-12">
                                <p class="mb-0 font-16">
                                    To proceed, please view the Terms &
                                    Conditions and sign and submit.
                                </p>
                            </div>

                            <div class="col-12 mt-4">
                                <el-button
                                    @click="openTCModal = true"
                                    class="btn btn-primary mutable-mobile-width"
                                    style="margin-bottom: 30px;"
                                >
                                    View Terms & Conditions
                                </el-button>
                            </div>

                            <div class="col-12">
                                <h3
                                    class="text-blue font-heading"
                                    style="margin-bottom: 30px;"
                                >
                                    Your Signature
                                </h3>
                            </div>

                            <div class="col-12">
                                <div class="mb-3" style="height:150px;">
                                    <VueSignaturePad
                                        class="app-signature"
                                        ref="signaturePad"
                                        :options="{
                                            backgroundColor: '#fff'
                                        }"
                                        style="margin-bottom: 8px; border: 1px solid #e3e3e3; width: 50%;"
                                    />
                                </div>

                                <p class="mb-0 has-interaction" @click="undo">
                                    <i class="fas fa-undo text-red mr-2"></i
                                    ><strong class="text-red">Undo</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="col-12 d-flex align-items-center justify-content-end mt-3"
            >
                <el-button
                    class="btn btn-primary mutable-mobile-width order-1 order-md-2 mb-3 mb-md-0"
                    @click="save"
                    :disabled="!tc"
                >
                    Submit
                </el-button>
            </div>
        </div>
        <success-page v-if="done" />
        <terms-and-conditions-modal
            :open-modal.sync="openTCModal"
            @accepted="value => (tc = value)"
        ></terms-and-conditions-modal>
    </div>
</template>

<script>
import Supplier from "../../models/Supplier";
import Application from "../../models/Application";
import PlanVariation from "../../models/PlanVariation";
export default {
    props: {
        supplier: {
            type: Object,
            required: true
        },
        application: {
            type: Object,
            required: true
        },
        planVariation: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            tc: false,
            openTCModal: false,
            done: false
        };
    },
    methods: {
        async submit() {},
        /**
         * @param {number} interest
         * @param {number} fee
         *
         * @return {string}
         */
        getTotalPayable(interest, fee) {
            const totalPercentage = interest + fee;
            let amount =
                this.planVariation.dollar_revised_plan_amount ||
                this.application.lead.dollar_requested_amount;

            return Number(amount * (totalPercentage / 100 + 1)).toLocaleString(
                "en-AU",
                {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }
            );
        },
        /**
         * @param {number} addDay
         * @return {string}
         */
        getDueDate(addDay) {
            let startDay = new Date(
                this.planVariation.revised_start_date ||
                    this.application.lead.start_date
            );
            return moment(
                new Date(startDay.setDate(startDay.getDate() + addDay))
            ).format("ddd, Do MMMM YYYY");
        },
        async save() {
            if (!this.tc) return;
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

            if (!isEmpty) {
                try {
                    // add signature
                    this.planVariation.signature = data;
                    const res = await new PlanVariation(this.planVariation)
                        .for(
                            new Supplier(this.supplier),
                            new Application(this.application)
                        )
                        .save();

                    this.done = true;
                } catch (err) {
                    console.error(err);

                    var errorText = "";
                    _.forEach(err.response.data.errors, function(value) {
                        errorText += "" + value + " ";
                    });
                    this.$message({
                        type: "error",
                        message: errorText
                    });
                }
            } else {
                this.$message({
                    type: "error",
                    message: "Please sign in the box provided"
                });
            }
        },
        undo() {
            this.$refs.signaturePad.undoSignature();
        }
    }
};
</script>
