var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c(
        "div",
        {
          staticClass:
            "container-fluid bg-white application-card card mk-leadidentity"
        },
        [
          _c("div", { staticClass: "row" }, [
            _vm.form.errors.any()
              ? _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "show-form-error" },
                    _vm._l(_vm.form.errors.all(), function(errors, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "ul",
                          { staticClass: "mb-0" },
                          _vm._l(errors, function(error, i) {
                            return _c("li", { key: i }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(error) +
                                  "\n                            "
                              )
                            ])
                          }),
                          0
                        )
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1)
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row id-margin-belt" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-4" },
              [
                _c("label", { attrs: { for: "first_name" } }, [
                  _vm._v("First Name")
                ]),
                _vm._v(" "),
                _c("el-input", {
                  class: {
                    "is-error": _vm.form.errors.has("customer.first_name")
                  },
                  on: { change: _vm._createLead },
                  model: {
                    value: _vm.application.customer.first_name,
                    callback: function($$v) {
                      _vm.$set(_vm.application.customer, "first_name", $$v)
                    },
                    expression: "application.customer.first_name"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4" },
              [
                _c("label", { attrs: { for: "middle_name" } }, [
                  _vm._v("Middle Name")
                ]),
                _vm._v(" "),
                _c("el-input", {
                  class: {
                    "is-error": _vm.form.errors.has("customer.middle_name")
                  },
                  model: {
                    value: _vm.application.customer.middle_name,
                    callback: function($$v) {
                      _vm.$set(_vm.application.customer, "middle_name", $$v)
                    },
                    expression: "application.customer.middle_name"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4" },
              [
                _c("label", { attrs: { for: "last_name" } }, [
                  _vm._v("Last Name")
                ]),
                _vm._v(" "),
                _c("el-input", {
                  class: {
                    "is-error": _vm.form.errors.has("customer.last_name")
                  },
                  model: {
                    value: _vm.application.customer.last_name,
                    callback: function($$v) {
                      _vm.$set(_vm.application.customer, "last_name", $$v)
                    },
                    expression: "application.customer.last_name"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-6 d-flex flex-column" },
              [
                _c("label", { attrs: { for: "date_of_birth" } }, [
                  _vm._v("Date of Birth")
                ]),
                _vm._v(" "),
                _c("el-date-picker", {
                  staticClass: "w-100",
                  class: {
                    "is-error": _vm.form.errors.has("customer.date_of_birth")
                  },
                  attrs: {
                    type: "date",
                    format: "dd/MM/yyyy",
                    "value-format": "yyyy-MM-dd"
                  },
                  model: {
                    value: _vm.application.customer.date_of_birth,
                    callback: function($$v) {
                      _vm.$set(_vm.application.customer, "date_of_birth", $$v)
                    },
                    expression: "application.customer.date_of_birth"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-md-6" },
              [
                _c("label", { attrs: { for: "email" } }, [_vm._v("Email")]),
                _vm._v(" "),
                _c("el-input", {
                  class: {
                    "is-error": _vm.form.errors.has("customer.email")
                  },
                  on: { change: _vm._createLead },
                  model: {
                    value: _vm.application.customer.email,
                    callback: function($$v) {
                      _vm.$set(_vm.application.customer, "email", $$v)
                    },
                    expression: "application.customer.email"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-md-6" },
              [
                _c("label", { attrs: { for: "phone" } }, [_vm._v("Phone")]),
                _vm._v(" "),
                _c("vue-tel-input", {
                  class: [
                    "w-100 form-control-borders",
                    { "is-error": _vm.form.errors.has("customer.phone") }
                  ],
                  staticStyle: { "min-height": "38px !important" },
                  attrs: {
                    mode: "international",
                    onlyCountries: ["AU", "GB", "NZ", "US"]
                  },
                  on: { blur: _vm._createLead },
                  model: {
                    value: _vm.application.customer.phone,
                    callback: function($$v) {
                      _vm.$set(_vm.application.customer, "phone", $$v)
                    },
                    expression: "application.customer.phone"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("label", { attrs: { for: "residential_address" } }, [
                  _vm._v("Current Residential Address")
                ]),
                _vm._v(" "),
                _vm.application.customer.extra_attributes.residential_address
                  ? _c("el-input", {
                      class: {
                        "is-error": _vm.form.errors.has(
                          "customer.extra_attributes.residential_address.formatted_address"
                        )
                      },
                      model: {
                        value:
                          _vm.application.customer.extra_attributes
                            .residential_address.formatted_address,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.application.customer.extra_attributes
                              .residential_address,
                            "formatted_address",
                            $$v
                          )
                        },
                        expression:
                          "\n                        application.customer.extra_attributes\n                            .residential_address.formatted_address\n                    "
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-12 col-md-4 d-flex flex-column has-dropdown-element"
              },
              [
                _c("label", { attrs: { for: "identification_method" } }, [
                  _vm._v("Identification Method")
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "margin-b-30",
                    class: {
                      "is-error": _vm.form.errors.has(
                        "identification.identification_type"
                      )
                    },
                    model: {
                      value: _vm.identification.identification_type,
                      callback: function($$v) {
                        _vm.$set(_vm.identification, "identification_type", $$v)
                      },
                      expression: "identification.identification_type"
                    }
                  },
                  _vm._l(_vm.options, function(opt) {
                    return _c("el-option", {
                      key: opt.id,
                      attrs: { value: opt.id, label: opt.name }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.identification.identification_type == 1
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-12 col-md-4 d-flex flex-column has-dropdown-element"
                  },
                  [
                    _c("label", { attrs: { for: "issuing_state" } }, [
                      _vm._v("Issuing State")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "margin-b-30",
                        class: {
                          "is-error": _vm.form.errors.has(
                            "identification.issuing_state"
                          )
                        },
                        model: {
                          value: _vm.identification.issuing_state,
                          callback: function($$v) {
                            _vm.$set(_vm.identification, "issuing_state", $$v)
                          },
                          expression: "identification.issuing_state"
                        }
                      },
                      _vm._l(_vm.states, function(state, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: state.name, value: state.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-md-4" },
              [
                _c("label", { attrs: { for: "identification_number" } }, [
                  _vm._v("Identification Number")
                ]),
                _vm._v(" "),
                _c("el-input", {
                  class: {
                    "is-error": _vm.form.errors.has(
                      "identification.identification_number"
                    )
                  },
                  model: {
                    value: _vm.identification.identification_number,
                    callback: function($$v) {
                      _vm.$set(_vm.identification, "identification_number", $$v)
                    },
                    expression: "identification.identification_number"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-3" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg-9 mb-4" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 d-md-none" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center align-items-center"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn btn-primary mt-0 w-100",
                          on: {
                            click: function($event) {
                              return _vm.save(false)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                                Verify by taking a photo\n                            "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 d-md-none" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center align-items-center"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn btn-primary mt-0 w-100",
                          on: {
                            click: function($event) {
                              return _vm.save(true)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                                Verify without taking a photo\n                            "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 d-none d-md-block" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-start align-items-center"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn btn-primary mt-0 mr-3",
                          on: {
                            click: function($event) {
                              return _vm.save(false)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                                Verify by taking a photo\n                            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "p-2" }, [
                        _vm._v(
                          "\n                                or\n                            "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn btn-primary mt-0 ml-3",
                          on: {
                            click: function($event) {
                              return _vm.save(true)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                                Verify without taking a photo\n                            "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-md-row flex-column align-items-center justify-content-between mb-5 px-3 px-md-0"
        },
        [
          _c(
            "button",
            {
              staticClass:
                "btn-white-green-text mb-3 mb-md-0 mutable-mobile-width",
              on: { click: _vm.back }
            },
            [_vm._v("\n            Back\n        ")]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 position-relative" }, [
      _c("div", { staticClass: "app-card-decoration" }),
      _vm._v(" "),
      _c("h1", [_vm._v("Your Identity")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "app-card-intro" }, [
        _vm._v(
          "\n                    Please make sure the details you complete below match\n                    the details on your nominated Photo ID.\n                "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "h3",
        {
          staticClass: "text-blue font-heading",
          staticStyle: { "margin-bottom": "20px" }
        },
        [_vm._v("Verify Your Identity")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "app-card-intro" }, [
        _vm._v(
          "\n                    Before an application can be approved, verification of your ID will need to be completed by the team at Elepay\n                "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 d-md-none" }, [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-center align-items-center my-1"
        },
        [
          _c("span", { staticClass: "p-2" }, [
            _vm._v(
              "\n                                or\n                            "
            )
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }