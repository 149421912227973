<template>
    <div>
        <div class="container-fluid bg-white card application-card mk-thankyou">
            <div class="row">
                <div class="col-12 position-relative">
                    <div class="app-card-decoration"></div>
                    <h1 class="">Thank You!</h1>
                </div>
                <div class="col-12">
                    <p class="app-card-intro" style="margin-bottom: 60px;">Thank you for providing your details, our team will be in contact with you very shortly</p>

                    <p>Please keep an eye on your email for our next piece of correspondence, which you should receive shortly. Be sure to add us to your approved contacts to prevent our emails going into your junk mail.</p>
                    <p style="margin-bottom: 30px;">Thank you for your time.</p>
                
                    <h3 class="text-blue font-heading" style="margin-bottom: 30px;">Team Elevare</h3>
                </div>
            </div>
        </div>

        <!-- <div class="d-flex justify-content-center align-items-center">
            <el-button class="btn btn-primary" @click="close"> Close</el-button>
        </div> -->
    </div>
</template>

<script>
export default {
    methods: {
        close() {
            window.close();
        }
    }
};
</script>
