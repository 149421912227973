<template>
    <div>
        <div class="row">
            <div class="col">
                <h3>Leads</h3>
            </div>
        </div>
        <div class="row mk-dashboard">
            <div class="col-12">
                <paginated-table
                    v-if="dashboard.data"
                    :tableData="tableData"
                    :paginatedData="dashboard"
                    :initial_page="5"
                    @pagination-change-page="getData"
                >
                    <template slot="after-search-slot">
                        <div
                            class="d-flex justify-content-end align-items-center ml-3"
                        >
                            <el-date-picker
                                v-model="dateRange"
                                @change="getData(1)"
                                type="daterange"
                                range-separator="-"
                                format="dd/MM/yyyy"
                                value-format="yyyy-MM-dd"
                                start-placeholder="Start date"
                                end-placeholder="End date"
                            >
                            </el-date-picker>
                        </div>
                    </template>
                </paginated-table>
            </div>
        </div>
    </div>
</template>

<script>
import Supplier from "../../../supplier/models/Supplier";
import LeadDashboard from "../../../supplier/models/LeadDashboard";
export default {
    props: {
        initial_supplier: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dashboard: {},
            supplier: this.initial_supplier,
            page: 1,
            loading: false,
            search: null,
            dateRange: null,
            tableData: {
                columns: [
                    {
                        label: "First Name",
                        name: "customer.first_name",
                        sortable: false
                    },
                    {
                        label: "Last Name",
                        name: "customer.last_name",
                        sortable: false
                    },
                    { label: "Email", name: "customer.email", sortable: false },
                    { label: "Phone", name: "customer.phone", sortable: false },
                    {
                        label: "Requested Amount",
                        name: "requested_amount",
                        sortable: true,
                        money: true
                    },
                    {
                        label: "Address",
                        name: "property_address"
                    },
                    {
                        label: "Estimated Start Date",
                        name: "start_date",
                        whole_date: true,
                        sortable: false
                    },
                    {
                        label: "Created At",
                        name: "created_at",
                        datetime: true,
                        sortable: true
                    }
                ],
                showSearch: true,
                showPerPage: true,
                overrideView: true
            }
        };
    },
    async mounted() {
        this.getData(1);
    },
    methods: {
        getData(
            page = 1,
            sortColumn = null,
            query = null,
            per_page = 5,
            desc = false
        ) {
            this.page = page ? page : 1;
            let dashboard = LeadDashboard.page(this.page)
                .include("customer", "supplier")
                .params({
                    per_page: per_page
                });
            if (sortColumn) {
                dashboard.orderBy(
                    (desc ? "-" : "") +
                        (sortColumn.sort_name || sortColumn.name)
                );
            } else {
                dashboard.orderBy("-created_at");
            }

            if (query) dashboard.where("where_like", query);

            if (this.dateRange)
                dashboard.where("created_between", this.dateRange.join(","));

            dashboard.append("property_address");
            dashboard.where("without_submitted_applications", true);
            dashboard
                .for(new Supplier(this.supplier))
                .get()
                .then(res => {
                    this.loading = false;
                    this.dashboard = res;
                })
                .catch(errors => {
                    this.loading = false;
                    console.error(errors);
                });
        }
    }
};
</script>
