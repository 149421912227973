export default {
    act: {
        name: "ACT",
        value: 'act'
    },
    nsw: {
        name: "NSW",
        value: 'nsw',
    },
    nt: {
        name: "NT",
        value: 'nt',
    },
    sa: {
        name: "SA",
        value: 'sa',
    },
    wa: {
        name: "WA",
        value: 'wa',
    },
    tas: {
        name: "TAS",
        value: 'tas',
    },
    qld: {
        name: "QLD",
        value: 'qld',
    },
    vic: {
        name: "VIC",
        value: 'vic',
    },
};
