<template>
    <div class="mt-3">
        <div class="container-fluid mb-4 pt-3 bg-white card">
            <div class="row">
                
                <div class="col-12">
                    <div class="row customer-card bg-white">
                        <div
                            class="col-12 d-flex align-items-center justify-content-between"
                        >
                            <h2 class="mb-0">Email Templates</h2>
                            <a
                                :href="
                                    `/admin/suppliers/${supplier.id}/email_templates/create`
                                "
                                class="btn btn-primary"
                                >Create Email Template</a
                            >
                        </div>
                        <div class="col-12">
                            <paginated-table
                                v-if="emailTemplates.data"
                                :tableData="tableData"
                                :paginatedData="emailTemplates"
                                @pagination-change-page="getEmailTemplates"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Supplier from "../../models/Supplier";
import EmailTemplate from "../../models/EmailTemplate";

export default {
    props: {
        supplier: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            emailTemplates: {},
            loading: false,
            tableData: {
                columns: [
                    { label: "Name", name: "name", sortable: true },
                    {
                        label: "Updated At",
                        name: "updated_at",
                        datetime: true,
                        sortable: false
                    }
                ],
                edit: true,
                showSearch: false,
                showPerPage: true,
                viewUrl: `/admin/suppliers/${this.supplier.id}/email_templates`
            }
        };
    },
    mounted() {
        this.getEmailTemplates();
    },
    methods: {
        async getEmailTemplates(
            page = 1,
            sortColumn = null,
            query = null,
            per_page = 100,
            desc = false
        ) {
            let templates = EmailTemplate.page(page).params({
                per_page: per_page
            });
            if (sortColumn) {
                templates.orderBy(
                    (desc ? "-" : "") +
                        (sortColumn.sort_name || sortColumn.name)
                );
            } else {
                templates.orderBy("name");
            }

            templates
                .for(new Supplier(this.supplier))
                .get()
                .then(res => {
                    this.loading = false;
                    this.emailTemplates = res;
                })
                .catch(errors => {
                    this.loading = false;
                    console.error(errors);
                });
        }
    }
};
</script>
