<template>
    <div class="mt-3">
        <div class="container-fluid mb-4 bg-white card">
            <div class="row">
                <div class="col-12">
                    <div class="customer-card bg-white pt-3">
                        <div class="row">
                            <div
                                class="col-12 d-flex align-items-center justify-content-between"
                            >
                                <h2 class="mb-0">Leads</h2>
                                <a
                                    :href="
                                        `/admin/suppliers/${supplier.id}/leads/create`
                                    "
                                    class="btn btn-primary"
                                    >Create Lead
                                </a>
                            </div>

                            <div class="col-12">
                                <paginated-table
                                    v-if="leads.data"
                                    :tableData="tableData"
                                    :paginatedData="leads"
                                    :initial_page="15"
                                    @pagination-change-page="getLeads"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Lead from "../../models/Lead";
import Supplier from "../../models/Supplier";
export default {
    props: {
        supplier: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            leads: {},
            tableData: {
                columns: [
                    {
                        label: "First Name",
                        name: "customer.first_name",
                        sortable: false
                    },
                    {
                        label: "Email",
                        name: "customer.email",
                        sortable: false
                    },
                    {
                        label: "Phone",
                        name: "customer.phone",
                        sortable: false
                    },
                    {
                        label: "Amount Requested",
                        name: "requested_amount",
                        money: true,
                        sortable: false
                    },
                    {
                        label: "Estimated Start Date",
                        name: "start_date",
                        whole_date: true,
                        sortable: false
                    },
                    // {
                    //     label: "Form name",
                    //     name: "lead_form.name",
                    //     sortable: false
                    // },
                    {
                        label: "Created At",
                        name: "created_at",
                        datetime: true,
                        sortable: false
                    }
                ],
                edit: true,
                showSearch: false,
                showPerPage: true,
                viewUrl: `/admin/suppliers/${this.supplier.id}/leads`
            }
        };
    },
    mounted() {
        this.getLeads();
    },
    methods: {
        async getLeads(
            page = 1,
            sortColumn = null,
            query = null,
            per_page = 15,
            desc = false
        ) {
            let leads = Lead.page(page).params({
                per_page: per_page
            });
            if (sortColumn) {
                leads.orderBy(
                    (desc ? "-" : "") +
                        (sortColumn.sort_name || sortColumn.name)
                );
            } else {
                leads.orderBy("-created_at");
            }

            leads.where("without_submitted_applications", true);

            leads
                .for(new Supplier(this.supplier))
                .include("customer", "lead_form")
                .get()
                .then(res => {
                    this.loading = false;
                    this.leads = res;
                })
                .catch(errors => {
                    this.loading = false;
                    console.error(errors);
                });
        }
    }
};
</script>
