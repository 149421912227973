<template>
    <div>
        <div class="row position-relative mk-applicationlanding" style="">

            <div class="w-100 position-absolute" style="top: 0; z-index: 1;">
                <div class="col-12 d-flex justify-content-between align-items-center">

                    <div class="d-flex align-items-center p-3">
                        <img class="ml-4 img-fluid" style="max-width: 100px;" src="/img/backend/brand/elepay-no-tag.png" />

                        <p class="mb-0 ml-3 font-12 text-blue d-md-block d-none">The Pay Later specialist for the property Industry</p>
                    </div>

                    <img class="ml-4 img-fluid p-3" style="max-width: 160px;" src="/img/backend/brand/elevare.png" />
                </div>
            </div>


            <div
                class="col-md-6 col-12"
                style="padding-top: 100px; background-color: #efefef;"
            >
                <div
                    class="container d-flex align-items-center justify-content-center"
                    style="min-height: 500px;"
                >
                    <div class="row w-100">
                        <div class="col-12 mb-4">
                            <h2
                                class="text-blue font-heading"
                                style="margin-bottom: 27px; font-size: 30px;"
                            >The safe and secure way to defer upfront property expenses for 107 days.</h2>
                            <div
                                class="title-decoration-bar bg-pink"
                                style="margin-bottom: 38px;"
                            />
                            <p
                                class="text-blue mb-1"
                                style="margin-bottom: 40px; font-size: 16px; min-height: 26px;"
                            >Elepay is Australia's favourite pay later provider with bespoke payment plans for Australian property owners. Join thousands of other happy Australians by deferring expenses for up to 107 days from start date. It's simple, hassle free and best of all it allows you to avoid any upfront property expenses.</p>

                            <a
                                href="#learn-more-modal"
                                class="no-hover"
                                data-toggle="modal"
                                data-backdrop="true"
                                style="border-bottom: 2px solid #ff968d;"
                            ><strong class="text-blue">Click here to learn more about elepay.</strong></a>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="container-fluid mk-applicationlanding col-12 col-md-6"
                style="background-image: url('/img/backgrounds/application-landing-1.png'); background-size: cover; background-position: top center"
            />

        </div>


        <!-- key benefits row -->
        <div
            class="mobile-key-benefits-belt"
        />
        <div
            class="container-fluid py-5 key-benefits-container"
            style="background-size: cover; background-position: top left"
        >
            <div class="container py-5">
                <div class="row px-3 pl-3 pl-lg-5">
                    <div class="ml-auto col-12 col-lg-6">
                        <h2
                            class="fw-700 font-heading"
                            style="margin-bottom: 7px; font-size: 32px;"
                        >Key benefits</h2>
                        <div
                            class="title-decoration-bar bg-pink"
                            style="margin-bottom: 29px;" 
                        />

                        <ul>
                            <li>
                                <p class="mb-1 font-17"><strong class="text-blue font-heading">No upfront payment</strong></p>
                            </li>
                            <li>
                                <p class="mb-1 font-17"><strong class="text-blue font-heading">107 days to repay from start date</strong></p>
                            </li>
                            <li>
                                <p class="mb-1 font-17"><strong class="text-blue font-heading">Funding from $500 - $25,000</strong></p>
                            </li>
                            <li>
                                <p class="mb-1 font-17"><strong class="text-blue font-heading">3 minute online application process</strong></p>
                            </li>
                            <li>
                                <p class="mb-1 font-17"><strong class="text-blue font-heading">2 hour approval process</strong></p>
                            </li>
                            <li>
                                <p class="mb-1 font-17"><strong class="text-blue font-heading">Manage your account online</strong></p>
                            </li>
                            <li>
                                <p class="mb-1 font-17"><strong class="text-blue font-heading">Fees starting from as low as 2%</strong></p>
                            </li>
                            <li>
                                <p class="mb-1 font-17"><strong class="text-blue font-heading">Approved funds are disbursed directly to your supplier</strong></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>


        <div class="container-fluid bg-blue">
            <div class="row py-4">
                <div class="col-12 d-none d-md-block">
                    <div class="d-flex justify-content-center align-items-center">
                        <p class="mx-2 mb-0"><strong class="text-white font-heading">Application takes less than 3 minutes.</strong></p>
                        <a class="mx-2 btn-pink" :href="createLink">Apply now</a>
                    </div>
                </div>
                <div class="col-12 d-md-none">
                    <div class="d-flex justify-content-center align-items-center flex-column">
                        <p class="mx-2 mb-3"><strong class="text-white font-heading">Application takes less than 3 minutes.</strong></p>
                        <a class="mx-2 btn-pink w-100 text-center" :href="createLink">Apply now</a>
                    </div>
                </div>
            </div>
        </div>


        <div class="container-fluid bg-slate py-5">
            <div class="container py-4">
                <div class="row">
                    <div class="col-12 col-lg-5 d-flex flex-column align-items-center align-items-lg-start mb-4 mb-lg-0">
                        <img class="mb-3 img-fluid align-self-center align-self-lg-start" src="/img/logos/elevare-logo.svg" />
                        <div
                            class="title-decoration-bar bg-pink"
                            style="margin-top: 20px;" 
                        />
                    </div>

                    <div class="col-12 col-lg-2 d-flex flex-column align-items-center align-items-lg-start mb-4 mb-lg-0">
                        <img class="mb-3 img-fluid align-self-center align-self-lg-start" src="/img/elements/footer-address.png" />
                        <p class="text-white mb-1">273 Abbotsford Road</p>
                        <p class="text-white">Bowen Hills QLD 4006</p>
                    </div>

                    <div class="col-12 col-lg-2 d-flex flex-column mb-4 mb-lg-0">
                        <img class="mb-3 img-fluid align-self-center align-self-lg-start" src="/img/elements/footer-phone.png" />
                        <a href="tel:1300019417" class="text-white text-center text-lg-left">1300 019 417</a>
                    </div>

                    <div class="col-12 col-lg-3 d-flex flex-column mb-4 mb-lg-0">
                        <img class="mb-3 img-fluid align-self-center align-self-lg-start" src="/img/elements/footer-email.png" />
                        <a href="mailto:hello@elevarepayeasy.com.au" class="text-white text-center text-lg-left">hello@elevarepayeasy.com.au</a>
                    </div>
                </div>
                <!-- end top row -->

                <div class="row mt-1 mt-md-4">
                    <div class="col-12 col-lg-5 d-flex flex-column align-items-center align-items-lg-start">
                        <p class="mb-3 mb-lg-0"><strong class="text-white">We're the Pay Later specialists for the property Industry.</strong></p>
                    </div>

                    <div class="col-12 col-lg-2 d-flex flex-column align-items-center align-items-lg-start">
                        <p class="text-m-grey mb-3 mb-lg-0">ABN: 47 634 728 591</p>
                    </div>

                    <div class="col-12 col-lg-2 d-flex flex-column align-items-center align-items-lg-start">
                        <a href="#0" class="text-m-grey text-center text-lg-left mb-3 mb-lg-0">Terms & Conditions</a>
                    </div>

                    <div class="col-12 col-lg-3 d-flex flex-column align-items-center align-items-lg-start">
                        <a href="#0" class="text-m-grey text-center text-lg-left">Privacy and Refund Policies</a>
                    </div>
                </div>

            </div>
        </div>



        <!-- learn more modal -->
        <div id="learn-more-modal" class="modal">
            <div class="modal-dialog modal-dialog-centered">
                
                <div class="modal-content">
                    <div class="modal-header px-5 pt-5" style="border-bottom: none;">
                        <img
                            class="img-fluid"
                            style="max-width: 100px;"
                            src="/img/backend/brand/elepay-no-tag.png"
                        />
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i class="far fa-times-circle"></i></span>
                        </button>
                    </div>

                    <div class="modal-body px-5">
                        <p><strong>The Elepay business is conducted by Elevare Pay Easy Pty Ltd, an Australian privately-owned company which is part of the Elevare Pay Easy Group. </strong></p>
                        <p>Elevare Pay Easy Pty Ltd provides a deferred payment solution and Buy Now Pay Later solution for participants in the Australian property industry. The executive team of the Elevare Pay Easy Group have been involved within the deferred payment solution and Buy Now Pay Later industry since the category was established in 2014. Elepay’s suite of products have been designed and approved by professional experts to ensure that they comply with all relevant legal requirements and standards.</p>

                        <img class="img-fluid mb-4" style="max-width: 160px;" src="/img/backend/brand/elevare.png" />
                    </div>
                </div>

            </div>
        </div>
        <!-- end learn more modal -->

    </div>
</template>


<script>
export default {
    props: {
        createLink: {
            required: true,
            type: String
        }
    }
};
</script>



<style scoped>
h1 {
    font-size: 50px;
}

ul {
    padding-left: 14px;
}

ul li {
    color: #ff968d;
}

.key-benefits-container {
    background-image: url('/img/backgrounds/application-landing-2.png');
    background-color: rgb(239, 239, 239);
}

@media only screen and (max-width: 992px) {
    .key-benefits-container {
        background-image: none;
    }
}


.mobile-key-benefits-belt {
    display: none;
}

@media only screen and (max-width: 768px) {
    .mobile-key-benefits-belt {
        display: flex;
        min-height: 320px;
        background-image: url('/img/backgrounds/application-landing-2.png');
        background-size: cover;
    }
}

</style>
