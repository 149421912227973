var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "tc-modal mx-auto",
      staticStyle: { "max-width": "600px" },
      attrs: { visible: _vm.open, "close-on-click-modal": false },
      on: {
        "update:visible": function($event) {
          _vm.open = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "container-fluid bg-white card application-card mk-upload-rule p-3 mb-0"
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 position-relative" }, [
              _c("div", { staticClass: "app-card-decoration" }),
              _vm._v(" "),
              _c(
                "h3",
                {
                  staticClass: "my-3",
                  staticStyle: {
                    "ont-family": "aktiv-grotesk-extended, sans-serif"
                  }
                },
                [
                  _vm._v(
                    "\n                    ID Verification Photo Requirements\n                "
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("ul", { staticClass: "rules-list" }, [
                _c("li", [
                  _vm._v(
                    "\n                        Nominated Photo ID\n                        "
                  ),
                  _c("strong", [
                    _vm._v(
                      "held up next ot your face clearly\n                            visible"
                    )
                  ]),
                  _vm._v(
                    "\n                        (not cut off)\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n                        Clear, focused image with good lighting\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n                        Face centered and lookign at the camera straight on,\n                        not tilted in any direction\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n                        Hair off your face, so that the edges of your face\n                        are visible\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n                        Eyes open, mouth closed\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n                        Neutral expression (not smiling, laughing or\n                        frowning)\n                    "
                  )
                ])
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }