import Vue from "vue";
import Vuex, {
    Payload,
    Store
} from "vuex";
import Customer from './modules/Customer';
import Lead from './modules/Lead';
import Identification from './modules/Identification';

Vue.use(Vuex);
const store = new Store({
    modules: {
        Customer,
        Lead,
        Identification
    }
});

export default store;
