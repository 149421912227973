<template>
    <div class="mk-supplierindex">
        <div class="container-fluid mb-4 py-3">
            <div class="row">
                <div
                    class="col-12 d-flex align-items-center justify-content-between"
                >
                    <h1 class="mb-0">Suppliers</h1>
                    <a href="/admin/suppliers/create" class="btn btn-primary"
                        >Create Supplier</a
                    >
                </div>
            </div>
        </div>

        <div class="container-fluid mb-4 bg-white card">
            <div class="row">
                <div class="col-12">
                    <div class="px-3 customer-card bg-white">
                        <paginated-table
                            v-if="suppliers.data"
                            :tableData="tableData"
                            :paginatedData="suppliers"
                            @pagination-change-page="getSuppliers"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Supplier from "../../models/Supplier";
export default {
    data() {
        return {
            suppliers: {},
            tableData: {
                columns: [
                    { label: "Name", name: "name", sortable: true },
                    { label: "Email", name: "email", sortable: true },
                    { label: "Phone", name: "phone", sortable: true },
                    {
                        label: "Created At",
                        name: "created_at",
                        datetime: true,
                        sortable: false
                    }
                ],
                edit: true,
                showSearch: false,
                showPerPage: true,
                viewUrl: "/admin/suppliers"
            }
        };
    },
    mounted() {
        this.getSuppliers();
    },
    methods: {
        async getSuppliers(
            page = 1,
            sortColumn = null,
            query = null,
            per_page = 100,
            desc = false
        ) {
            let suppliers = Supplier.page(page).params({
                per_page: per_page
            });
            if (sortColumn) {
                suppliers.orderBy(
                    (desc ? "-" : "") +
                        (sortColumn.sort_name || sortColumn.name)
                );
            } else {
                suppliers.orderBy("name");
            }

            suppliers
                .get()
                .then(res => {
                    this.loading = false;
                    this.suppliers = res;
                })
                .catch(errors => {
                    this.loading = false;
                    console.error(errors);
                });
        }
    }
};
</script>
