<template>
    <div>
        <div class="container-fluid bg-white card application-card mk-thankyou">
            <div class="row">
                <div class="col-12 position-relative">
                    <div class="app-card-decoration"></div>
                    <h1 class="">Verify Your Identity</h1>
                </div>

                <div class="col-12 mt-3">
                    <h3
                        style="margin-bottom: 60px; font-family: aktiv-grotesk-extended, sans-serif"
                    >
                        Step 2: Review your photo
                    </h3>
                </div>

                <div class="col-12">
                    <p>
                        Make sure your face and photo ID are clearly visible in
                        the frame. Click
                        <a
                            href="#"
                            @click.prevent="openModal = true"
                            class="help-link"
                            >here</a
                        >
                        to view the verification photo requirements again.
                    </p>
                </div>

                <div class="col-12 mt-3 col-lg-8">
                    <div class="d-flex flex-column align-items-center">
                        <img
                            v-if="image"
                            :src="image.url"
                            class="img-fluid"
                            :key="image.url"
                        />
                    </div>
                </div>

                <div class="col-lg-4 col-12 p-3">
                    <div
                        class="d-flex align-items-center justify-content-center flex-column"
                    >
                        <input
                            type="file"
                            v-show="false"
                            ref="file"
                            @change="uploadFiles"
                        />
                        <el-button
                            class="btn btn-primary w-100 my-2"
                            @click="openFile"
                        >
                            <i
                                class="fas fa-cloud-upload-alt mr-2 text-white"
                            ></i>
                            Select another photo
                        </el-button>

                        <span v-if="!isMobile">
                            or
                        </span>

                        <a
                            class="btn btn-primary w-100 my-2"
                            href="#"
                            @click.prevent="openFace"
                            target="_blank"
                            v-if="!isMobile"
                        >
                            <i class="fas fa-camera mr-2 text-white"></i>
                            Retake Photo
                        </a>

                        or

                        <el-button
                            class="btn btn-primary w-100 my-2"
                            @click="done"
                        >
                            <i class="fas fa-check-circle mr-2 text-white"></i>
                            I'm happy with my photo
                        </el-button>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="d-flex flex-md-row flex-column align-items-center justify-content-between mb-5"
        >
            <button
                class="btn-white-green-text mb-3 mb-md-0 mutable-mobile-width"
                @click="$emit('previous', true)"
            >
                Back
            </button>
        </div>
        <upload-rule :open-modal.sync="openModal"> </upload-rule>
    </div>
</template>

<script>
import Supplier from "../../../models/Supplier";
import Application from "../../../models/Application";
import Form from "form-backend-validation";
import Media from "../../../models/Media";
import axios from "axios";
export default {
    props: {
        initial_supplier: {
            type: Object,
            required: true
        },
        initial_application: {
            type: Object,
            required: true
        },
        linkToFace: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            application: this.initial_application,
            supplier: this.initial_supplier,
            image:
                this.initial_application.media.length > 0
                    ? this.initial_application.media
                          .filter(
                              m =>
                                  m.collection_name == "face_proof" ||
                                  m.collection_name == "documents"
                          )
                          .pop()
                    : null,
            form: new Form(),
            isMobile: false,
            loading: false,
            openModal: false
        };
    },
    mounted() {
        if (window.matchMedia("screen and (max-width: 768px)").matches)
            this.isMobile = true;
        
        Echo.channel(`photo_submitted.applications.${this.application.id}`)
            .listen('.photo.submitted', e => {
                console.log(e);
                this.refreshApplication();
            });
    },
    methods: {
        openFace() {
            window.open(this.linkToFace, "_blank");
        },
        async refreshApplication() {
            try {
                const res = await new Application()
                    .for(new Supplier(this.supplier))
                    .find(this.application.id);

                this.image = res.media
                    .filter(m => m.collection_name == "face_proof")
                    .pop();

                this.$emit("update-application", res);
            } catch(err) {
                console.error(err);
            }
        },
        openFile() {
            this.$refs.file.click();
        },
        done() {
            this.$emit("next", true);
        },
        async uploadFiles(e) {
            this.loading = true;
            let files = e.target.files;
            let local_files = [];

            for (let i = 0; i < files.length; i++) {
                local_files.push(files[i]);
            }

            let form = new FormData();
            form.append("type", "documents");
            local_files.forEach(file => {
                form.append("files[]", file);
            });

            try {
                const res = await axios.post(
                    `/api/v1/customer/suppliers/${this.supplier.id}/applications/${this.application.id}/medias`,
                    form,
                    {
                        headers: { "content-type": "multipart/form-data" }
                    }
                );

                this.$message({
                    type: "success",
                    message: "Upload Success"
                });
                this.loading = false;
                this.application.media = res.data.media;

                this.image = res.data.media
                    .filter(m => m.collection_name == "documents")
                    .pop();

                this.$emit("update-application", res.data);
            } catch (err) {
                this.loading = false;
                console.error(err);
                this.form.errors.record(err.response.data.errors);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
        }
    }
};
</script>
