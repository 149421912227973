<template>
    <div>
        <div class="container-fluid bg-white card application-card mk-appsum">
            <div class="row">
                <div class="col-12 position-relative">
                    <div class="app-card-decoration"></div>
                    <h1>Application Summary</h1>
                </div>
                <div class="col-12">
                    <p class="app-card-intro" style="margin-bottom: 60px;">We're almost there. Just before your final submission, please make sure that all the details of your application are correct.</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-3" style="margin-bottom: 30px;">
                    <label>First Name</label>
                    <p>{{ application.customer.first_name }}</p>
                </div>

                <div class="col-12 col-md-3" style="margin-bottom: 30px;">
                    <label>Last Name</label>
                    <p>{{ application.customer.last_name }}</p>
                </div>

                <div class="col-12" style="margin-bottom: 30px;">
                    <label>Authorised Supplier</label>
                    <p>{{ supplier.name }}</p>
                </div>

                <div class="col-12" style="margin-bottom: 30px;">
                    <label>Property Address</label>
                    <p>
                        {{
                            application.lead.extra_attributes.address
                                .formatted_address
                        }}
                    </p>
                </div>

                <div class="col-12 col-md-3" style="margin-bottom: 30px;">
                    <label>Agreement Date</label>
                    <p>{{ moment(new Date()).format("DD/MM/Y") }}</p>
                </div>

                <div class="col-12 col-md-3" style="margin-bottom: 60px;">
                    <label>Estimated Start Date</label>
                    <p>
                        {{
                            moment
                                .utc(application.lead.start_date, "YYYY-MM-DD")
                                .format("DD/MM/Y")
                        }}
                    </p>
                </div>
            </div>
            <!-- end application summary -->

            <!-- amount payable row -->
            <div class="row">
                <div class="col-12 mb-5">
                    <!-- <h3 class="text-blue font-heading" style="margin-bottom: 30px;">Amount Payable & Due Date</h3> -->
                    <h3 class="text-blue font-heading" style="margin-bottom: 30px;">Dates and Fees Payable</h3>
                    <p class="mb-1"><strong style="font-size:16px;">The quicker you repay your plan, the lower your fees.</strong></p>
                    <p>Enjoy our low fees when you pay off your plan earlier.</p>

                    <div
                        class="mb-3 d-inline-block"
                        style="padding: 5px 10px; background-color: #FF4E00; border-radius: 5px;"
                    >
                        <p class="mb-0 text-white"><strong class="text-white">Payment Schedule:</strong></p>
                    </div>

                    <p>The table below provides you with the fees applicable if your payment is received by the designated date.</p>

                    <div class="row">
                        <div class="col-12 col-md-4">
                            <pay-before-card
                                paydayCardBg="rgb(249, 249, 249)"
                                :payBeforeDays="45"
                                payBeforeFee="2.95%"
                                :payBeforeHighlightFee="true"
                                payBeforeInterest="0.0%"
                                :payBeforeAmountPayable="getTotalPayable(2.95, 0)"
                                :due-date="getDueDate(44)"
                            ></pay-before-card>
                        </div>

                        <div class="col-12 col-md-4">
                            <pay-before-card
                                paydayCardBg="rgba(255, 78, 0, 0.03)"
                                :payBeforeDays="60"
                                payBeforeFee="4.95%"
                                :payBeforeHighlightFee="false"
                                payBeforeInterest="0.0%"
                                :payBeforeAmountPayable="getTotalPayable(4.95, 0)"
                                :due-date="getDueDate(59)"
                            >
                            </pay-before-card>
                        </div>

                        <div class="col-12 col-md-4 schedule-mobile-card">
                            <pay-before-card
                                paydayCardBg="rgba(255, 78, 0, 0.12)"
                                :payBeforeDays="107"
                                payBeforeFee="5.0%"
                                :payBeforeHighlightFee="false"
                                payBeforeInterest="0.95%"
                                :payBeforeAmountPayable="getTotalPayable(5.0, 0.95)"
                                :payBeforeIsFinalCard="true"
                                :due-date="getDueDate(107)"
                            >
                            </pay-before-card>
                        </div>
                    </div>
                </div>

                <!-- old section -->
                <!-- <div class="col-md-4 col-12" style="margin-bottom: 30px;">
                    <label>Requested Plan Amount</label>
                    <el-input :value="amountString" disabled>
                         <p slot="prefix" class="mb-0" style="padding-top: 10px; padding-left: 10px;">$</p>
                    </el-input>
                </div>

                <div class="col-md-4 col-12" style="margin-bottom: 30px;">
                    <label>Fee</label>
                    <el-input value="5%" disabled> </el-input>
                </div>

                <div class="col-md-4 col-12" style="margin-bottom: 30px;">
                    <label>Interest</label>
                    <el-input value="0.9%" disabled> </el-input>
                </div>

                <div class="col-md-4 col-12" style="margin-bottom: 30px;">
                    <label>Total Amount Payable</label>
                    <p class="mb-0"><strong style="font-size: 24px;">$ {{totalPayable}}</strong></p>
                </div> -->

                <!-- end old section -->

                <!-- old section  -->
                <!-- <div class="col-md-4 col-12" style="margin-bottom: 30px;">
                    <label>107 Due Date</label>
                    <p class="mb-0"><strong style="font-size: 24px;">{{dueDate}}</strong></p>
       
                </div> -->
                <!-- end old section -->
            

                <div class="col-12" style="margin-bottom: 60px;">
                    <el-checkbox v-model="tc"><p><strong>I agree that I must pay the Approved Marketing Amount (AMA) and associated fees, on the Repayment Date, even if I have not sold the Property</strong></p></el-checkbox>
                </div>

                <!-- signature box -->
                <div class="col-12">
                    <h3 class="text-blue font-heading" style="margin-bottom: 30px;">Your Signature</h3>
                    <div class="">
                        <VueSignaturePad
                            class="app-signature"
                            ref="signaturePad"
                            :options="{
                                backgroundColor: '#fff'
                            }"
                            style="margin-bottom: 8px; border: 1px solid #e3e3e3;"
                        />
                    </div>
                    <p class="mb-0 has-interaction" @click="undo"><i class="fas fa-undo text-red mr-2"></i><strong class="text-red">Undo</strong></p>
                </div>
            </div>
            <!-- end amount payable row -->
        </div>

        <!-- progress buttons -->
        <div class="d-flex flex-md-row flex-column align-items-center justify-content-between mb-5 px-3 px-md-0">
            <button
                class="btn-white-green-text mutable-mobile-width order-2 order-md-1"
                @click="$emit('previous', true)"
            >Back</button>
            <el-button
                class="btn btn-primary mutable-mobile-width order-1 order-md-2 mb-3 mb-md-0"
                @click="save"
                :disabled="!tc"
            >Agree and submit</el-button>
        </div>
    </div>
</template>

<script>
import Application from "../../../models/Application";
import Supplier from "../../../../backend/models/Supplier";
import axios from "axios";
export default {
    props: {
        step: {
            type: Number,
            required: true
        },
        initial_supplier: {
            type: Object,
            required: true
        },
        initial_application: {
            type: Object,
            required: true
        }
    },
    components: {},
    data() {
        return {
            tc: false,
            application: this.initial_application,
            supplier: this.initial_supplier
        };
    },
    computed: {
        dueDate() {
            let startDay = new Date(this.application.lead.start_date);
            return moment(
                new Date(startDay.setDate(startDay.getDate() + 107))
            ).format("DD/MM/Y");
        },
        totalPayable() {
            return Number(
                this.application.lead.dollar_requested_amount * ((5.9 / 100) + 1)
            ).toLocaleString("en-AU", {minimumFractionDigits: 2, maximumFractionDigits: 2});
        },
        amountString() {
            return Number(
                this.application.lead.dollar_requested_amount
            ).toLocaleString("en-AU", {minimumFractionDigits: 2, maximumFractionDigits: 2});
        }
    },
    methods: {
        /**
         * @param {number} interest
         * @param {number} fee
         * 
         * @return {string}
         */
        getTotalPayable(interest, fee) {
            const totalPercentage =  interest + fee;
            console.log(totalPercentage); 
            return Number(
                this.application.lead.dollar_requested_amount * ( totalPercentage / 100 + 1)
            ).toLocaleString("en-AU", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        },
        /**
         * @param {number} addDay
         * @return {string}
         */
        getDueDate(addDay) {
            let startDay = new Date(this.application.lead.start_date);
            return moment(
                new Date(startDay.setDate(startDay.getDate() + addDay))
            ).format("ddd, Do MMMM YYYY");
        },
        async save() {
            if (!this.tc) return;
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

            if (!isEmpty) {
                try {
                    // add signature
                    this.application.signature = data;
                    const res = await new Application(this.application)
                        .for(new Supplier(this.supplier))
                        .save();

                    this.$emit("next", true);
                } catch (err) {
                    console.error(err);

                    var errorText = "";
                    _.forEach(err.response.data.errors, function(value) {
                        errorText += "" + value + " ";
                    });
                    this.$message({
                        type: "error",
                        message: errorText
                    });
                }
            } else {
                this.$message({
                    type: "error",
                    message: "Please sign in the box provided"
                });
            }
        },
        undo() {
            this.$refs.signaturePad.undoSignature();
        }
    }
};
</script>

<style lang="sass" scoped>
.schedule-mobile-card
    @media only screen and (max-width: 768px) 
      margin-top: 35px
</style>

