var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container-fluid bg-white card py-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"supplier_user_name"}},[_vm._v("Supplier Agent Name")]),_vm._v(" "),_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.application.lead.sales_representative),callback:function ($$v) {_vm.$set(_vm.application.lead, "sales_representative", $$v)},expression:"application.lead.sales_representative"}})],1),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"supplier_name"}},[_vm._v("Supplier Name")]),_vm._v(" "),_c('el-input',{attrs:{"value":_vm.supplier.name,"disabled":""}})],1),_vm._v(" "),_c('div',{staticClass:"col-12 mt-3"},[_c('label',{attrs:{"for":"property_address"}},[_vm._v("Property Address")]),_vm._v(" "),_c('el-input',{attrs:{"disabled":""},model:{value:(
                        _vm.application.lead.extra_attributes.address
                            .formatted_address
                    ),callback:function ($$v) {_vm.$set(_vm.application.lead.extra_attributes.address
                            , "formatted_address", $$v)},expression:"\n                        application.lead.extra_attributes.address\n                            .formatted_address\n                    "}})],1),_vm._v(" "),_c('div',{staticClass:"col-6 mt-3"},[_c('label',{attrs:{"for":"amount_requested"}},[_vm._v("Amount Requested")]),_vm._v(" "),_c('div',{staticClass:"el-input is-disabled"},[_c('currency-input',{staticClass:"el-input__inner",attrs:{"disabled":""},model:{value:(_vm.application.lead.dollar_requested_amount),callback:function ($$v) {_vm.$set(_vm.application.lead, "dollar_requested_amount", $$v)},expression:"application.lead.dollar_requested_amount"}})],1)]),_vm._v(" "),_c('div',{staticClass:"col-6 flex-column d-flex mt-3"},[_c('label',{attrs:{"for":"start_date"}},[_vm._v("Estimated Start Date")]),_vm._v(" "),_c('el-date-picker',{staticClass:"w-100",attrs:{"disabled":"","format":"dd/MM/yyyy","value-format":"yyyy-MM-dd","type":"date"},model:{value:(_vm.application.lead.start_date),callback:function ($$v) {_vm.$set(_vm.application.lead, "start_date", $$v)},expression:"application.lead.start_date"}})],1),_vm._v(" "),_c('div',{staticClass:"col-6 mt-3"},[_c('label',{attrs:{"for":"fund_required_for"}},[_vm._v("Fund Required For")]),_vm._v(" "),_c('el-input',{attrs:{"disabled":"","value":_vm.application.lead.fund_purposes
                            .map(function (fp) { return fp.name; })
                            .join(',')}}),_vm._v(" "),(
                        _vm.application.lead.fund_purposes.filter(
                            function (fp) { return fp.name == 'Other'; }
                        ).length > 0
                    )?_c('el-input',{staticClass:"mt-3",attrs:{"disabled":""},model:{value:(_vm.application.lead.other_purpose),callback:function ($$v) {_vm.$set(_vm.application.lead, "other_purpose", $$v)},expression:"application.lead.other_purpose"}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }