<template>
    <div class="mt-3">
        <div class="container-fluid mb-4 bg-white card">
            <div class="row">
                <div class="col-12">
                    <div class="customer-card bg-white pt-3">
                        <div class="row">
                            <div
                                class="col-12 d-flex align-items-center justify-content-between"
                            >
                                <h2 class="mb-0">Applications</h2>
                                <el-select
                                    v-model="status"
                                    @change="getApplications()"
                                >
                                    <el-option
                                        v-for="status in statuses"
                                        :key="status.name"
                                        :value="status.name"
                                        :label="status.name"
                                    >
                                    </el-option>
                                </el-select>
                            </div>

                            <div class="col-12">
                                <paginated-table
                                    v-if="applications.data"
                                    :tableData="tableData"
                                    :paginatedData="applications"
                                    :initial_page="15"
                                    @pagination-change-page="getApplications"
                                >
                                    <template v-slot:right-row="row">
                                        <div
                                            class="d-flex align-items-center justify-content-end"
                                            @click.stop
                                        >
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                content="Edit Application"
                                                placement="top-start"
                                            >
                                                <a
                                                    :href="
                                                        `/admin/suppliers/${supplier.id}/applications/${row.id}/edit`
                                                    "
                                                    style="text-decoration:none;"
                                                    class="btn-light-blue icon-btn mr-3 d-flex justify-content-center align-items-center"
                                                >
                                                    <i
                                                        class="fas fa-edit text-green"
                                                    ></i>
                                                </a>
                                            </el-tooltip>
                                            <el-dropdown
                                                @command="
                                                    command =>
                                                        handleCommand(
                                                            command,
                                                            row
                                                        )
                                                "
                                            >
                                                <span
                                                    class="btn-light-blue icon-btn mr-3 d-flex justify-content-center align-items-center"
                                                >
                                                    <i
                                                        class="fas fa-code-branch text-green"
                                                    ></i>
                                                </span>
                                                <el-dropdown-menu
                                                    slot="dropdown"
                                                >
                                                    <el-dropdown-item
                                                        command="new_plan"
                                                    >
                                                        Create New Plan
                                                        Variation
                                                    </el-dropdown-item>
                                                    <el-dropdown-item
                                                        command="edit_plan"
                                                        v-if="
                                                            row.plan_variations
                                                                .length > 0
                                                        "
                                                    >
                                                        Edit Latest Plan
                                                        Variation
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </div>
                                    </template>
                                </paginated-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Application from "../../models/Application";
import Supplier from "../../models/Supplier";
import ApplicationStatus from "../../constants/ApplicationStatus";
export default {
    props: {
        supplier: {
            type: Object,
            required: true
        },
        applicationStatuses: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            applications: {},
            status: "Current",
            tableData: {
                columns: [
                    {
                        label: "First Name",
                        name: "customer.first_name",
                        sortable: false
                    },
                    {
                        label: "Email",
                        name: "customer.email",
                        sortable: false
                    },
                    {
                        label: "Phone",
                        name: "customer.phone",
                        sortable: false
                    },
                    {
                        label: "Submitted At",
                        name: "submitted_at",
                        sortable: true
                    },
                    {
                        label: "Source",
                        name: "lead.source",
                        badge: true,
                        badge_coloring: value => {
                            return value.lead && value.lead.source
                                ? "#18206F"
                                : "#FF968D";
                        },
                        text_color: "#fff",
                        conditional_value: value => {
                            return value.lead && value.lead.source
                                ? "elepay"
                                : "Fast Track";
                        },
                        sortable: false
                    },
                    {
                        label: "Status",
                        name: "application_status.status",
                        sortable: false
                    },
                    {
                        label: "Created At",
                        name: "created_at",
                        datetime: true,
                        sortable: false
                    },
                    {
                        label: "Estimated Start Date",
                        name: "lead.start_date",
                        whole_date: true,
                        sortable: false
                    },
                    {
                        custom_component: true
                    }
                ],
                edit: true,
                showSearch: false,
                showPerPage: true,
                viewUrl: `/admin/suppliers/${this.supplier.id}/applications`
            }
        };
    },
    mounted() {
        this.getApplications();
    },
    methods: {
        handleCommand(value, row) {
            if (value === "new_plan") {
                window.location.href = `/admin/suppliers/${this.supplier.id}/applications/${row.id}/plans/create`;
            } else {
                window.location.href = `/admin/suppliers/${this.supplier.id}/applications/${row.id}/plans/${row.plan_variations[0].id}/edit`;
            }
        },
        async getApplications(
            page = 1,
            sortColumn = null,
            query = null,
            per_page = 15,
            desc = false
        ) {
            let applications = Application.page(page).params({
                per_page: per_page
            });
            if (sortColumn) {
                applications.orderBy(
                    (desc ? "-" : "") +
                        (sortColumn.sort_name || sortColumn.name)
                );
            } else {
                applications.orderBy("-created_at");
            }

            applications
                .for(new Supplier(this.supplier))
                .where("application_status", this.status)
                .include(
                    "customer",
                    "lead",
                    "application_status",
                    "plan_variations"
                )
                .get()
                .then(res => {
                    this.loading = false;
                    this.applications = res;
                })
                .catch(errors => {
                    this.loading = false;
                    console.error(errors);
                });
        }
    },
    computed: {
        statuses() {
            return Object.keys(ApplicationStatus).map(key => {
                return ApplicationStatus[key];
            });
        }
    }
};
</script>
