<template>
    <div class="position-relative">
        <div v-if="payBeforeIsFinalCard" class="final-payment-warning">
            <p class="mb-0 text-white text-center fw-700">
                Final Date for Repayment
            </p>
        </div>
        <div class="payday-card" :style="{ backgroundColor: paydayCardBg }">
            <div>
                <p class="mb-0 font-14 font-heading text-blue fw-700">
                    Pay before
                </p>
                <p class="font-18 font-heading text-blue fw-700">
                    {{ payBeforeDays }} days
                </p>
                <p>
                    If you pay your plan in full by
                    <strong class="text-blue font-14">{{
                        innerDueDate
                    }}</strong>
                </p>
            </div>

            <div class="deco-orange-divider"></div>

            <div>
                <div
                    class="d-flex justify-content-between align-items-baseline"
                >
                    <p style="margin-bottom: 20px;">Fee</p>
                    <p
                        class="font-14"
                        :class="payBeforeHighlightFee ? 'highlight-fee' : ''"
                        style="margin-bottom: 20px;"
                    >
                        {{ payBeforeFee }}
                    </p>
                </div>

                <div class="d-flex justify-content-between">
                    <p class="mb-0">Interest</p>
                    <p class="mb-0 font-14">{{ payBeforeInterest }}</p>
                </div>
            </div>

            <div class="deco-orange-divider"></div>

            <div>
                <p style="margin-bottom: 10px;">Amount Payable</p>
                <p class="mb-0">
                    <strong class="text-blue"
                        >${{ innerPayBeforeAmountPayable }}</strong
                    >
                </p>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.payday-card {
    padding: 16px 17px;
    margin-top: 50px;
}

.final-payment-warning {
    position: absolute;
    top: -33px;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #ff4e00;
}

.payday-card p {
    font-size: 14px;
}

.highlight-fee {
    color: rgb(142, 166, 4);
    font-size: 18px !important;
    font-weight: 700;
}

.deco-orange-divider {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    height: 1px;
    background-color: rgb(255, 78, 0);
}
</style>

<script>
export default {
    props: {
        paydayCardBg: {
            type: String,
            required: true
        },
        payBeforeDays: {
            type: Number,
            required: true
        },
        payBeforeFee: {
            type: String,
            required: true
        },
        payBeforeHighlightFee: {
            type: Boolean
        },
        payBeforeInterest: {
            type: String,
            required: true
        },
        payBeforeAmountPayable: {
            type: String,
            required: true
        },
        payBeforeIsFinalCard: {
            type: Boolean
        },
        dueDate: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            innerDueDate: this.dueDate,
            innerPayBeforeAmountPayable: this.payBeforeAmountPayable
        };
    },
    watch: {
        dueDate(to, from) {
            this.innerDueDate = to;
        },
        payBeforeAmountPayable(to, from) {
            this.innerPayBeforeAmountPayable = to;
        }
    }
};
</script>
