var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "container-fluid bg-white card application-card mk-thankyou"
        },
        [
          _c("div", { staticClass: "row" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-8 col-12 order-2 order-sm-12" }, [
              _c("div", [
                _c("p", [
                  _vm._v(
                    "\n                        Upload a photo from your computer or phone gallery,\n                        or take a photo using your webcam or phone camera\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-5 mb-3 row margin-mobile mr-3" }, [
                  _c("div", { staticClass: "col-12 d-md-none" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-center align-items-center my-1"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ],
                          ref: "file",
                          attrs: { type: "file" },
                          on: { change: _vm.uploadFiles }
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "btn btn-primary w-100 mt-0",
                            on: { click: _vm.openFile }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "fas fa-cloud-upload-alt mr-2 text-white"
                            }),
                            _vm._v(
                              "\n                                    Upload Photo\n                                "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  !_vm.isMobile
                    ? _c("div", { staticClass: "col-12 d-md-none" }, [
                        _vm._m(2)
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isMobile
                    ? _c("div", { staticClass: "col-12 d-md-none" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center align-items-center my-1"
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-primary w-100 mt-0",
                                attrs: {
                                  disabled: !_vm.application.id,
                                  href: "#",
                                  target: "_blank"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.openFace($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-camera mr-2 text-white"
                                }),
                                _vm._v(
                                  "\n                                    Take Photo\n                                "
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 d-none d-md-block" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-start align-items-center my-1"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ],
                          ref: "file",
                          attrs: { type: "file" },
                          on: { change: _vm.uploadFiles }
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "btn btn-primary mt-0 mr-2",
                            on: { click: _vm.openFile }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "fas fa-cloud-upload-alt mr-2 text-white"
                            }),
                            _vm._v(
                              "\n                                    Upload Photo\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.isMobile
                          ? _c("span", { staticClass: "p-2" }, [_vm._v("or")])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isMobile
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "btn btn-primary mt-0 ml-2 el-button el-button--default",
                                attrs: {
                                  disabled: !_vm.application.id,
                                  href: "#",
                                  target: "_blank"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.openFace($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-camera mr-2 text-white"
                                }),
                                _vm._v(
                                  "\n                                    Take Photo\n                                "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-lg-4 p-3 col-12 order-1 order-sm-12 mb-3",
                staticStyle: { background: "rgba(142, 166, 4, 0.1)" }
              },
              [
                _c("p", [
                  _vm._v(
                    "\n                    Note: The graphic below shows you the correct way to\n                    take your verification photo for the best results on the\n                    identify verification process.\n                "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n                    For more information on the photo requirements click\n                    "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "help-link",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.openModal = true
                        }
                      }
                    },
                    [_vm._v("here")]
                  )
                ]),
                _vm._v(" "),
                _vm._m(3)
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-md-row flex-column align-items-center justify-content-between mb-5"
        },
        [
          _c(
            "button",
            {
              staticClass:
                "btn-white-green-text mb-3 mb-md-0 mutable-mobile-width",
              on: {
                click: function($event) {
                  return _vm.$emit("previous", true)
                }
              }
            },
            [_vm._v("\n            Back\n        ")]
          )
        ]
      ),
      _vm._v(" "),
      _c("upload-rule", {
        attrs: { "open-modal": _vm.openModal },
        on: {
          "update:openModal": function($event) {
            _vm.openModal = $event
          },
          "update:open-modal": function($event) {
            _vm.openModal = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 position-relative" }, [
      _c("div", { staticClass: "app-card-decoration" }),
      _vm._v(" "),
      _c("h1", {}, [_vm._v("Verify Your Identity")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 mt-3" }, [
      _c(
        "h3",
        {
          staticStyle: {
            "margin-bottom": "60px",
            "font-family": "aktiv-grotesk-extended, sans-serif"
          }
        },
        [
          _vm._v(
            "\n                    Step 1: Upload your photo\n                "
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "d-flex justify-content-center align-items-center my-1 p-2"
      },
      [_c("span", [_vm._v(" or")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "img-fluid mb-1",
        staticStyle: { border: "1px solid #8ea604" },
        attrs: { src: "/img/elements/verification-acceptable.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }