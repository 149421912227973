<template>
    <div v-loading="loading">
        <div class="container-fluid px-0 mk-supplier">
            <div class="row py-3">
                <div
                    class="col-10 d-flex justify-content-between align-items-center"
                >
                    <h1 class="mb-0">Supplier</h1>
                </div>
                <div
                    class="col-2 d-flex justify-content-end align-items-center"
                >
                    <el-popconfirm
                        confirm-button-text="Delete"
                        cancel-button-text="Don't Delete"
                        icon="far fa-times-circle"
                        icon-color="#F56C6C"
                        title="Are you sure to delete this supplier?"
                        @onConfirm="deleteSupplier"
                        v-if="supplier.id"
                    >
                        <el-button
                            type="danger"
                            v-if="supplier.id"
                            slot="reference"
                        >
                            Delete
                        </el-button>
                    </el-popconfirm>
                    <el-button
                        type="primary"
                        @click="handleSubmit"
                        class="ml-3"
                    >
                        Save
                    </el-button>
                </div>
            </div>
        </div>

        <div class="container-fluid bg-white card">
            <div class="row py-3">
                <div class="form-group col-md-4">
                    <label for="name">Name</label>
                    <el-input
                        placeholder="Name"
                        v-model="supplier.name"
                    ></el-input>
                </div>
                <div class="form-group col-md-4">
                    <label for="email">Email</label>
                    <el-input
                        placeholder="Email"
                        v-model="supplier.email"
                    ></el-input>
                </div>
                <div class="form-group col-md-4">
                    <label for="phone">Phone</label>

                    <vue-tel-input
                        mode="international"
                        :class="['w-100 form-control-borders']"
                        :onlyCountries="['AU', 'GB', 'NZ', 'US']"
                        style="min-height:38px !important"
                        v-model="supplier.phone"
                    ></vue-tel-input>
                </div>
                <div class="form-group col-md-4">
                    <label for="principal">Principal</label>
                    <el-input
                        placeholder="Principal"
                        v-model="supplier.principal"
                    ></el-input>
                </div>
                <div class="form-group col-md-4">
                    <div class="input-group">
                        <label for="status">Status</label>

                        <el-select
                            v-model="supplier.supplier_status_id"
                            class="w-100"
                            placeholder="Filter Supplier Status"
                        >
                            <el-option
                                v-for="status in supplierStatuses"
                                :key="status.id"
                                :value="status.id"
                                :label="status.name"
                            ></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <div class="input-group">
                        <label for="email_prefix">Email Prefix</label>
                        <el-input
                            placeholder="e.g., noreply"
                            v-model="supplier.email_prefix"
                        >
                            <template slot="append">@elepay.com.au </template>
                        </el-input>
                    </div>
                </div>
                <div class="form-group col-md-4" v-if="supplier.id">
                    <div
                        class="h-100 d-flex align-items-center justify-content-start"
                    >
                        <img
                            :src="supplier.icon_link"
                            v-if="supplier.icon_link"
                            @click="openUpload"
                            class="has-interaction mt-auto"
                            style="max-height: 40px;"
                        />

                        <el-button
                            @click="openUpload"
                            class="mt-auto ml-auto btn-green-text"
                            >Upload Icon</el-button
                        >

                        <input
                            type="file"
                            v-show="false"
                            @change="uploadIcon"
                            ref="icon_uploader"
                            accept="image/*"
                        />
                    </div>
                </div>

                <!-- tenant custom landing page  -->
                <!-- <div class="form-group col-md-4" v-if="supplier.id">
                    <div
                        class="h-100 d-flex align-items-center justify-content-start"
                    >
                        <el-checkbox v-model="supplier.has_custom_landing_page"
                            >Use Custom Landing Page</el-checkbox
                        >

                        <p>
                            <a
                                :href="
                                    `/admin/suppliers/${supplier.id}/landing_pages/create`
                                "
                                >Create Landing Page
                            </a>
                        </p>
                    </div>
                </div> -->
            </div>
        </div>

        <supplier-user
            v-if="supplier.users.length > 0 && !supplier.id"
            :initial_user="supplier.users[0]"
            :roles="roles"
            :supplier="supplier"
            @update-user="user => (supplier.users[0] = user)"
        ></supplier-user>

        <supplier-user-index v-if="supplier.id" :supplier="supplier">
        </supplier-user-index>

        <email-template-index
            :supplier="supplier"
            v-if="supplier.id"
        ></email-template-index>

        <div class="row">
            <lead-form-index
                v-if="supplier.id"
                :supplier="supplier"
                class="col-6"
            ></lead-form-index>
            <application-form-index
                v-if="supplier.id"
                :supplier="supplier"
                class="col-6"
                :shorten-link-domain="shortenLinkDomain"
            ></application-form-index>
        </div>

        <lead-index :supplier="supplier" v-if="supplier.id"></lead-index>
        <application-index
            :supplier="supplier"
            v-if="supplier.id"
            :application-statuses="applicationStatuses"
        >
        </application-index>

        <plan-variation-index :supplier="supplier" v-if="supplier.id">
        </plan-variation-index>
    </div>
</template>

<script>
import Supplier from "../../models/Supplier";
export default {
    props: {
        supplierStatuses: {
            type: Array,
            required: true
        },
        roles: {
            type: Array,
            required: true
        },
        applicationStatuses: {
            type: Array,
            required: true
        },
        initial_supplier: {
            type: Object,
            default: () => {
                return {
                    supplier_status_id: null,
                    name: null,
                    email: null,
                    principal: null,
                    phone: null,
                    has_custom_landing_page: false,
                    users: [
                        {
                            first_name: null,
                            last_name: null,
                            email: null,
                            password: null,
                            password_confirmation: null,
                            roles: []
                        }
                    ]
                };
            }
        },
        shortenLinkDomain: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            supplier: this.initial_supplier,
            loading: false
        };
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            new Supplier(this.supplier)
                .save()
                .then(res => {
                    this.loading = false;
                    if (!this.initial_supplier.id) {
                        this.$message({
                            type: "success",
                            message: "Saved"
                        });
                        window.location.replace(
                            `/admin/suppliers/${res.id}/edit`
                        );
                        window.history.pushState(
                            null,
                            null,
                            `/admin/suppliers/${res.id}/edit`
                        );
                    } else {
                        // if it is edit operation
                        const event = new CustomEvent("save_supplier", {
                            detail: true
                        });
                        document.dispatchEvent(event);
                    }
                })
                .catch(err => {
                    this.loading = false;
                    console.error(err);
                    var errorText = "";
                    _.forEach(err.response.data.errors, function(value) {
                        errorText += "" + value + " ";
                    });
                    this.$message({
                        type: "error",
                        message: errorText
                    });
                });
        },
        async deleteSupplier() {
            try {
                await new Supplier(this.supplier).delete();
                window.location.replace("/admin/suppliers");
                window.history.pushState(null, null, "/admin/suppliers");
            } catch (err) {
                console.error(err);
            }
        },
        openUpload() {
            this.$refs.icon_uploader.click();
        },
        async uploadIcon(event) {
            try {
                const file = event.target.files[0];
                let form = new FormData();
                form.append("icon", file);
                const res = await axios.post(
                    `/api/v1/admin/suppliers/${this.supplier.id}/icons`,
                    form,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                );

                this.supplier.icon_link = res.data.icon_link;
            } catch (err) {
                console.error(err);
            }
        }
    }
};
</script>
