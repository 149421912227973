<template>
    <div>
        <div class="container-fluid bg-white card application-card mk-pdeclaration">
            <div class="row">

                <div v-if="form.errors.any()" class="col-12">
                    <div class="show-form-error">
                        <div v-for="(errors, index) in form.errors.all()" :key="index">
                            <ul class="mb-0">
                                <li v-for="(error, i) in errors" :key="i">{{error}}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-12 position-relative">
                    <div class="app-card-decoration"></div>
                    <h1>Personal Declaration</h1>
                </div>
                <div class="col-12">
                    <p class="app-card-intro" style="margin-bottom: 60px;">Here are a few basic questions which provide us with an insight into your current financial position.</p>

                    <!-- old text from 26-6-2020 update -->
                    <!-- <p class="app-card-intro" style="margin-bottom: 60px;">The details you provide below are critical before any funding can be approved. So please make sure you provide us with accurate information which can be verified via various Australian external authorised credited sources.</p> -->
                </div>
            </div>


            <div class="row">
                <div class="col-12">
                    <h3 class="text-blue font-heading" style="margin-bottom: 30px;">Credit Declaration</h3>
                    <p>Please read the following statements and tick both to agree.</p>
                </div>

                <div class="col-12">
                    <el-checkbox
                        style="margin-bottom: 30px;"
                        :value="!!declaration.is_not_bankrupt"
                        @change="value => (declaration.is_not_bankrupt = value)"
                    >I declare that I am not bankrupt or insolvent and I am not subject to any unsatisfied judgements
                    </el-checkbox>
                    <el-checkbox
                        style="margin-bottom: 30px;"
                        :value="
                            !!declaration.is_not_property_subject_to_changes
                        "
                        @change="
                            value =>
                                (declaration.is_not_property_subject_to_changes = value)
                        "
                    >The property is not subject to any event (for example mortgage default) which would affect my (or the owners) ability to sell the property.
                    </el-checkbox>

                    <p class="app-card-intro" style="margin-bottom: 20px;">If you were unable to tick both of the above boxes, simply tick this box below and one of our support crew will reach out to have a chat once we receive your application so we can understand more about your current situation.</p>

                    <!-- old text from 26-6-2020 update -->
                    <!-- <p class="app-card-intro" style="margin-bottom: 20px;">Even if you are not listing your property for sale, both items above must be checked to proceed. If you have any circumstances that prevent you from agreeing with the declarations, select the bottom option in order to have an Elevare Credit Analyst call you upon submission of your application.</p> -->
                    <el-checkbox
                        style="margin-bottom: 60px;"
                        :disabled="disableThirdOption"
                        @change="value => (declaration.speak_to_elevare_credit_analyst = value)"
                        :value="!!declaration.speak_to_elevare_credit_analyst"
                    >Yes please contact me to discuss, thanks.

                    <!-- old text from 26-6-2020 update -->
                    <!-- I would like to speak with an Elevare Credit Analyst to discuss my situation further. -->
                    </el-checkbox>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h3 class="text-blue font-heading" style="margin-bottom: 30px;">Employment Details</h3>
                </div>

                <div class="col-12 col-lg-6">
                    <label>Current Employer</label>
                    <el-input v-model="employment.current_employer" :class="{'is-error':form.errors.has('employment.current_employer')}"> </el-input>
                </div>

                <div class="col-12 col-lg-6 d-flex flex-column has-dropdown-element">
                    <label>Duration of Employment</label>
                    <el-select v-model="employment.employment_duration_id" :class="{'is-error':form.errors.has('employment.employment_duration_id')}" class="margin-b-30">
                        <el-option
                            v-for="duration in employmentDurations"
                            :key="duration.id"
                            :value="duration.id"
                            :label="duration.name"
                        >
                        </el-option>
                    </el-select>
                </div>


                <div class="col-12 col-lg-6">
                    <label>Position</label>
                    <el-input v-model="employment.position" :class="{'is-error':form.errors.has('employment.position')}"></el-input>
                </div>

                <div class="col-12 col-lg-6 d-flex flex-column has-dropdown-element">
                    <label>Type of Employment</label>
                    <el-select v-model="employment.employment_type_id" :class="{'is-error':form.errors.has('employment.employment_type_id')}" class="margin-b-30">
                        <el-option
                            v-for="type in employmentTypes"
                            :key="type.id"
                            :value="type.id"
                            :label="type.name"
                        >
                        </el-option>
                    </el-select>
                </div>

                <div class="col-12 col-lg-6 d-flex flex-column has-dropdown-element" style="margin-bottom: 60px;">
                    <label>Gross Annual Income</label>
                    <el-select v-model="employment.income_range_id" :class="{'is-error':form.errors.has('employment.income_range_id')}">
                        <el-option
                            v-for="incomeRange in incomeRanges"
                            :key="incomeRange.id"
                            :value="incomeRange.id"
                            :label="incomeRange.name"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>


            <!-- Start terms -->
            <div class="row">
                <div class="col-12">
                    <h3 class="text-blue font-heading" style="margin-bottom: 30px;">Terms & Conditions</h3>
                </div>

                <div class="col-12">
                    <p class="app-card-intro" style="margin-bottom: 30px;">Before we proceed to the final application submission, we want to be sure that you’ve read our terms and conditions, and that you are happy and comfortable with all the provisions in this policy.</p>
                </div>

                <div class="col-12">
                    <el-button
                        @click="openTCModal = true" 
                        :disabled="!canProgress"
                        class="btn btn-primary mutable-mobile-width"
                        style="margin-bottom: 30px;"
                    >
                        View Terms & Conditions
                    </el-button>
                    
                    <!-- TEST PLACEHOLDER -->
                    <!-- <el-checkbox v-model="tc" class="">I confirm that I have read the Terms and Conditions.</el-checkbox> -->
                </div>
            </div>
        </div>

        <div class="d-flex flex-md-row flex-column align-items-center justify-content-between mb-5 px-3 px-md-0">
            <button
                class="btn-white-green-text mb-3 mb-md-0 mutable-mobile-width"
                @click="$emit('previous', true)"
            >Back</button>
            
            <!-- <el-button
                class="btn btn-primary"
                @click="save"
                :disabled="!tc || !canProgress"
            >Confirm and continue</el-button> -->
        </div>


        <terms-and-conditions-modal
            :open-modal.sync="openTCModal"
            @accepted="save"
        ></terms-and-conditions-modal>
    </div>
</template>


<script>
import Form from 'form-backend-validation';
export default {
    props: {
        initial_supplier: {
            type: Object
        },
        initial_application: {
            type: Object
        },
        incomeRanges: {
            type: Array
        },
        employmentTypes: {
            type: Array
        },
        employmentDurations: {
            type: Array
        }
    },
    data() {
        return {
            supplier: this.initial_supplier,
            application: this.initial_application,
            declaration: this.initial_application.credit_worthiness || {
                is_not_bankrupt: false,
                is_not_property_subject_to_changes: false,
                speak_to_elevare_credit_analyst: false
            },
            employment: this.initial_application.employments[0] || {
                current_employer: null,
                income_range_id: null,
                employment_type_id: null,
                employment_duration_id: null,
                position: null
            },
            tc: false,
            openTCModal: false,
            form: new Form(),
        };
    },
    methods: {
        async save() {
            try {
                if (!this.canProgress){
                    this.$message({
                        type:'error',
                        message: 'Credit Worthiness Declaration boxes should be ticked or tick speck to Elevare credit analyst'
                    })
                    return false;
                }
                this.form.clear();
                const res = await axios.post(
                    `/api/v1/customer/suppliers/${this.supplier.id}/applications/${this.application.id}/credit_worthinesses`,
                    {
                        credit_worthiness: this.declaration,
                        employment: this.employment
                    }
                );
                this.$emit('update-application', res.data);
                this.$emit("next", true);
            } catch (err) {
                console.error(err);
                this.form.errors.record(err.response.data.errors);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                // var errorText = "";
                // _.forEach(err.response.data.errors, function(value) {
                //     errorText += "" + value + " ";
                // });
                // this.$message({
                //     type: "error",
                //     message: errorText
                // });
            }
        }
    },
    computed:{
        canProgress(){
            if (this.declaration.speak_to_elevare_credit_analyst) return true;
            else if (this.declaration.is_not_bankrupt && this.declaration.is_not_property_subject_to_changes) return true;
            else return false;
        },
        disableThirdOption(){
            if (this.declaration.is_not_bankrupt && this.declaration.is_not_property_subject_to_changes) {
                return true;
            }
            return false;
        }
    }
};
</script>
