var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-10 col-lg-8 mx-auto pb-3 bg-white" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-12 d-flex justify-content-center align-items-center flex-column"
              },
              [_c("h1", { staticClass: "text-center" }, [_vm._v("Well Done!")])]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c(
                "p",
                {
                  staticClass: "app-card-intro text-center",
                  staticStyle: { "margin-bottom": "30px" }
                },
                [
                  _vm._v(
                    "\n                    A plan variation has been submitted.\n                    "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n\n                    Elevare Pay Easy will take care of it from here. We'll\n                    let you know if your plan variation has been processed.\n                "
                  )
                ]
              )
            ])
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }