var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "position-relative" }, [
    _vm.payBeforeIsFinalCard
      ? _c("div", { staticClass: "final-payment-warning" }, [
          _c("p", { staticClass: "mb-0 text-white text-center fw-700" }, [
            _vm._v("\n            Final Date for Repayment\n        ")
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "payday-card",
        style: { backgroundColor: _vm.paydayCardBg }
      },
      [
        _c("div", [
          _c(
            "p",
            { staticClass: "mb-0 font-14 font-heading text-blue fw-700" },
            [_vm._v("\n                Pay before\n            ")]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "font-18 font-heading text-blue fw-700" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.payBeforeDays) +
                " days\n            "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                If you pay your plan in full by\n                "
            ),
            _c("strong", { staticClass: "text-blue font-14" }, [
              _vm._v(_vm._s(_vm.innerDueDate))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "deco-orange-divider" }),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-baseline"
            },
            [
              _c("p", { staticStyle: { "margin-bottom": "20px" } }, [
                _vm._v("Fee")
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "font-14",
                  class: _vm.payBeforeHighlightFee ? "highlight-fee" : "",
                  staticStyle: { "margin-bottom": "20px" }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.payBeforeFee) +
                      "\n                "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c("p", { staticClass: "mb-0" }, [_vm._v("Interest")]),
            _vm._v(" "),
            _c("p", { staticClass: "mb-0 font-14" }, [
              _vm._v(_vm._s(_vm.payBeforeInterest))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "deco-orange-divider" }),
        _vm._v(" "),
        _c("div", [
          _c("p", { staticStyle: { "margin-bottom": "10px" } }, [
            _vm._v("Amount Payable")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "mb-0" }, [
            _c("strong", { staticClass: "text-blue" }, [
              _vm._v("$" + _vm._s(_vm.innerPayBeforeAmountPayable))
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }