var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row mk-dashboard" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.dashboard.data
            ? _c(
                "paginated-table",
                {
                  attrs: {
                    tableData: _vm.tableData,
                    paginatedData: _vm.dashboard,
                    initial_page: 5
                  },
                  on: { "pagination-change-page": _vm.getData }
                },
                [
                  _c("template", { slot: "after-search-slot" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-end align-items-center ml-3"
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "range-separator": "-",
                            format: "dd/MM/yyyy",
                            "value-format": "yyyy-MM-dd",
                            "start-placeholder": "Start date",
                            "end-placeholder": "End date"
                          },
                          on: {
                            change: function($event) {
                              return _vm.getData(1)
                            }
                          },
                          model: {
                            value: _vm.dateRange,
                            callback: function($$v) {
                              _vm.dateRange = $$v
                            },
                            expression: "dateRange"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [_c("h3", [_vm._v("Leads")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }