const state = {
    identification: {},
    linkToFace: null
};

const getters = {
    getIdentification: state => state.identification,
    getLinkToFace: state => state.linkToFace,
};

const mutations = {
    setIdentification(state, identification) {
        state.identification = identification;
    },
    setLinkToFace(state, linkToFace) {
        state.linkToFace = linkToFace;
    }
};

const actions = {
    saveIdentification(context, identification) {
        context.commit("setIdentification", identification);
    },
    saveLinkToFace(context, linkToFace) {
        context.commit('setLinkToFace', linkToFace);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
