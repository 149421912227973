<template>
    <div>
        <div class="container-fluid mb-4 bg-white card mk-leadformindex">
            <div class="row">
                <div class="col-12">
                    <div class="customer-card bg-white pt-3">
                        <div>
                            <h2 class="mb-0">Application Form</h2>
                        </div>
                        <div class="my-3">
                            <a :href="supplier.application_url" target="_blank">
                                <i class="fas fa-link mr-2"></i> Link to
                                application
                            </a>
                        </div>
                        <div class="my-3">
                            <a
                                :href="
                                    `${shortenLinkDomain}/s/${supplier.ssid}`
                                "
                                target="_blank"
                            >
                                <i class="fas fa-link mr-2"></i> Supplier
                                Landing Page
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        shortenLinkDomain: {
            required: true,
            type: String
        },
        supplier: {
            type: Object,
            required: true
        }
    }
};
</script>
