<template>
    <div class="mt-3">
        <div class="container-fluid mb-4 pt-3 bg-white card">
            <div class="row">
                <div class="col-12">
                    <div class="row customer-card bg-white">
                        <div
                            class="col-12 d-flex align-items-center justify-content-between"
                        >
                            <h2 class="mb-0">Email Queues</h2>
                        </div>
                        <div class="col-12">
                            <paginated-table
                                v-if="emailQueues.data"
                                :tableData="tableData"
                                :paginatedData="emailQueues"
                                @pagination-change-page="getEmailQueues"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Supplier from "@/backend/models/Supplier";
import Lead from "@/backend/models/Lead";
import EmailQueue from "@/backend/models/EmailQueue";
export default {
    props: {
        supplier: {
            type: Object,
            required: true
        },
        lead: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            emailQueues: {},
            loading: false,
            tableData: {
                columns: [
                    {
                        label: "Email Rule ID",
                        name: "email_rule.id",
                        sortable: false
                    },
                    {
                        label: "Email Template",
                        name: "email_rule.email_template.name",
                        sortable: false
                    },

                    {
                        label: "Status",
                        name: "status",
                        sortable: false,
                        badge: true,
                        text_color: "#fff",
                        badge_coloring: value => {
                            switch (value.status) {
                                case 1:
                                    return "#8ea604";
                                case 2:
                                    return "#E65100";
                                default:
                                    return "#18206f";
                            }
                        },
                        conditional_value: value => {
                            switch (value.status) {
                                case 1:
                                    return "SENT";
                                case 2:
                                    return "PAUSED";
                                default:
                                    return "DISPATCHED";
                            }
                        }
                    },
                    {
                        label: "Run at",
                        name: "run_at",
                        sortable: false
                    },
                    {
                        label: "Created At",
                        name: "created_at",
                        datetime: true,
                        sortable: false
                    }
                ],
                edit: false,
                showSearch: false,
                showPerPage: false
            }
        };
    },
    mounted() {
        this.getEmailQueues();
    },
    methods: {
        async getEmailQueues(
            page = 1,
            sortColumn = null,
            query = null,
            per_page = 100,
            desc = false
        ) {
            let queues = EmailQueue.page(page).params({
                per_page: per_page
            });

            if (sortColumn) {
                queues.orderBy(
                    (desc ? "-" : "") +
                        (sortColumn.sort_name || sortColumn.name)
                );
            } else {
                queues.orderBy("run_at");
            }

            queues.include("emailRule.emailTemplate");

            queues
                .for(new Supplier(this.supplier), new Lead(this.lead))
                .get()
                .then(res => {
                    this.loading = false;
                    this.emailQueues = res;
                })
                .catch(errors => {
                    this.loading = false;
                    console.error(errors);
                });
        }
    }
};
</script>
