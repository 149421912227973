export default {
    date_listed: {
        id: 1,
        name: "Date Listed",
    },
    expected_list_date: {
        id: 2,
        name: "Expected List Date",
    },
};
