<template>
    <el-dialog
        :title="user.id ? 'Edit User' : 'Create User'"
        :visible.sync="open"
        width="50%"
        v-loading="loading"
    >
        <div class="row">
            <div class="col-4 my-1">
                <label>
                    First Name
                </label>
            </div>
            <div class="col-8 my-1">
                <el-input
                    placeholder="First Name"
                    v-model="user.first_name"
                ></el-input>
            </div>
            <div class="col-4 my-1">
                <label>
                    Last Name
                </label>
            </div>
            <div class="col-8 my-1">
                <el-input
                    placeholder="Last Name"
                    v-model="user.last_name"
                ></el-input>
            </div>
            <div class="col-4 my-1">
                <label>
                    Email
                </label>
            </div>
            <div class="col-8 my-1">
                <el-input placeholder="Email" v-model="user.email"></el-input>
            </div>
            <div class="col-4 my-1">
                <label>
                    Ignore Elvare Emails
                </label>
            </div>
            <div class="col-8 my-1">
                <el-checkbox v-model="user.supplier_user_ignore_email">
                </el-checkbox>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="open = false">Cancel</el-button>
            <el-button type="primary" @click="save">Confirm</el-button>
        </span>
    </el-dialog>
</template>

<script>
import Supplier from "@/backend/models/Supplier";
import User from "@/backend/models/User";

export default {
    props: {
        supplier: {
            required: true,
            type: Object
        },
        initial_user: {
            type: Object,
            default: () => {
                return {
                    first_name: null,
                    last_name: null,
                    email: null,
                    supplier_user_ignore_email: false
                };
            }
        },
        isOpen: {
            required: true,
            type: Boolean
        }
    },
    data() {
        return {
            user: this.initial_user || {
                first_name: null,
                last_name: null,
                email: null,
                supplier_user_ignore_email: false
            },
            loading: false,
            open: false
        };
    },
    mounted() {
        this.user.supplier_user_ignore_email = !!this.user
            .supplier_user_ignore_email;
    },
    methods: {
        async save() {
            try {
                this.loading = true;

                const res = await new User(this.user)
                    .for(new Supplier(this.supplier))
                    .save();
                this.loading = false;
                this.$emit("saved", res);
            } catch (err) {
                this.loading = false;
                console.error(err);
                var errorText = "";
                _.forEach(err.response.data.errors, function(value) {
                    errorText += "" + value + " ";
                });
                this.$message({
                    type: "error",
                    message: errorText
                });
            }
        }
    },
    watch: {
        open(to, from) {
            if (!to) {
                this.$emit("close", true);
            }
        },
        isOpen(to, from) {
            console.log(to);
            this.open = to;
        }
    }
};
</script>
