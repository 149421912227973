<template>
    <transition name="slide-fade">
        <div
            ref="email_variables_view"
            class="sidebar_view"
            id="email_variables_view"
            v-if="open"
        >
            <div class="sidebar-body">
                <div class="row mb-3">
                    <div class="col-10">
                        <h3>Available Template Collections</h3>
                    </div>
                    <div
                        class="col-2 d-flex align-items-center justify-content-end"
                    >
                        <a @click.prevent="open = false" href="#">
                            <i class="fas fa-times text-muted"></i>
                        </a>
                    </div>
                </div>

                <div
                    class="row row-table py-3"
                    v-for="(collection, index) in collections"
                    :key="index"
                >
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Apply collection as template body"
                        placement="bottom"
                    >
                        <div
                            class="col-12"
                            style="cursor:pointer;"
                            @click="selectTemplate(collection)"
                        >
                            <p class="text-muted">
                                {{ collection.name }}
                            </p>
                            <div
                                v-html="collection.body"
                                class="collection-view mt-3"
                            ></div>
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import EmailTemplateCollection from "../../../models/EmailTemplateCollection";

export default {
    props: {
        openDrawer: {
            require: true,
            type: Boolean
        }
    },
    async mounted() {
        const res = await new EmailTemplateCollection().get();

        this.collections = res;
    },
    data() {
        return {
            open: this.openDrawer,
            collections: []
        };
    },
    methods: {
        selectTemplate(collection) {
            this.$confirm(
                "Are you sure you want to apply this template?.",
                "Warning",
                {
                    confirmButtonText: "Apply",
                    cancelButtonText: "Cancel",
                    type: "warning"
                }
            )
                .then(res => {
                    this.$emit("apply-template", collection);
                    // this.open = false;
                })
                .catch(err => {});
        }
    },
    watch: {
        openDrawer(to, from) {
            this.open = to;
        },
        open(to, from) {
            this.$emit("update:openDrawer", to);
        }
    }
};
</script>
