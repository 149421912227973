<template>
    <div>
        <div class="container-fluid bg-white card py-5">
            <div class="row">
                <div class="col-12">
                    <h1>Application Summary</h1>
                </div>
                <div class="col-12 mt-3">
                    <p>
                        We’re almost there. Just before your final submission,
                        we want to make sure that you are totally happy with the
                        application and all the details are correct.
                    </p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-6">
                    <label>First Name</label>
                    <p>{{ application.customer.first_name }}</p>
                </div>
                <div class="col-6">
                    <label>Last Name</label>
                    <p>{{ application.customer.last_name }}</p>
                </div>
                <div class="col-12">
                    <label>Authorised Supplier</label>
                    <p>{{ supplier.name }}</p>
                </div>
                <div class="col-12">
                    <label>Property Address</label>
                    <p>
                        {{
                            application.lead.extra_attributes.address
                                .formatted_address
                        }}
                    </p>
                </div>
                <div class="col-6">
                    <label>Agreement Date</label>
                    <p>
                        {{
                            moment(new Date(application.submitted_at)).format(
                                "DD/MM/Y"
                            )
                        }}
                    </p>
                </div>
                <div class="col-12">
                    <label>Estimated Start Date</label>
                    <p>
                        {{
                            moment
                                .utc(application.lead.start_date, "YYYY-MM-DD")
                                .format("DD/MM/Y")
                        }}
                    </p>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-12">
                    <h4>Amount Payable & Due Date</h4>
                </div>
                <div class="col-4">
                    <label>
                        Requested Lead Amount
                    </label>
                    <el-input :value="amountString" disabled>
                        <p
                            slot="prefix"
                            class="mb-0"
                            style="padding-top: 10px; padding-left: 10px;"
                        >
                            $
                        </p>
                    </el-input>
                </div>
                <div class="col-4">
                    <label>
                        Fee
                    </label>
                    <el-input value="5%" disabled> </el-input>
                </div>
                <div class="col-4">
                    <label>
                        Interest
                    </label>
                    <el-input value="0.9%" disabled> </el-input>
                </div>
                <div class="col-4 mt-3">
                    <label>
                        Total Amount Payable
                    </label>
                    <el-input :value="totalPayable" disabled>
                        <p
                            slot="prefix"
                            class="mb-0"
                            style="padding-top: 10px; padding-left: 10px;"
                        >
                            $
                        </p>
                    </el-input>
                </div>
                <div class="col-4 mt-3">
                    <label>
                        107-Day Due Date
                    </label>
                    <el-input :value="dueDate" disabled> </el-input>
                </div>

                <div class="col-12 mt-5">
                    <div class="row">
                        <div class="col-12">
                            <h4>
                                Signature Here
                            </h4>
                        </div>
                        <div class="col-12">
                            <div
                                class="card mt-2"
                                style="border-bottom:1px solid #efefef;"
                                v-for="media in application.media.filter(
                                    media =>
                                        media.collection_name ==
                                        'customer_signature'
                                )"
                                :key="media.id"
                            >
                                <div
                                    class="d-flex justify-content-between align-items-center"
                                >
                                    <div>
                                        <p>{{ media.file_name }}</p>
                                        <p>
                                            <i class="fas fa-tag"></i>
                                            {{
                                                _.startCase(
                                                    _.replace(
                                                        media.collection_name,
                                                        "_",
                                                        " "
                                                    )
                                                )
                                            }}
                                        </p>
                                    </div>
                                    <div>
                                        <a :href="media.url" customer_signature>
                                            <i class="fas fa-eye text-blue">
                                            </i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        initial_supplier: {
            type: Object,
            required: true
        },
        initial_application: {
            type: Object,
            required: true
        }
    },
    components: {},
    data() {
        return {
            tc: false,
            application: this.initial_application,
            supplier: this.initial_supplier
        };
    },
    computed: {
        dueDate() {
            let startDay = new Date(this.application.lead.start_date);
            return moment(
                new Date(startDay.setDate(startDay.getDate() + 107))
            ).format("DD/MM/Y");
        },
        totalPayable() {
            return Number(
                this.application.lead.dollar_requested_amount * (5.9 / 100 + 1)
            ).toLocaleString("en-AU", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        },
        amountString() {
            return Number(
                this.application.lead.dollar_requested_amount
            ).toLocaleString("en-AU", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        }
    },
    methods: {}
};
</script>
