const state = {
    customer: {},
};

const getters = {
    getCustomer: state => state.customer
};

const mutations = {
    setCustomer(state, customer) {
        state.customer = customer;
    }
};

const actions = {
    saveCustomer(context, customer) {
        context.commit("setCustomer", customer);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
