var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "mobile-key-benefits-belt" }),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid bg-blue" }, [
      _c("div", { staticClass: "row py-4" }, [
        _c("div", { staticClass: "col-12 d-none d-md-block" }, [
          _c(
            "div",
            { staticClass: "d-flex justify-content-center align-items-center" },
            [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "mx-2 btn-pink",
                  attrs: { href: _vm.createLink }
                },
                [_vm._v("Apply now")]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 d-md-none" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-center align-items-center flex-column"
            },
            [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "mx-2 btn-pink w-100 text-center",
                  attrs: { href: _vm.createLink }
                },
                [_vm._v("Apply now")]
              )
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(4),
    _vm._v(" "),
    _vm._m(5)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "row position-relative mk-applicationlanding" },
      [
        _c(
          "div",
          {
            staticClass: "w-100 position-absolute",
            staticStyle: { top: "0", "z-index": "1" }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "col-12 d-flex justify-content-between align-items-center"
              },
              [
                _c("div", { staticClass: "d-flex align-items-center p-3" }, [
                  _c("img", {
                    staticClass: "ml-4 img-fluid",
                    staticStyle: { "max-width": "100px" },
                    attrs: { src: "/img/backend/brand/elepay-no-tag.png" }
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-0 ml-3 font-12 text-blue d-md-block d-none"
                    },
                    [
                      _vm._v(
                        "The Pay Later specialist for the property Industry"
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "ml-4 img-fluid p-3",
                  staticStyle: { "max-width": "160px" },
                  attrs: { src: "/img/backend/brand/elevare.png" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col-md-6 col-12",
            staticStyle: {
              "padding-top": "100px",
              "background-color": "#efefef"
            }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "container d-flex align-items-center justify-content-center",
                staticStyle: { "min-height": "500px" }
              },
              [
                _c("div", { staticClass: "row w-100" }, [
                  _c("div", { staticClass: "col-12 mb-4" }, [
                    _c(
                      "h2",
                      {
                        staticClass: "text-blue font-heading",
                        staticStyle: {
                          "margin-bottom": "27px",
                          "font-size": "30px"
                        }
                      },
                      [
                        _vm._v(
                          "The safe and secure way to defer upfront property expenses for 107 days."
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "title-decoration-bar bg-pink",
                      staticStyle: { "margin-bottom": "38px" }
                    }),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "text-blue mb-1",
                        staticStyle: {
                          "margin-bottom": "40px",
                          "font-size": "16px",
                          "min-height": "26px"
                        }
                      },
                      [
                        _vm._v(
                          "Elepay is Australia's favourite pay later provider with bespoke payment plans for Australian property owners. Join thousands of other happy Australians by deferring expenses for up to 107 days from start date. It's simple, hassle free and best of all it allows you to avoid any upfront property expenses."
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "no-hover",
                        staticStyle: { "border-bottom": "2px solid #ff968d" },
                        attrs: {
                          href: "#learn-more-modal",
                          "data-toggle": "modal",
                          "data-backdrop": "true"
                        }
                      },
                      [
                        _c("strong", { staticClass: "text-blue" }, [
                          _vm._v("Click here to learn more about elepay.")
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "container-fluid mk-applicationlanding col-12 col-md-6",
          staticStyle: {
            "background-image":
              "url('/img/backgrounds/application-landing-1.png')",
            "background-size": "cover",
            "background-position": "top center"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "container-fluid py-5 key-benefits-container",
        staticStyle: {
          "background-size": "cover",
          "background-position": "top left"
        }
      },
      [
        _c("div", { staticClass: "container py-5" }, [
          _c("div", { staticClass: "row px-3 pl-3 pl-lg-5" }, [
            _c("div", { staticClass: "ml-auto col-12 col-lg-6" }, [
              _c(
                "h2",
                {
                  staticClass: "fw-700 font-heading",
                  staticStyle: { "margin-bottom": "7px", "font-size": "32px" }
                },
                [_vm._v("Key benefits")]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "title-decoration-bar bg-pink",
                staticStyle: { "margin-bottom": "29px" }
              }),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _c("p", { staticClass: "mb-1 font-17" }, [
                    _c("strong", { staticClass: "text-blue font-heading" }, [
                      _vm._v("No upfront payment")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("p", { staticClass: "mb-1 font-17" }, [
                    _c("strong", { staticClass: "text-blue font-heading" }, [
                      _vm._v("107 days to repay from start date")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("p", { staticClass: "mb-1 font-17" }, [
                    _c("strong", { staticClass: "text-blue font-heading" }, [
                      _vm._v("Funding from $500 - $25,000")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("p", { staticClass: "mb-1 font-17" }, [
                    _c("strong", { staticClass: "text-blue font-heading" }, [
                      _vm._v("3 minute online application process")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("p", { staticClass: "mb-1 font-17" }, [
                    _c("strong", { staticClass: "text-blue font-heading" }, [
                      _vm._v("2 hour approval process")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("p", { staticClass: "mb-1 font-17" }, [
                    _c("strong", { staticClass: "text-blue font-heading" }, [
                      _vm._v("Manage your account online")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("p", { staticClass: "mb-1 font-17" }, [
                    _c("strong", { staticClass: "text-blue font-heading" }, [
                      _vm._v("Fees starting from as low as 2%")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("p", { staticClass: "mb-1 font-17" }, [
                    _c("strong", { staticClass: "text-blue font-heading" }, [
                      _vm._v(
                        "Approved funds are disbursed directly to your supplier"
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mx-2 mb-0" }, [
      _c("strong", { staticClass: "text-white font-heading" }, [
        _vm._v("Application takes less than 3 minutes.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mx-2 mb-3" }, [
      _c("strong", { staticClass: "text-white font-heading" }, [
        _vm._v("Application takes less than 3 minutes.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container-fluid bg-slate py-5" }, [
      _c("div", { staticClass: "container py-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-5 d-flex flex-column align-items-center align-items-lg-start mb-4 mb-lg-0"
            },
            [
              _c("img", {
                staticClass:
                  "mb-3 img-fluid align-self-center align-self-lg-start",
                attrs: { src: "/img/logos/elevare-logo.svg" }
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "title-decoration-bar bg-pink",
                staticStyle: { "margin-top": "20px" }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-2 d-flex flex-column align-items-center align-items-lg-start mb-4 mb-lg-0"
            },
            [
              _c("img", {
                staticClass:
                  "mb-3 img-fluid align-self-center align-self-lg-start",
                attrs: { src: "/img/elements/footer-address.png" }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "text-white mb-1" }, [
                _vm._v("273 Abbotsford Road")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-white" }, [
                _vm._v("Bowen Hills QLD 4006")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-2 d-flex flex-column mb-4 mb-lg-0" },
            [
              _c("img", {
                staticClass:
                  "mb-3 img-fluid align-self-center align-self-lg-start",
                attrs: { src: "/img/elements/footer-phone.png" }
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "text-white text-center text-lg-left",
                  attrs: { href: "tel:1300019417" }
                },
                [_vm._v("1300 019 417")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-3 d-flex flex-column mb-4 mb-lg-0" },
            [
              _c("img", {
                staticClass:
                  "mb-3 img-fluid align-self-center align-self-lg-start",
                attrs: { src: "/img/elements/footer-email.png" }
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "text-white text-center text-lg-left",
                  attrs: { href: "mailto:hello@elevarepayeasy.com.au" }
                },
                [_vm._v("hello@elevarepayeasy.com.au")]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-1 mt-md-4" }, [
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-5 d-flex flex-column align-items-center align-items-lg-start"
            },
            [
              _c("p", { staticClass: "mb-3 mb-lg-0" }, [
                _c("strong", { staticClass: "text-white" }, [
                  _vm._v(
                    "We're the Pay Later specialists for the property Industry."
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-2 d-flex flex-column align-items-center align-items-lg-start"
            },
            [
              _c("p", { staticClass: "text-m-grey mb-3 mb-lg-0" }, [
                _vm._v("ABN: 47 634 728 591")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-2 d-flex flex-column align-items-center align-items-lg-start"
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "text-m-grey text-center text-lg-left mb-3 mb-lg-0",
                  attrs: { href: "#0" }
                },
                [_vm._v("Terms & Conditions")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-3 d-flex flex-column align-items-center align-items-lg-start"
            },
            [
              _c(
                "a",
                {
                  staticClass: "text-m-grey text-center text-lg-left",
                  attrs: { href: "#0" }
                },
                [_vm._v("Privacy and Refund Policies")]
              )
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "modal", attrs: { id: "learn-more-modal" } },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "div",
              {
                staticClass: "modal-header px-5 pt-5",
                staticStyle: { "border-bottom": "none" }
              },
              [
                _c("img", {
                  staticClass: "img-fluid",
                  staticStyle: { "max-width": "100px" },
                  attrs: { src: "/img/backend/brand/elepay-no-tag.png" }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-label": "Close"
                    }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _c("i", { staticClass: "far fa-times-circle" })
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body px-5" }, [
              _c("p", [
                _c("strong", [
                  _vm._v(
                    "The Elepay business is conducted by Elevare Pay Easy Pty Ltd, an Australian privately-owned company which is part of the Elevare Pay Easy Group. "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Elevare Pay Easy Pty Ltd provides a deferred payment solution and Buy Now Pay Later solution for participants in the Australian property industry. The executive team of the Elevare Pay Easy Group have been involved within the deferred payment solution and Buy Now Pay Later industry since the category was established in 2014. Elepay’s suite of products have been designed and approved by professional experts to ensure that they comply with all relevant legal requirements and standards."
                )
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "img-fluid mb-4",
                staticStyle: { "max-width": "160px" },
                attrs: { src: "/img/backend/brand/elevare.png" }
              })
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }