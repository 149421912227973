/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import "core-js/stable";
import "regenerator-runtime/runtime";
import '../bootstrap';
import '../plugins';
import Vue from 'vue';
import {
    Model
} from "vue-api-query";
import axios from "axios";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
import VueTelInput from 'vue-tel-input';
import VueSignaturePad from 'vue-signature-pad';
import VueCurrencyInput from 'vue-currency-input';
import smoothscroll from 'smoothscroll-polyfill';
import LogRocket from 'logrocket';
import {
    ValidationProvider,
    ValidationObserver,
    extend
} from 'vee-validate';
import {
    required,
    email,
    numeric,
    min_value
} from 'vee-validate/dist/rules';

import * as Sentry from "@sentry/browser";
import {
    Vue as VueIntegration
} from "@sentry/integrations";


import Vuex from "vuex";
import store from "./stores";
Vue.prototype.$store = store;

if (process.env.NODE_ENV == 'production') {
    LogRocket.init(process.env.MIX_LOGROCKET_ID);
}
// inject global axios instance as http client to Model
Model.$http = axios;

window.Vue = Vue;

const pluginOptions = {
    /* see config reference */
    globalOptions: {
        currency: 'USD'
    }
};


Vue.prototype._ = _;
Vue.prototype.moment = moment;
// kick off the polyfill!
smoothscroll.polyfill();
Vue.use(VueTelInput);
Vue.use(VueSignaturePad);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);


// vue validation
extend('email', {
    ...email,
    message: '{_field_} should be an email'
});

extend('numeric', {
    ...numeric,
    message: '{_field_} should be a number'
});

extend('required', {
    ...required,
    message: 'Please enter an {_field_}'
});

extend('min_value', {
    ...min_value,
    message: '{_field_} should be larger than $0'
});

extend('date', {
    validate(value) {
        return {
            valid: () => {
                if (value) {
                    const regexp = new RegExp("/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/");
                    regexp.test(value);
                }
            }
        };
    },
    computesRequired: true,
    message: '{_field_} should be a date'
});


extend('option_required', {
    ...required,
    message: 'Please select at least one option'
});

Sentry.init({
    dsn: process.env.MIX_SENTRY_DSN,
    integrations: [new VueIntegration({
        Vue,
        attachProps: true
    })],
});


Vue.use(VueCurrencyInput, {
    globalOptions: {
        currency: 'AUD',
        precision: 2,
        locale: 'en-AU'
    }
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

const files = require.context("./", true, /\.vue$/i);
files.keys().map(key =>
    Vue.component(
        key
        .split("/")
        .pop()
        .split(".")[0],
        files(key).default
    )
);
Vue.use(ElementUI, {
    locale
});

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component(
    "paginated-table",
    require("../components/PaginatedTable.vue").default
);
Vue.component("pagination", require("laravel-vue-pagination"));


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
