var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-center mx-auto"
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn btn-primary mutable-mobile-width",
                  on: {
                    click: function($event) {
                      return _vm.$emit("next", true)
                    }
                  }
                },
                [_vm._v("Start")]
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "container-fluid bg-white card application-card mk-introduction"
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 position-relative" }, [
            _c("div", { staticClass: "app-card-decoration" }),
            _vm._v(" "),
            _c("h1", { staticClass: "mb-4" }, [_vm._v("Welcome")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12" }, [
            _c("p", { staticClass: "app-card-intro" }, [
              _vm._v(
                "The Pay Later online process will take you less than 3 minutes"
              )
            ]),
            _vm._v(" "),
            _c("p", {}, [
              _vm._v("Please make sure you have your Photo ID handy")
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }