<template>
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-start align-items-center">
                    Send
                    <el-input
                        v-model="hours"
                        type="number"
                        class="mx-2"
                        style="width:8%"
                    ></el-input>
                    Hours after submission
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-start align-items-center mt-3">
            <p class="mr-3">Send To:</p>

            <el-checkbox
                label="Elevare Admins"
                v-model="sendToAdmin"
            ></el-checkbox>
            <el-checkbox label="Agency" v-model="sendToSupplier"></el-checkbox>
            <el-checkbox
                label="Customer"
                v-model="sendToCustomer"
            ></el-checkbox>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        initial_email_rule: {
            type: Object
        }
    },
    data() {
        return {
            sendToAdmin: !!this.initial_email_rule.send_to_admin || false,
            sendToCustomer: !!this.initial_email_rule.send_to_customer || false,
            sendToSupplier: !!this.initial_email_rule.send_to_supplier || false,
            hours: this.initial_email_rule.hours || 0
        };
    },
    mounted() {},
    watch: {
        hours(to, from) {
            this.$emit("set-hours", to);
        },
        sendToSupplier(to, from) {
            this.$emit("set-send-supplier", to);
        },
        sendToCustomer(to, from) {
            this.$emit("set-send-customer", to);
        },
        sendToAdmin(to, from) {
            this.$emit("set-send-admin", to);
        }
    }
};
</script>
