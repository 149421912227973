var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid px-0 mb-5" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row px-3" }, [
      _c("div", { staticClass: "col-12 py-3 bg-white px-5" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-3 my-3" }, [
            _vm._v("\n                    First Name\n                ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-9 my-3" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.application.customer.first_name) +
                "\n                "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3 my-3" }, [
            _vm._v("\n                    Last Name\n                ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-9 my-3" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.application.customer.last_name) +
                "\n                "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3 my-3" }, [
            _vm._v(
              "\n                    Authorised Supplier\n                "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-9 my-3" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.application.supplier.name) +
                "\n                "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3 my-3" }, [
            _vm._v(
              "\n                    Requested Plan Amount\n                "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-9 my-3" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.application.lead.dollar_requested_amount) +
                "\n                "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "row my-5 py-3 w-50",
            staticStyle: {
              "border-top": "1px",
              "border-top-style": "solid",
              "border-top-color": "#FF4E00",
              "border-bottom": "1px",
              "border-bottom-style": "solid",
              "border-bottom-color": "#FF4E00"
            }
          },
          [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group col-md-9 col-9 my-3" },
              [
                _c("el-input", {
                  attrs: { placeholder: "Revised Plan Amount", disabled: "" },
                  model: {
                    value: _vm.planVariation.dollar_revised_plan_amount,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.planVariation,
                        "dollar_revised_plan_amount",
                        $$v
                      )
                    },
                    expression: "planVariation.dollar_revised_plan_amount"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group col-md-9 col-9 my-3" },
              [
                _c("el-date-picker", {
                  staticClass: "w-100",
                  attrs: {
                    placeholder: "Revised Plan Date",
                    type: "date",
                    format: "dd/MM/yyyy",
                    "value-format": "yyyy-MM-dd",
                    disabled: ""
                  },
                  model: {
                    value: _vm.planVariation.revised_start_date,
                    callback: function($$v) {
                      _vm.$set(_vm.planVariation, "revised_start_date", $$v)
                    },
                    expression: "planVariation.revised_start_date"
                  }
                })
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row w-100 py-3" }, [
          _vm._m(3),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-md-4" },
            [
              _c("pay-before-card", {
                key: _vm.starterKey,
                attrs: {
                  paydayCardBg: "rgb(249, 249, 249)",
                  payBeforeDays: 45,
                  payBeforeFee: "2.0%",
                  payBeforeHighlightFee: true,
                  payBeforeInterest: "0.0%",
                  payBeforeAmountPayable: _vm.getTotalPayable(2, 0),
                  "due-date": _vm.getDueDate(44)
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-md-4" },
            [
              _c("pay-before-card", {
                key: _vm.starterKey,
                attrs: {
                  paydayCardBg: "rgba(255, 78, 0, 0.03)",
                  payBeforeDays: 60,
                  payBeforeFee: "5.0%",
                  payBeforeHighlightFee: false,
                  payBeforeInterest: "0.0%",
                  payBeforeAmountPayable: _vm.getTotalPayable(5, 0),
                  "due-date": _vm.getDueDate(59)
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-md-4 schedule-mobile-card" },
            [
              _c("pay-before-card", {
                key: _vm.starterKey,
                attrs: {
                  paydayCardBg: "rgba(255, 78, 0, 0.12)",
                  payBeforeDays: 107,
                  payBeforeFee: "5.0%",
                  payBeforeHighlightFee: false,
                  payBeforeInterest: "0.9%",
                  payBeforeAmountPayable: _vm.getTotalPayable(5, 0.9),
                  payBeforeIsFinalCard: true,
                  "due-date": _vm.getDueDate(107)
                }
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row my-3" }, [
      _c("div", { staticClass: "col-12 d-flex justify-content-between" }, [
        _c("h1", [_vm._v("\n                Plan variation\n            ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group col-md-3 col-3 my-3" }, [
      _c("p", { staticClass: "mb-0" }, [
        _vm._v(
          "\n                        Revised Plan Amount\n                    "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group col-md-3 col-3 my-3" }, [
      _c("p", { staticClass: "mb-0" }, [
        _vm._v(
          "\n                        Revised Plan Date\n                    "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("p", [
        _vm._v(
          "\n                        Property Owners Payment Schedule\n                    "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }