<template>
    <div>
        <div class="container-fluid bg-white card py-5">
            <div class="row">
                <div class="col-12">
                    <h1>Your Property</h1>
                </div>
                <div class="col-12 mt-3">
                    <p>
                        This is the property where the funds provided will be
                        invested.
                    </p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <label for="property_address">Property Address</label>
                    <el-input
                        disabled
                        v-model="
                            application.lead.extra_attributes.address
                                .formatted_address
                        "
                    >
                    </el-input>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-6">
                    <h4>Listing Details</h4>
                    <p>Please provide us with details for when your property is being listed for sale on the market</p>
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <el-checkbox
                        disabled
                        :value="!!application.is_not_listed_as_sale"
                        @change="
                            value => (application.is_not_listed_as_sale = value)
                        "
                    >
                        Property is not being listed for sale
                    </el-checkbox>
                </div>
            </div>
            <div class="row mt-5">
                
                <div class="col-lg-6 d-flex flex-column">
                    <label for="listing_date_type">Date Type</label>
                    <el-select v-model="application.listing_date_type" disabled>
                        <el-option v-for="type in listingDateTypes" :key="type.id" :value="type.id" :label="type.name">
                        </el-option>
                    </el-select>
                </div>

                <div class="col-6 d-flex flex-column">
                    <label for="date_listed">{{currentDateName}}</label>
                    <el-date-picker
                        disabled
                        type="date"
                        v-model="application.date_listed"
                        class="w-100"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                </div>

                <div class="col-12 mt-3">
                    <label for="sales_representative">Listing Agency</label>
                    <el-input v-model="application.listing_agency" disabled>
                    </el-input>
                </div>
                <div class="col-6 mt-3">
                    <label for="expected_low">Expected Low Range Price</label>
                    <div class="el-input is-disabled">
                        <currency-input
                            class="el-input__inner"
                            v-model="application.dollar_expected_low"
                            disabled
                        />
                    </div>
                </div>
                <div class="col-6 mt-3">
                    <label for="expected_low">Expected High Range Price</label>
                    <div class="el-input is-disabled">
                        <currency-input
                            class="el-input__inner"
                            v-model="application.dollar_expected_high"
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <h4>Ownership Declaration</h4>
                    <p class="mt-3">Please select the ownership statement that applies to you.</p>
                </div>
                <div class="col-12">
                    <el-radio
                        v-model="application.self_ownership"
                        :label="1"
                        disabled
                    >
                        I am the registered owner of the property {field} and my
                        name appears on the current title deed which is held
                        with the Australian Titles Office.
                    </el-radio>
                    <el-radio
                        v-model="application.self_ownership"
                        :label="2"
                        disabled
                        >No I AM NOT the registered owner of the property,
                        however my relationship with the registered owner is as
                        follows:

                        <el-select
                            v-model="application.ownership_id"
                            v-if="ownerships.length"
                            disabled
                        >
                            <el-option
                                v-for="ownership in ownerships"
                                :value="ownership.id"
                                :label="ownership.name"
                                :key="ownership.id"
                            >
                            </el-option>
                        </el-select>
                    </el-radio>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Ownerships from "../../../../frontend/constants/Ownerships";
import ListingDateTypes from "../../../../frontend/constants/ListingDateTypes";
export default {
    props: {
        initial_supplier: {
            type: Object,
            required: true
        },
        initial_application: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            application: this.initial_application,
            supplier: this.initial_supplier
        };
    },

    computed: {
        ownerships() {
            return Object.keys(Ownerships).map(key => {
                return Ownerships[key];
            });
        },
        listingDateTypes() {
            return Object.keys(ListingDateTypes).map(key => {
                return ListingDateTypes[key];
            });
        },
        currentDateName() {
            const type = this.listingDateTypes.find(type => type.id == this.application.listing_date_type);
            if (type) return type.name;
            return this.listingDateTypes[0].name;
        }
    }
};
</script>
