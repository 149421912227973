<template>
    <div class="container-fluid bg-white card py-3 my-3">
        <h2 class="mb-3">Supplier Contact</h2>
        <div class="row">
            <div class="form-group col-12 col-md-6 col-lg-4">
                <label name="first_name">First Name</label>
                <el-input
                    placeholder="First Name"
                    v-model="user.first_name"
                ></el-input>
            </div>
            <div class="form-group col-12 col-md-6 col-lg-4">
                <label name="last_name">Last Name</label>
                <el-input
                    placeholder="Last Name"
                    v-model="user.last_name"
                ></el-input>
            </div>
            <div class="form-group col-12 col-md-6 col-lg-4">
                <label name="email">Email</label>
                <el-input
                    placeholder="Email"
                    type="email"
                    v-model="user.email"
                ></el-input>
            </div>
            <!-- <div class="form-group col-12 col-md-6 col-lg-4" v-if="!supplier.id">
                <label name="password">Password</label>
                <el-input
                    placeholder="Password"
                    type="password"
                    v-model="user.password"
                    show-password
                ></el-input>
            </div> -->
            <!-- <div
                class="form-group col-12 col-md-6 col-lg-4"
                v-if="!supplier.id"
            >
                <label name="password_confirmation">Confirm Password</label>

                <el-input
                    placeholder="Confirm Password"
                    type="password"
                    v-model="user.password_confirmation"
                    show-password
                ></el-input>
            </div> -->
            <!-- <div class="form-group col-12 col-md-6 col-lg-4">
                <label for="status">Roles</label>

                <el-select v-model="user.roles" class="w-100" multiple>
                    <el-option
                        v-for="role in roles"
                        :key="role.id"
                        :value="role.id"
                        :label="role.name"
                    ></el-option>
                </el-select>
            </div> -->
        </div>
        <!-- <div class="row">
            <div
                class="col-12 d-flex align-items-center justify-content-end"
                v-if="supplier.id"
            >
                <button class="btn btn-danger mr-2" @click="deleteUser">
                    Delete
                </button>
                <button class="btn btn-primary" @click="save">Save</button>
            </div>
        </div> -->
    </div>
</template>
<script>
import Supplier from "../../models/Supplier";
import User from "../../models/User";
export default {
    props: {
        supplier: {
            type: Object
        },
        initial_user: {
            type: Object
        },
        roles: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            user: this.initial_user
        };
    },
    mounted() {
        this.user.roles = this.user.roles.map(role => role.id);
        document.addEventListener("save_supplier", this.listener);
    },
    beforeDestroy() {
        document.removeEventListener("save_supplier", this.listener);
    },
    methods: {
        listener(event) {
            this.save();
        },
        async deleteUser() {
            try {
                const res = await new User(this.user)
                    .for(new Supplier(this.supplier))
                    .delete();
            } catch (err) {
                console.error(err);
            }
        },
        async save() {
            try {
                const res = await new User(this.user)
                    .for(new Supplier(this.supplier))
                    .save();
                this.$message({
                    type: "success",
                    message: "Saved"
                });
            } catch (err) {
                console.error(err);
                var errorText = "";
                _.forEach(err.response.data.errors, function(value) {
                    errorText += "" + value + " ";
                });
                this.$message({
                    type: "error",
                    message: errorText
                });
            }
        }
    },
    watch: {
        user: {
            handler(to, from) {
                this.$emit("update-user", this.user);
            },
            deep: true
        }
    }
};
</script>
