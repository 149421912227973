<template>
    <div class="container-fluid px-0 mb-5">
        <div class="row mb-3">
            <div class="col-12 d-flex justify-content-between">
                <h1>
                    {{ emailTemplate.id ? "Edit" : "Create" }} email template
                </h1>
                <div class="d-flex align-items-center justify-content-end">
                    <el-popconfirm
                        confirm-button-text="Delete"
                        cancel-button-text="Don't Delete"
                        icon="far fa-times-circle"
                        icon-color="#F56C6C"
                        title="Are you sure to delete this email template?"
                        @onConfirm="deleteTemplate"
                        v-if="emailTemplate.id"
                    >
                        <button
                            class="btn btn-danger mr-2"
                            v-if="emailTemplate.id"
                            slot="reference"
                        >
                            Delete
                        </button>
                    </el-popconfirm>

                    <button
                        class="btn btn-primary mr-2"
                        @click="openCollection = true"
                    >
                        Template Collection
                        <i class="fas fa-envelope text-white"></i>
                    </button>
                    <button class="btn btn-primary mr-2" @click="open = true">
                        Email Variable <i class="fas fa-cog text-white"></i>
                    </button>
                    <button class="btn btn-primary" @click="save">Save</button>
                </div>
            </div>
        </div>

        <div class="row px-3">
            <div class="col-12 py-3 bg-white">
                <div class="row">
                    <div class="form-group col-md-6 col-12">
                        <label for="template_name">Template Name</label>
                        <el-input
                            placeholder="Template Name"
                            v-model="emailTemplate.name"
                        ></el-input>
                    </div>

                    <div class="form-group col-md-6 col-12">
                        <label for="subject">Subject</label>
                        <el-input
                            placeholder="Subject"
                            v-model="emailTemplate.subject"
                        ></el-input>
                    </div>

                    <email-rule
                        :initial_email_rule="emailTemplate.email_rule"
                        @set-hours="
                            value => (emailTemplate.email_rule.hours = value)
                        "
                        @set-send-admin="
                            value =>
                                (emailTemplate.email_rule.send_to_admin = value)
                        "
                        @set-send-supplier="
                            value =>
                                (emailTemplate.email_rule.send_to_supplier = value)
                        "
                        @set-send-customer="
                            value =>
                                (emailTemplate.email_rule.send_to_customer = value)
                        "
                    ></email-rule>

                    <div class="form-group col-md-6 col-12">
                        <label for="target_flow">Target</label>
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <el-select
                                v-model="emailTemplate.target_flow"
                                class="w-100"
                            >
                                <el-option
                                    v-for="target in targets"
                                    :key="target.id"
                                    :value="target.id"
                                    :label="target.name"
                                >
                                </el-option>
                            </el-select>
                            <el-checkbox
                                class="ml-3"
                                label="Send SMS Reminder"
                                :disabled="emailTemplate.target_flow != 1"
                                v-model="smsReminder"
                            ></el-checkbox>
                        </div>
                    </div>

                    <div class="col-12">
                        <editor
                            v-model="emailTemplate.body"
                            :api-key="tinyApiKey"
                            :init="{
                                height: 600,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor fullpage table',
                                    'searchreplace visualblocks code fullscreen',
                                    'print preview anchor insertdatetime media',
                                    'paste code help wordcount table fullpage'
                                ],
                                relative_urls: false,
                                remove_script_host: false,
                                convert_urls: false,
                                cleanup: false,
                                cleanup_on_startup: false,
                                apply_source_formatting: true,
                                paste_auto_cleanup_on_paste: false,
                                allow_conditional_comments: true,
                                convert_fonts_to_spans: false,
                                toolbar:
                                    'undo redo | formatselect | bold italic forecolor backcolor | code fullpage addVariableButton | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help',
                                setup: editor => setEditor(editor)
                            }"
                        >
                        </editor>
                    </div>
                </div>
            </div>
        </div>
        <email-template-collection
            :open-drawer.sync="openCollection"
            @apply-template="collection => applyCollection(collection)"
            v-if="supplier.id"
        >
        </email-template-collection>
        <email-variable
            :open-drawer.sync="open"
            :supplier="supplier"
            @updated-variables="getVariables"
            v-if="supplier.id"
        ></email-variable>
    </div>
</template>
<script>
import Editor from "@tinymce/tinymce-vue";
import Supplier from "../../models/Supplier";
import EmailTemplate from "../../models/EmailTemplate";
import EmailVariable from "../../models/EmailVariable";
import EmailTemplateTarget from "../../constants/EmailTemplateTarget";
export default {
    components: {
        Editor
    },
    props: {
        supplier: {
            type: Object,
            required: true
        },
        initial_template: {
            type: Object,
            default: () => {
                return {
                    name: null,
                    subject: null,
                    body: "",
                    target_flow: 1,
                    email_rule: {
                        hours: 0,
                        send_to_admin: false,
                        send_to_customer: false,
                        send_to_supplier: false,
                        sms_reminder: false
                    }
                };
            }
        }
    },
    data() {
        return {
            emailTemplate: this.initial_template,
            tinyApiKey: process.env.MIX_TINYMCE_KEY,
            open: false,
            openCollection: false,
            editor: null,
            variables: [],
            smsReminder: !!this.initial_template.email_rule.sms_reminder,
            initialSmsReminderValue: !!this.initial_template.email_rule
                .sms_reminder
        };
    },
    mounted() {
        this.getVariables();
    },
    methods: {
        applyCollection(collection) {
            this.editor.insertContent(collection.body);
            this.openCollection = false;
        },
        setEditor(editor) {
            this.editor = editor;
            this.renderVariablesMenu();
        },
        renderVariablesMenu() {
            if (this.editor) {
                this.editor.ui.registry.addMenuButton("addVariableButton", {
                    text: "Add Email Variable",
                    fetch: callback => {
                        let items = this.variables.map(variable => {
                            return {
                                type: "menuitem",
                                text: variable.name,
                                onAction: () => {
                                    this.editor.insertContent(
                                        `&nbsp;{{${variable.key}}}`
                                    );
                                }
                            };
                        });
                        callback(items);
                    }
                });
            }
        },
        async getVariables() {
            try {
                const res = await new EmailVariable()
                    .for(new Supplier(this.supplier))
                    .get();

                this.variables = res;
                this.renderVariablesMenu();
            } catch (err) {
                console.error(err);
            }
        },
        async deleteTemplate() {
            try {
                await new EmailTemplate(this.emailTemplate)
                    .for(new Supplier(this.supplier))
                    .delete();
                window.location.replace(
                    `/admin/suppliers/${this.supplier.id}/edit`
                );
                window.history.pushState(
                    null,
                    null,
                    `/admin/suppliers/${this.supplier.id}/edit`
                );
            } catch (err) {
                console.error(err);
            }
        },
        async save() {
            try {
                this.emailTemplate.email_rule.sms_reminder = this.smsReminder;

                const res = await new EmailTemplate(this.emailTemplate)
                    .for(new Supplier(this.supplier))
                    .save();

                if (!this.initial_template.id) {
                    window.location.replace(
                        `/admin/suppliers/${this.supplier.id}/email_templates/${res.id}/edit`
                    );
                    window.history.pushState(
                        null,
                        null,
                        `/admin/suppliers/${this.supplier.id}/email_templates/${res.id}/edit`
                    );
                }
            } catch (err) {
                console.error(err);
                var errorText = "";
                _.forEach(err.response.data.errors, function(value) {
                    errorText += "" + value + " ";
                });
                this.$message({
                    type: "error",
                    message: errorText
                });
            }
        }
    },
    computed: {
        targets() {
            return Object.keys(EmailTemplateTarget).map(key => {
                return EmailTemplateTarget[key];
            });
        }
    },
    watch: {
        "emailTemplate.target_flow"(to, from) {
            if (to === 1) {
                this.smsReminder = this.initialSmsReminderValue;
            } else {
                this.smsReminder = false;
            }
        },
        smsReminder(to, from) {
            if (this.emailTemplate.target_flow === 1)
                this.initialSmsReminderValue = to;
        }
    }
};
</script>
