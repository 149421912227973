import "@coreui/coreui";
import Vue from "vue";
import {
    Model
} from "vue-api-query";
import axios from "axios";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
import VueTelInput from 'vue-tel-input';
import VueClipboard from 'vue-clipboard2';
import VueCurrencyInput from 'vue-currency-input';
import smoothscroll from 'smoothscroll-polyfill';
import LogRocket from 'logrocket';


// inject global axios instance as http client to Model
Model.$http = axios;
window.Vue = Vue;

Vue.prototype._ = _;
Vue.prototype.moment = moment;

// kick off the polyfill!
smoothscroll.polyfill();

Vue.use(VueTelInput);
Vue.use(VueClipboard);
Vue.use(VueCurrencyInput, {
    globalOptions: {
        currency: 'AUD',
        precision: 2,
        locale: 'en-AU'
    }
});

if (process.env.NODE_ENV == 'production') {
    LogRocket.init(process.env.MIX_LOGROCKET_ID);
}

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context("./", true, /\.vue$/i);
files.keys().map(key =>
    Vue.component(
        key
        .split("/")
        .pop()
        .split(".")[0],
        files(key).default
    )
);
Vue.use(ElementUI, {
    locale
});
Vue.component(
    "paginated-table",
    require("../components/PaginatedTable.vue").default
);
Vue.component("pagination", require("laravel-vue-pagination"));

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: "#app"
});
