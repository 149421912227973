<template>
    <div>
        <paginated-table
            v-if="users.data"
            :tableData="tableData"
            :paginatedData="users"
            @pagination-change-page="getUsers"
        >
            <template v-slot:right-row="row">
                <div
                    class="d-flex align-items-center justify-content-end"
                    @click.stop
                >
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Delete User"
                        placement="top-start"
                    >
                        <a
                            href="#"
                            @click.prevent="deleteUser(row)"
                            style="text-decoration:none;"
                            class="btn-light-blue icon-btn mr-3 d-flex justify-content-center align-items-center"
                            type="danger"
                        >
                            <i class="fas fa-trash text-danger"></i>
                        </a>
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Edit User"
                        placement="top-start"
                    >
                        <a
                            href="#"
                            @click.prevent="updateUser(row)"
                            style="text-decoration:none;"
                            class="btn-light-blue icon-btn mr-3 d-flex justify-content-center align-items-center"
                            type="danger"
                        >
                            <i class="fas fa-edit text-blue"></i>
                        </a>
                    </el-tooltip>
                </div>
            </template>
        </paginated-table>
    </div>
</template>

<script>
import Supplier from "@/backend/models/Supplier";
import User from "@/backend/models/User";
export default {
    props: {
        supplier: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            users: {},
            tableData: {
                columns: [
                    { label: "First Name", name: "first_name", sortable: true },
                    { label: "Last Name", name: "last_name", sortable: true },
                    { label: "Email", name: "email", sortable: true },
                    {
                        label: "Updated At",
                        name: "updated_at",
                        datetime: true,
                        sortable: true
                    },
                    {
                        custom_component: true
                    }
                ],
                edit: false,
                showSearch: false,
                showPerPage: false
            }
        };
    },
    mounted() {
        this.getUsers(1);
        document.addEventListener("update-user-data", this.listener);
    },
    beforeDestroy() {
        document.removeEventListener("update-user-data", this.listener);
    },
    methods: {
        listener(event) {
            this.getUsers(1);
        },
        async getUsers(
            page = 1,
            sortColumn = null,
            query = null,
            per_page = 100,
            desc = false
        ) {
            let users = User.page(page).params({
                per_page: per_page
            });
            if (sortColumn) {
                users.orderBy(
                    (desc ? "-" : "") +
                        (sortColumn.sort_name || sortColumn.name)
                );
            } else {
                users.orderBy("-created_at");
            }

            users
                .for(new Supplier(this.supplier))
                .get()
                .then(res => {
                    this.loading = false;
                    this.users = res;
                })
                .catch(errors => {
                    this.loading = false;
                    console.error(errors);
                });
        },
        updateUser(value) {
            this.$emit("update-user", value);
        },
        deleteUser(value) {
            this.$emit("delete-user", value);
        }
    }
};
</script>
