<template>
    <div>
        <div class="container-fluid bg-white card application-card mk-introduction">
            <div class="row">
                <div class="col-12 position-relative">
                    <div class="app-card-decoration"></div>
                    <h1 class="mb-4">Welcome</h1>
                </div>
                <div class="col-12">
                    <p class="app-card-intro">The Pay Later online process will take you less than 3 minutes</p>
                    <p class="">Please make sure you have your Photo ID handy</p>
                </div>

                <!-- 
                <div class="col-12">
                    <p class="app-card-intro">The 107 day Pay Later Application process will only take a few minutes.</p>
                </div>
                <div class="col-12">
                    <p>Before we get going, make sure you have the following details ready for your application process:</p>
                    <ul class="mb-0">
                        <li>
                            <p>If you are selling your property, you will need to know your listing agency and your listing date.</p>
                        </li>
                        <li>
                            <p>Please make sure you have a valid form of photo ID, being an Australian Drivers Licence or Australian Passport.</p>
                        </li>
                        <li>
                            <p class="mb-0">Please make sure you have a valid Australian Credit or Debit card. You won’t need this now, but you will need one in order to become an Elevare customer.</p>
                        </li>
                    </ul>
                </div> -->


            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex align-items-center justify-content-center mx-auto">
                        <el-button
                            class="btn btn-primary mutable-mobile-width"
                            @click="$emit('next', true)"
                        >Start</el-button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        step: {
            required: true,
            type: Number
        }
    }
};
</script>
