export default {
    driving_license: {
        id: 1,
        name: "Australia Driving License",
    },
    passport: {
        id: 2,
        name: "Australian Passport",
    },
};
