<template>
    <div v-loading="loading">
        <div class="container-fluid bg-white application-card card mk-identity">
            <div class="row">

                <div v-if="form.errors.any()" class="col-12">
                    <div class="show-form-error">
                        <div v-for="(errors, index) in form.errors.all()" :key="index">
                            <ul class="mb-0">
                                <li v-for="(error, i) in errors" :key="i">{{error}}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-12 position-relative">
                    <div class="app-card-decoration"></div>
                    <h1>Your Identity</h1>
                </div>
                <div class="col-12">
                    <p class="app-card-intro">
                        Please make sure the details you complete below match the details on your nominated Photo ID.
                        <!-- It's important that during the application stage, we
                        confirm your identity. We’ll need to identify your
                        details and an ID document with various Australian
                        Government regulated bodies, so please ensure the
                        details you enter are correct. -->
                    </p>
                </div>
            </div>

            <div class="row id-margin-belt mb-3">
                <div class="col-12 col-lg-4">
                    <label for="first_name">First Name</label>
                    <el-input
                        style=""
                        v-model="application.customer.first_name"
                        :class="{'is-error':form.errors.has('customer.first_name')}"
                    >
                    </el-input>
                </div>
                <div class="col-12 col-lg-4">
                    <label for="middle_name">Middle Name</label>
                    <el-input
                        style=""
                        v-model="application.customer.middle_name"
                        :class="{'is-error':form.errors.has('customer.middle_name')}"
                    >
                    </el-input>
                </div>
                <div class="col-12 col-lg-4">
                    <label for="last_name">Last Name</label>
                    <el-input style="" v-model="application.customer.last_name"
                        :class="{'is-error':form.errors.has('customer.last_name')}"
                    >
                    </el-input>
                </div>
                <!-- <div class="col-12">
                    <p style="margin-bottom: 60px;">
                        Please write your full legal name as shown on your ID
                    </p>
                </div> -->
            </div>

            <div class="row">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <label for="date_of_birth">Date of Birth</label>
                    <el-date-picker
                        type="date"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        class="w-100"
                        v-model="application.customer.date_of_birth"
                        :class="{'is-error':form.errors.has('customer.date_of_birth')}"
                    >
                    </el-date-picker>
                </div>

                <div class="col-12 col-md-6">
                    <label for="email">Email</label>
                    <el-input v-model="application.customer.email" :class="{'is-error':form.errors.has('customer.email')}"></el-input>
                </div>

                <div class="col-12 col-md-6">
                    <label for="phone">Phone</label>
                    <vue-tel-input
                        mode="international"
                        :class="['w-100 form-control-borders', {'is-error':form.errors.has('customer.phone')}]"
                        :onlyCountries="['AU', 'GB', 'NZ', 'US']"
                        style="min-height:38px !important"
                        v-model="application.customer.phone"
                    ></vue-tel-input>
                </div>

                <div class="col-12">
                    <label for="residential_address"
                        >Current Residential Address</label
                    >
                    <el-input
                        :class="{'is-error':form.errors.has('customer.extra_attributes.residential_address.formatted_address')}"
                        v-if="
                            application.customer.extra_attributes
                                .residential_address
                        "
                        v-model="
                            application.customer.extra_attributes
                                .residential_address.formatted_address
                        "
                    >
                    </el-input>
                </div>
            </div>


            <div class="row mt-5">
                <div class="col-12">
                    <p class="app-card-intro mb-4">Identity verification</p>
                </div>
                <div class="col-12 col-md-4 d-flex flex-column has-dropdown-element">
                    <label for="identification_method">Identification Method</label>
                    <el-select
                        v-model="identification.identification_type"
                        :class="{'is-error':form.errors.has('identification.identification_type')}"
                        class="margin-b-30"
                    >
                        <el-option
                            v-for="opt in options"
                            :key="opt.id"
                            :value="opt.id"
                            :label="opt.name"
                        ></el-option>
                    </el-select>
                </div>

                <div
                    class="col-12 col-md-4 d-flex flex-column has-dropdown-element"
                    v-if="identification.identification_type == 1"
                >
                    <label for="issuing_state">Issuing State</label>
                    <el-select v-model="identification.issuing_state"  :class="{'is-error':form.errors.has('identification.issuing_state')}" class="margin-b-30">
                        <el-option
                            v-for="(state, index) in states"
                            :key="index"
                            :label="state.name"
                            :value="state.value"
                        ></el-option>
                    </el-select>
                </div>

                <div class="col-12 col-md-4">
                    <label for="identification_number">
                        <span v-if="identification.identification_type == 1">Licence</span>
                        <span v-else>Identification</span>
                        Number
                    </label>
                    <el-input v-model="identification.identification_number"  :class="{'is-error':form.errors.has('identification.identification_number')}">
                    </el-input>
                </div>
            </div>


            <!-- Upload Instruction row -->
            <div class="row mt-3">
                <div class="col-12">
                    <h3 class="text-blue font-heading" style="margin-bottom: 20px;">Verify Your Identity</h3>
                    <p class="app-card-intro">
                        Before an application can be approved, verification of your ID will need to be completed by the team at Elepay
                    </p>
                </div>

                <div class="col-12 col-lg-9 mb-4">
                    <div class="row">
                        <div class="col-12 d-md-none">
                            <div class="d-flex justify-content-center align-items-center">
                                <el-button class="btn btn-primary mt-0 w-100" @click="save(false)">
                                    Verify by taking a photo
                                </el-button>
                            </div>
                        </div>
                        <div class="col-12 d-md-none">
                            <div class="d-flex justify-content-center align-items-center my-1">
                                <span class="p-2">
                                    or
                                </span>
                            </div>
                        </div>

                        <div class="col-12 d-md-none">
                            <div class="d-flex justify-content-center align-items-center">
                                <el-button class="btn btn-primary mt-0 w-100" @click="save(true)">
                                    Verify without taking a photo
                                </el-button>
                            </div>
                        </div>



                        <div class="col-12 d-none d-md-block">
                            <div class="d-flex justify-content-start align-items-center">
                                <el-button class="btn btn-primary mt-0 mr-3" @click="save(false)">
                                    Verify by taking a photo
                                </el-button>

                                <span class="p-2">
                                    or
                                </span>

                                 <el-button class="btn btn-primary mt-0 ml-3" @click="save(true)">
                                    Verify without taking a photo
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="d-flex flex-md-row flex-column align-items-center justify-content-between mb-5"
        >
            <button
                class="btn-white-green-text mb-3 mb-md-0 mutable-mobile-width"
                @click="$emit('previous', true)"
            >
                Back
            </button>

            <!-- <el-button class="btn btn-primary" @click="save" :disabled="!idTc"
                >Confirm and continue</el-button
            > -->
        </div>
    </div>
</template>

<script>
import Supplier from "../../../models/Supplier";
import Application from "../../../models/Application";
import IdentificationMethods from "../../../constants/IdentificationMethods";
import States from "../../../constants/States";
import Form from 'form-backend-validation';
import Media from '../../../models/Media';
import axios from 'axios';
export default {
    props: {
        initial_supplier: {
            type: Object,
            required: true
        },
        initial_application: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            application: this.initial_application,
            supplier: this.initial_supplier,
            idTc: false,
            identification: this.initial_application.identifications[0] || {
                identification_type: null,
                identification_number: null,
                issuing_state: null
            },
            form: new Form(),
            loading: false,
        };
    },
    mounted() {
        if (!this.application.customer.extra_attributes.residential_address) {
            this.application.customer.extra_attributes = {
                residential_address: {
                    formatted_address: ""
                }
            };
        }
    },
    beforeDestroy(){
    },
    computed: {
        options() {
            return Object.keys(IdentificationMethods).map(key => {
                return IdentificationMethods[key];
            });
        },
        states() {
            return Object.keys(States).map(key => {
                return States[key];
            });
        }
    },
    methods: {
        /**
         * @deprecated
         */
        async deleteMedia(media, isDocuments) {
            try {
                const res = await new Media(media).for(new Supplier(this.supplier), new Application(this.application)).delete();
                const index = this.application.media.findIndex(m => m.id == media.id);
                if (index >= 0) this.application.media.splice(index, 1);
                if (!isDocuments) {
                    this.image = null;
                }
            } catch(err) {
                console.error(err);
            }
        },
        async save(skipIdentity = false) {
            try {
                this.form.clear();
                const res = await axios.post(
                    `/api/v1/customer/suppliers/${this.supplier.id}/applications/${this.application.id}/identifications`,
                    {
                        identification: this.identification,
                        customer: this.application.customer,
                        media_check: this.idTc
                    }
                );

                let payload = res.data;
                payload.skip_identity = skipIdentity;

                this.$emit('update-application' , payload);
                this.$emit("next", true);
            } catch (err) {
                console.error(err);
                this.form.errors.record(err.response.data.errors);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
        }
    },
};
</script>
