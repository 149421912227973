var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "container-fluid bg-white card application-card mk-appsum"
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-12 col-md-3",
              staticStyle: { "margin-bottom": "30px" }
            },
            [
              _c("label", [_vm._v("First Name")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.application.customer.first_name))])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-12 col-md-3",
              staticStyle: { "margin-bottom": "30px" }
            },
            [
              _c("label", [_vm._v("Last Name")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.application.customer.last_name))])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12", staticStyle: { "margin-bottom": "30px" } },
            [
              _c("label", [_vm._v("Authorised Supplier")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.supplier.name))])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12", staticStyle: { "margin-bottom": "30px" } },
            [
              _c("label", [_vm._v("Property Address")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.application.lead.extra_attributes.address
                        .formatted_address
                    ) +
                    "\n                "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-12 col-md-3",
              staticStyle: { "margin-bottom": "30px" }
            },
            [
              _c("label", [_vm._v("Agreement Date")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm.moment(new Date()).format("DD/MM/Y")))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-12 col-md-3",
              staticStyle: { "margin-bottom": "60px" }
            },
            [
              _c("label", [_vm._v("Estimated Start Date")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.moment
                        .utc(_vm.application.lead.start_date, "YYYY-MM-DD")
                        .format("DD/MM/Y")
                    ) +
                    "\n                "
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 mb-5" }, [
            _c(
              "h3",
              {
                staticClass: "text-blue font-heading",
                staticStyle: { "margin-bottom": "30px" }
              },
              [_vm._v("Dates and Fees Payable")]
            ),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("p", [
              _vm._v("Enjoy our low fees when you pay off your plan earlier.")
            ]),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "The table below provides you with the fees applicable if your payment is received by the designated date."
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-md-4" },
                [
                  _c("pay-before-card", {
                    attrs: {
                      paydayCardBg: "rgb(249, 249, 249)",
                      payBeforeDays: 45,
                      payBeforeFee: "2.95%",
                      payBeforeHighlightFee: true,
                      payBeforeInterest: "0.0%",
                      payBeforeAmountPayable: _vm.getTotalPayable(2.95, 0),
                      "due-date": _vm.getDueDate(44)
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-md-4" },
                [
                  _c("pay-before-card", {
                    attrs: {
                      paydayCardBg: "rgba(255, 78, 0, 0.03)",
                      payBeforeDays: 60,
                      payBeforeFee: "4.95%",
                      payBeforeHighlightFee: false,
                      payBeforeInterest: "0.0%",
                      payBeforeAmountPayable: _vm.getTotalPayable(4.95, 0),
                      "due-date": _vm.getDueDate(59)
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-md-4 schedule-mobile-card" },
                [
                  _c("pay-before-card", {
                    attrs: {
                      paydayCardBg: "rgba(255, 78, 0, 0.12)",
                      payBeforeDays: 107,
                      payBeforeFee: "5.0%",
                      payBeforeHighlightFee: false,
                      payBeforeInterest: "0.95%",
                      payBeforeAmountPayable: _vm.getTotalPayable(5.0, 0.95),
                      payBeforeIsFinalCard: true,
                      "due-date": _vm.getDueDate(107)
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12", staticStyle: { "margin-bottom": "60px" } },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.tc,
                    callback: function($$v) {
                      _vm.tc = $$v
                    },
                    expression: "tc"
                  }
                },
                [
                  _c("p", [
                    _c("strong", [
                      _vm._v(
                        "I agree that I must pay the Approved Marketing Amount (AMA) and associated fees, on the Repayment Date, even if I have not sold the Property"
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-12" }, [
            _c(
              "h3",
              {
                staticClass: "text-blue font-heading",
                staticStyle: { "margin-bottom": "30px" }
              },
              [_vm._v("Your Signature")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {},
              [
                _c("VueSignaturePad", {
                  ref: "signaturePad",
                  staticClass: "app-signature",
                  staticStyle: {
                    "margin-bottom": "8px",
                    border: "1px solid #e3e3e3"
                  },
                  attrs: {
                    options: {
                      backgroundColor: "#fff"
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "mb-0 has-interaction", on: { click: _vm.undo } },
              [
                _c("i", { staticClass: "fas fa-undo text-red mr-2" }),
                _c("strong", { staticClass: "text-red" }, [_vm._v("Undo")])
              ]
            )
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-md-row flex-column align-items-center justify-content-between mb-5 px-3 px-md-0"
      },
      [
        _c(
          "button",
          {
            staticClass:
              "btn-white-green-text mutable-mobile-width order-2 order-md-1",
            on: {
              click: function($event) {
                return _vm.$emit("previous", true)
              }
            }
          },
          [_vm._v("Back")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass:
              "btn btn-primary mutable-mobile-width order-1 order-md-2 mb-3 mb-md-0",
            attrs: { disabled: !_vm.tc },
            on: { click: _vm.save }
          },
          [_vm._v("Agree and submit")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 position-relative" }, [
        _c("div", { staticClass: "app-card-decoration" }),
        _vm._v(" "),
        _c("h1", [_vm._v("Application Summary")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12" }, [
        _c(
          "p",
          {
            staticClass: "app-card-intro",
            staticStyle: { "margin-bottom": "60px" }
          },
          [
            _vm._v(
              "We're almost there. Just before your final submission, please make sure that all the details of your application are correct."
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-1" }, [
      _c("strong", { staticStyle: { "font-size": "16px" } }, [
        _vm._v("The quicker you repay your plan, the lower your fees.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "mb-3 d-inline-block",
        staticStyle: {
          padding: "5px 10px",
          "background-color": "#FF4E00",
          "border-radius": "5px"
        }
      },
      [
        _c("p", { staticClass: "mb-0 text-white" }, [
          _c("strong", { staticClass: "text-white" }, [
            _vm._v("Payment Schedule:")
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }