var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row position-relative mk-applicationlanding" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col-md-12 col-12 top-container",
          staticStyle: {
            "padding-top": "100px",
            "background-size": "cover",
            "background-position": "left center"
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "container d-flex align-items-center justify-content-center",
              staticStyle: { "min-height": "500px" }
            },
            [
              _c("div", { staticClass: "row w-100" }, [
                _c("div", { staticClass: "col-12 mb-4" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "title-decoration-bar bg-pink",
                    staticStyle: { "margin-bottom": "38px" }
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn-pink w-100 text-center",
                      attrs: { href: _vm.createLink }
                    },
                    [_vm._v("Apply now")]
                  )
                ])
              ])
            ]
          )
        ]
      )
    ]),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _c("div", { staticClass: "mobile-key-benefits-belt" }),
    _vm._v(" "),
    _vm._m(3),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid bg-blue" }, [
      _c("div", { staticClass: "row py-4" }, [
        _c("div", { staticClass: "col-12 d-none d-md-block" }, [
          _c(
            "div",
            { staticClass: "d-flex justify-content-center align-items-center" },
            [
              _vm._m(4),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "mx-2 btn-pink",
                  attrs: { href: _vm.createLink }
                },
                [_vm._v("Apply now")]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 d-md-none" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-center align-items-center flex-column"
            },
            [
              _vm._m(5),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "mx-2 btn-pink w-100 text-center",
                  attrs: { href: _vm.createLink }
                },
                [_vm._v("Apply now")]
              )
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(6),
    _vm._v(" "),
    _vm._m(7)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "w-100", staticStyle: { top: "0", "z-index": "1" } },
      [
        _c(
          "div",
          {
            staticClass:
              "col-12 d-flex justify-content-between align-items-center"
          },
          [
            _c("div", { staticClass: "d-flex align-items-center p-3" }, [
              _c("img", {
                staticClass: "ml-4 img-fluid",
                staticStyle: { "max-width": "100px" },
                attrs: { src: "/img/backend/brand/elepay-no-tag.png" }
              })
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "ml-4 img-fluid",
              staticStyle: { "max-width": "160px" },
              attrs: { src: "/img/backend/brand/logo-advantage.png" }
            })
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h2",
      {
        staticClass: "text-blue font-heading text-white",
        staticStyle: { "margin-bottom": "27px", "font-size": "30px" }
      },
      [
        _vm._v("\n                            Defer your styling fees "),
        _c("br"),
        _vm._v("\n                            with Advantage "),
        _c("br"),
        _vm._v(
          "\n                            for up to 120 days!\n                        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container py-5" }, [
      _c("div", { staticClass: "row mx-5" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-center flex-column align-items-start"
            },
            [
              _c("div", {
                staticClass: "title-decoration-bar bg-pink",
                staticStyle: { "margin-bottom": "29px" }
              }),
              _vm._v(" "),
              _c(
                "h2",
                {
                  staticClass: "fw-700 font-heading mb-5",
                  staticStyle: { "margin-top": "7px", "font-size": "32px" }
                },
                [
                  _vm._v(
                    "\n                        Why pay now when you can pay later?\n                    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                        Advantage Property Styling and Elepay have teamed up\n                        to offere you a pay later option for your styling\n                        fees. Elepay is Australia's leading pay later\n                        provider, with bespoke payment plans for Australian\n                        property owners. With Advatange Pay Later, Elepay\n                        will pay your styling fees to Advantage, enabling\n                        you to repay Elepay anytime up to 120 days. Elepay\n                        has helped thousands of other Australians, who are\n                        taking advantage of this simple, hassle free,\n                        payment option.\n                    "
                )
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "no-hover btn-pink text-center",
                  staticStyle: { "border-bottom": "2px solid #ff968d" },
                  attrs: {
                    href: "#learn-more-modal",
                    "data-toggle": "modal",
                    "data-backdrop": "true"
                  }
                },
                [_vm._v("Find out more")]
              )
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "container-fluid py-5 key-benefits-container",
        staticStyle: {
          "background-size": "cover",
          "background-position": "center left"
        }
      },
      [
        _c("div", { staticClass: "container py-5" }, [
          _c("div", { staticClass: "row px-3 pl-3 pl-lg-5" }, [
            _c("div", { staticClass: "ml-auto col-12 col-lg-6" }, [
              _c("div", {
                staticClass: "title-decoration-bar bg-pink",
                staticStyle: { "margin-bottom": "29px" }
              }),
              _vm._v(" "),
              _c(
                "h2",
                {
                  staticClass: "fw-700 font-heading mb-5",
                  staticStyle: { "margin-top": "7px", "font-size": "32px" }
                },
                [
                  _vm._v(
                    "\n                        Key Features\n                    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _c("p", { staticClass: "mb-1 font-17" }, [
                    _vm._v(
                      "\n                                Style your home today and defer the styling\n                                fees for up to 120 days\n                            "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("p", { staticClass: "mb-1 font-17" }, [
                    _vm._v(
                      "\n                                No upfront payment\n                            "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("p", { staticClass: "mb-1 font-17" }, [
                    _vm._v(
                      "\n                                No monthly repayments\n                            "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("p", { staticClass: "mb-1 font-17" }, [
                    _vm._v(
                      "\n                                3 minute online application process\n                            "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("p", { staticClass: "mb-1 font-17" }, [
                    _vm._v(
                      "\n                                Approval within 2 hours\n                            "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("p", { staticClass: "mb-1 font-17" }, [
                    _vm._v(
                      "\n                                120 days to repay from application date\n                            "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("p", { staticClass: "mb-1 font-17" }, [
                    _vm._v(
                      "\n                                Fees starting from as low as 2%\n                            "
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mx-2 mb-0" }, [
      _c("strong", { staticClass: "text-white font-heading" }, [
        _vm._v("Application takes less than 3 minutes.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mx-2 mb-3" }, [
      _c("strong", { staticClass: "text-white font-heading" }, [
        _vm._v("Application takes less than 3 minutes.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container-fluid bg-slate py-5" }, [
      _c("div", { staticClass: "container py-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-5 d-flex flex-column align-items-center align-items-lg-start mb-4 mb-lg-0"
            },
            [
              _c("img", {
                staticClass:
                  "mb-3 img-fluid align-self-center align-self-lg-start",
                attrs: { src: "/img/logos/elevare-logo.svg" }
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "title-decoration-bar bg-pink",
                staticStyle: { "margin-top": "20px" }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-2 d-flex flex-column align-items-center align-items-lg-start mb-4 mb-lg-0"
            },
            [
              _c("img", {
                staticClass:
                  "mb-3 img-fluid align-self-center align-self-lg-start",
                attrs: { src: "/img/elements/footer-address.png" }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "text-white mb-1" }, [
                _vm._v("273 Abbotsford Road")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-white" }, [
                _vm._v("Bowen Hills QLD 4006")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-2 d-flex flex-column mb-4 mb-lg-0" },
            [
              _c("img", {
                staticClass:
                  "mb-3 img-fluid align-self-center align-self-lg-start",
                attrs: { src: "/img/elements/footer-phone.png" }
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "text-white text-center text-lg-left",
                  attrs: { href: "tel:1300019417" }
                },
                [_vm._v("1300 019 417")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-3 d-flex flex-column mb-4 mb-lg-0" },
            [
              _c("img", {
                staticClass:
                  "mb-3 img-fluid align-self-center align-self-lg-start",
                attrs: { src: "/img/elements/footer-email.png" }
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "text-white text-center text-lg-left",
                  attrs: { href: "mailto:hello@elevarepayeasy.com.au" }
                },
                [_vm._v("hello@elevarepayeasy.com.au")]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-1 mt-md-4" }, [
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-5 d-flex flex-column align-items-center align-items-lg-start"
            },
            [
              _c("p", { staticClass: "mb-3 mb-lg-0" }, [
                _c("strong", { staticClass: "text-white" }, [
                  _vm._v(
                    "We're the Pay Later specialists for the\n                            property Industry."
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-2 d-flex flex-column align-items-center align-items-lg-start"
            },
            [
              _c("p", { staticClass: "text-m-grey mb-3 mb-lg-0" }, [
                _vm._v(
                  "\n                        ABN: 47 634 728 591\n                    "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-2 d-flex flex-column align-items-center align-items-lg-start"
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "text-m-grey text-center text-lg-left mb-3 mb-lg-0",
                  attrs: { href: "#0" }
                },
                [_vm._v("Terms & Conditions")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-3 d-flex flex-column align-items-center align-items-lg-start"
            },
            [
              _c(
                "a",
                {
                  staticClass: "text-m-grey text-center text-lg-left",
                  attrs: { href: "#0" }
                },
                [_vm._v("Privacy and Refund Policies")]
              )
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "modal", attrs: { id: "learn-more-modal" } },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "div",
              {
                staticClass: "modal-header px-5 pt-5",
                staticStyle: { "border-bottom": "none" }
              },
              [
                _c("img", {
                  staticClass: "img-fluid",
                  staticStyle: { "max-width": "100px" },
                  attrs: { src: "/img/backend/brand/elepay-no-tag.png" }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-label": "Close"
                    }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _c("i", { staticClass: "far fa-times-circle" })
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body px-5" }, [
              _c("p", [
                _c("strong", [
                  _vm._v(
                    "The Elepay business is conducted by Elevare Pay\n                            Easy Pty Ltd, an Australian privately-owned\n                            company which is part of the Elevare Pay Easy\n                            Group.\n                        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                        Elevare Pay Easy Pty Ltd provides a deferred payment\n                        solution and Buy Now Pay Later solution for\n                        participants in the Australian property industry.\n                        The executive team of the Elevare Pay Easy Group\n                        have been involved within the deferred payment\n                        solution and Buy Now Pay Later industry since the\n                        category was established in 2014. Elepay’s suite of\n                        products have been designed and approved by\n                        professional experts to ensure that they comply with\n                        all relevant legal requirements and standards.\n                    "
                )
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "img-fluid mb-4",
                staticStyle: { "max-width": "160px" },
                attrs: { src: "/img/backend/brand/elevare.png" }
              })
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }