<template>
    <div>
        <div class="container-fluid bg-white card application-card mk-fe-lead">
            <div class="row">

                <div v-if="form.errors.any()" class="col-12">
                    <div class="show-form-error">
                        <div v-for="(errors, index) in form.errors.all()" :key="index">
                            <ul class="mb-0">
                                <li v-for="(error, i) in errors" :key="i">{{error}}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-12 position-relative">
                    <div class="app-card-decoration"></div>
                    <h1>Let's get started</h1>
                </div>
                <div class="col-12">
                    <p class="app-card-intro">Here are the details we already have. Just hit confirm and continue. If you need to change any field, use our chat support and we'll be in touch shortly.</p>
                </div>


                <div class="col-12 col-md-6">
                    <label for="supplier_user_name">Supplier Agent Name</label>
                    <el-input v-model="application.lead.sales_representative"
                        disabled
                        :class="{'is-error':form.errors.has('sales_representative')}"
                    >
                    </el-input>
                </div>

                <div class="col-12 col-md-6">
                    <label for="supplier_name">Supplier Name</label>
                    <el-input :value="supplier.name" disabled> </el-input>
                </div>

                <div class="col-12">
                    <label for="property_address">Property Address</label>
                    <el-input
                        :class="{'is-error':form.errors.has('extra_attributes.address.formatted_address') }"
                        v-model="application.lead.extra_attributes.address.formatted_address"
                    ></el-input>
                </div>

                <div class="col-12 col-md-6">
                    <label for="amount_requested">107 Day Amount Requested</label>
                    <currency-input
                        class="el-input__inner"
                        :class="{'is-error':form.errors.has('requested_amount')}"
                        v-model="application.lead.dollar_requested_amount"
                        disabled
                        @change="value =>
                                (application.lead.requested_amount =
                                    value * 100)"
                    />
                </div>


                <!-- <div class="col-12 col-md-6 d-flex align-items-end">
                    <p class="text-pink font-12 fw-400" style="margin-bottom: 30px;">*Please note: Once approved, this amount will become your Approved Marketing Amount (AMA)</p>
                </div> -->


                <div class="col-12 col-md-6 flex-column d-flex">
                    <label for="start_date">Estimated Start Date</label>
                    <el-date-picker
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        class="w-100"
                        type="date"
                        disabled
                        :class="{'is-error':form.errors.has('start_date')}"
                        v-model="application.lead.start_date"
                    >
                    </el-date-picker>
                </div>

                <div class="col-12 col-md-6">
                    <label for="fund_required_for">Funds required for</label>
                    <!-- <el-input disabled value="Property Purchasing"> </el-input> -->
                    <el-select
                        v-model="application.lead.fund_purpose_ids"
                        class="w-100 funds-req-select"
                        disabled multiple
                    >
                        <el-option
                            v-for="purpose in fundPurposes"
                            :key="purpose.id"
                            :value="purpose.id"
                            :label="purpose.name"
                        ></el-option>
                    </el-select>
                    <el-input v-model="application.lead.other_purpose" disabled class="mt-3" v-if="
                            application.lead.fund_purposes.filter(
                                fp => fp.name == 'Other'
                            ).length > 0
                        "></el-input>
                </div>
            </div>
        </div>

        <div
            class="d-flex flex-md-row flex-column align-items-center justify-content-between mb-5 px-3 px-md-0"
        >
            <button
                class="btn-white-green-text mutable-mobile-width order-2 order-md-1"
                @click="$emit('previous', true)"
            >Back</button>

            <el-button
                class="btn btn-primary mutable-mobile-width order-1 order-md-2 mb-3 mb-md-0"
                @click="save"
            >Confirm and continue</el-button>
        </div>
    </div>
</template>

<script>
import Supplier from "../../../models/Supplier";
import Application from "../../../models/Application";
import ApplicationLead from "../../../models/ApplicationLead";
import axios from "axios";
import Form from 'form-backend-validation';
export default {
    props: {
        initial_supplier: {
            type: Object,
            required: true
        },
        initial_application: {
            type: Object,
            required: true
        },
        step: {
            type: Number,
            required: true
        },
        fundPurposes:{
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            application: this.initial_application,
            supplier: this.initial_supplier,
            form: new Form(),
        };
    },
    mounted(){
        if (this.application.lead.fund_purposes) {
            this.application.lead.fund_purpose_ids = this.application.lead.fund_purposes.map(
                fp => fp.id
            );
        }
    },
    methods: {
        async save() {
            try {
                this.form.clear();
                const res = await axios.post(
                    `/api/v1/customer/suppliers/${this.supplier.id}/applications/${this.application.id}/application_leads`,
                    this.application.lead
                );
                this.$emit("next", true);
            } catch (err) {
                console.error(err);
                this.form.errors.record(err.response.data.errors);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                // this.showFormError = true;
                // var errorText = "";
                // _.forEach(err.response.data.errors, function(value) {
                //     errorText += "" + value + " ";
                // });
                // this.$message({
                //     type: "error",
                //     message: errorText
                // });
            }
        }
    }
};
</script>