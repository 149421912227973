export default {
    methods: {
        getDefaultIdentity() {
            if (Object.keys(this.$store.getters['Identification/getIdentification']).length > 0) {
                this.identification = this.$store.getters['Identification/getIdentification'];
            }
        },
        getDefaultCustomer() {
            if (!this.application.customer.extra_attributes.residential_address) {
                if (this.$store.getters['Customer/getCustomer'].extra_attributes &&
                    this.$store.getters['Customer/getCustomer'].extra_attributes.residential_address) {
                    this.application.customer.extra_attributes = this.$store.getters['Customer/getCustomer'].extra_attributes;
                } else {
                    this.application.customer.extra_attributes = {
                        residential_address: {
                            formatted_address: ""
                        }
                    };
                }
            }
            // if customer data exists
            this.application.customer.middle_name = this.$store.getters['Customer/getCustomer'].middle_name || null;
            this.application.customer.date_of_birth = this.$store.getters['Customer/getCustomer'].date_of_birth || null;
        }
    }
}
