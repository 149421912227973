<template>
    <div class="customer-card bg-white pt-3">
        <div class="row">
            <div
                class="col-12 d-flex align-items-center justify-content-between"
            >
                <h2 class="mb-0">Plan Variations</h2>
                <el-select v-model="status" @change="getPlans()">
                    <el-option
                        v-for="status in statuses"
                        :key="status.name"
                        :value="status.name"
                        :label="status.name"
                    >
                    </el-option>
                </el-select>
            </div>

            <div class="col-12">
                <paginated-table
                    v-if="plans.data"
                    :tableData="tableData"
                    :paginatedData="plans"
                    :initial_page="15"
                    @pagination-change-page="getPlans"
                >
                    <template v-slot:right-row="row">
                        <div
                            class="d-flex align-items-center justify-content-end"
                            @click.stop
                        >
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Edit Plan Variation"
                                placement="top-start"
                            >
                                <a
                                    :href="
                                        `/admin/suppliers/${row.supplier.id}/applications/${row.application.id}/plans/${row.id}/edit`
                                    "
                                    style="text-decoration:none;"
                                    class="btn-light-blue icon-btn mr-3 d-flex justify-content-center align-items-center"
                                >
                                    <i class="fas fa-eye text-green"></i>
                                </a>
                            </el-tooltip>
                        </div>
                    </template>
                </paginated-table>
            </div>
        </div>
    </div>
</template>

<script>
import PlanVariation from "@/backend/models/PlanVariation";
import Supplier from "@/backend/models/Supplier";
import PlanVariationStatus from "@/backend/constants/PlanVariationStatus";
export default {
    props: {},
    data() {
        return {
            plans: {},
            status: "Current",
            tableData: {
                columns: [
                    {
                        label: "First Name",
                        name: "application.customer.first_name",
                        sortable: false
                    },
                    {
                        label: "Last Name",
                        name: "application.customer.last_name",
                        sortable: false
                    },
                    {
                        label: "Email",
                        name: "application.customer.email",
                        sortable: false
                    },
                    {
                        label: "Variation",
                        name: "Variation",
                        sortable: false,
                        is_text: true,
                        getText: row => {
                            let text = "";
                            if (row.revised_start_date) {
                                text += moment(row.revised_start_date).format(
                                    "DD/MM/Y"
                                );
                            }

                            if (
                                row.revised_start_date &&
                                row.revised_plan_amount
                            ) {
                                text += " and ";
                            }

                            if (row.revised_plan_amount) {
                                text += `$${Number(
                                    row.dollar_revised_plan_amount
                                ).toLocaleString("en-AU", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}`;
                            }

                            return text;
                        }
                    },
                    {
                        label: "Status",
                        name: "plan_variation_status.status",
                        sortable: false
                    },
                    {
                        label: "Submitted At",
                        name: "submitted_at",
                        whole_date: true,
                        sortable: false
                    },
                    {
                        label: "Created At",
                        name: "created_at",
                        datetime: true,
                        sortable: false
                    },
                    {
                        custom_component: true
                    }
                ],
                edit: false,
                showSearch: false,
                showPerPage: true
            }
        };
    },
    mounted() {
        this.getPlans();
    },
    methods: {
        async getPlans(
            page = 1,
            sortColumn = null,
            query = null,
            per_page = 15,
            desc = false
        ) {
            let plans = PlanVariation.page(page).params({
                per_page: per_page
            });
            if (sortColumn) {
                plans.orderBy(
                    (desc ? "-" : "") +
                        (sortColumn.sort_name || sortColumn.name)
                );
            } else {
                plans.orderBy("-created_at");
            }

            plans
                .where("plan_variation_status", this.status)
                .include(
                    "application.customer",
                    "plan_variation_status",
                    "supplier"
                )
                .get()
                .then(res => {
                    this.loading = false;
                    this.plans = res;
                })
                .catch(errors => {
                    this.loading = false;
                    console.error(errors);
                });
        }
    },
    computed: {
        statuses() {
            return Object.keys(PlanVariationStatus).map(key => {
                return PlanVariationStatus[key];
            });
        }
    }
};
</script>
