<template>
    <div>
        <div class="container-fluid bg-white card py-5">
            <div class="row">
                <div class="col-6">
                    <label for="supplier_user_name">Supplier Agent Name</label>
                    <el-input
                        v-model="application.lead.sales_representative"
                        disabled
                    >
                    </el-input>
                </div>
                <div class="col-6">
                    <label for="supplier_name">Supplier Name</label>
                    <el-input :value="supplier.name" disabled> </el-input>
                </div>
                <div class="col-12 mt-3">
                    <label for="property_address">Property Address</label>
                    <el-input
                        disabled
                        v-model="
                            application.lead.extra_attributes.address
                                .formatted_address
                        "
                    >
                    </el-input>
                </div>
                <div class="col-6 mt-3">
                    <label for="amount_requested">Amount Requested</label>
                    <!-- <el-input
                        disabled
                        @change="
                            value =>
                                (application.lead.requested_amount =
                                    value * 100)
                        "
                        v-model="application.lead.dollar_requested_amount"
                        type="number"
                    >
                    </el-input> -->
                    <div class="el-input is-disabled">
                        <currency-input
                            class="el-input__inner"
                            v-model="application.lead.dollar_requested_amount"
                            disabled
                        />
                    </div>
                </div>
                <div class="col-6 flex-column d-flex mt-3">
                    <label for="start_date">Estimated Start Date</label>
                    <el-date-picker
                        disabled
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        class="w-100"
                        type="date"
                        v-model="application.lead.start_date"
                    >
                    </el-date-picker>
                </div>
                <div class="col-6 mt-3">
                    <label for="fund_required_for">Fund Required For</label>
                    <el-input
                        disabled
                        :value="
                            application.lead.fund_purposes
                                .map(fp => fp.name)
                                .join(',')
                        "
                    >
                    </el-input>
                    <el-input
                        v-model="application.lead.other_purpose"
                        disabled
                        class="mt-3"
                        v-if="
                            application.lead.fund_purposes.filter(
                                fp => fp.name == 'Other'
                            ).length > 0
                        "
                    ></el-input>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        initial_supplier: {
            type: Object,
            required: true
        },
        initial_application: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            application: this.initial_application,
            supplier: this.initial_supplier
        };
    },
    methods: {}
};
</script>
