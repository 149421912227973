<template>
    <div v-loading="loading">
        <div class="container-fluid bg-white application-card card mk-leadidentity">
            <div class="row">
                <div v-if="form.errors.any()" class="col-12">
                    <div class="show-form-error">
                        <div
                            v-for="(errors, index) in form.errors.all()"
                            :key="index"
                        >
                            <ul class="mb-0">
                                <li v-for="(error, i) in errors" :key="i">
                                    {{ error }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-12 position-relative">
                    <div class="app-card-decoration"></div>
                    <h1>Your Identity</h1>
                </div>
                <div class="col-12">
                    <p class="app-card-intro">
                        Please make sure the details you complete below match
                        the details on your nominated Photo ID.
                    </p>
                </div>
            </div>


            <div class="row id-margin-belt">
                <div class="col-12 col-lg-4">
                    <label for="first_name">First Name</label>
                    <el-input
                        style=""
                        v-model="application.customer.first_name"
                        :class="{
                            'is-error': form.errors.has('customer.first_name')
                        }"
                        @change="_createLead"
                    >
                    </el-input>
                </div>
                <div class="col-12 col-lg-4">
                    <label for="middle_name">Middle Name</label>
                    <el-input
                        style=""
                        v-model="application.customer.middle_name"
                        :class="{
                            'is-error': form.errors.has('customer.middle_name')
                        }"
                    >
                    </el-input>
                </div>
                <div class="col-12 col-lg-4">
                    <label for="last_name">Last Name</label>
                    <el-input
                        style=""
                        v-model="application.customer.last_name"
                        :class="{
                            'is-error': form.errors.has('customer.last_name')
                        }"
                    >
                    </el-input>
                </div>
                <!-- <div class="col-12">
                    <p style="margin-bottom: 60px;">
                        Please write your full legal name as shown on your ID
                    </p>
                </div> -->
            </div>

            <div class="row">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <label for="date_of_birth">Date of Birth</label>
                    <el-date-picker
                        type="date"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        class="w-100"
                        v-model="application.customer.date_of_birth"
                        :class="{
                            'is-error': form.errors.has(
                                'customer.date_of_birth'
                            )
                        }"
                    >
                    </el-date-picker>
                </div>

                <div class="col-12 col-md-6">
                    <label for="email">Email</label>
                    <el-input
                        v-model="application.customer.email"
                        @change="_createLead"
                        :class="{
                            'is-error': form.errors.has('customer.email')
                        }"
                    ></el-input>
                </div>

                <div class="col-12 col-md-6">
                    <label for="phone">Phone</label>
                    <vue-tel-input
                        mode="international"
                        :class="[
                            'w-100 form-control-borders',
                            { 'is-error': form.errors.has('customer.phone') }
                        ]"
                        :onlyCountries="['AU', 'GB', 'NZ', 'US']"
                        style="min-height:38px !important"
                        v-model="application.customer.phone"
                        @blur="_createLead"
                    ></vue-tel-input>
                </div>

                <div class="col-12">
                    <label for="residential_address"
                        >Current Residential Address</label
                    >
                    <el-input
                        :class="{
                            'is-error': form.errors.has(
                                'customer.extra_attributes.residential_address.formatted_address'
                            )
                        }"
                        v-if="
                            application.customer.extra_attributes
                                .residential_address
                        "
                        v-model="
                            application.customer.extra_attributes
                                .residential_address.formatted_address
                        "
                    >
                    </el-input>
                </div>
            </div>

            <div class="row">
                <div
                    class="col-12 col-md-4 d-flex flex-column has-dropdown-element"
                >
                    <label for="identification_method"
                        >Identification Method</label
                    >
                    <el-select
                        v-model="identification.identification_type"
                        :class="{'is-error': form.errors.has('identification.identification_type')}"
                        class="margin-b-30"
                    >
                        <el-option
                            v-for="opt in options"
                            :key="opt.id"
                            :value="opt.id"
                            :label="opt.name"
                        ></el-option>
                    </el-select>
                </div>

                <div
                    class="col-12 col-md-4 d-flex flex-column has-dropdown-element"
                    v-if="identification.identification_type == 1"
                >
                    <label for="issuing_state">Issuing State</label>
                    <el-select
                        v-model="identification.issuing_state"
                        :class="{'is-error': form.errors.has('identification.issuing_state')}"
                        class="margin-b-30"
                    >
                        <el-option
                            v-for="(state, index) in states"
                            :key="index"
                            :label="state.name"
                            :value="state.value"
                        ></el-option>
                    </el-select>
                </div>

                <div class="col-12 col-md-4">
                    <label for="identification_number"
                        >Identification Number</label
                    >
                    <el-input
                        v-model="identification.identification_number"
                        :class="{'is-error': form.errors.has('identification.identification_number')}"
                    >
                    </el-input>
                </div>
            </div>

            <!-- Upload Instruction row -->
             <div class="row mt-3">
                <div class="col-12">
                    <h3 class="text-blue font-heading" style="margin-bottom: 20px;">Verify Your Identity</h3>
                    <p class="app-card-intro">
                        Before an application can be approved, verification of your ID will need to be completed by the team at Elepay
                    </p>
                </div>

                <div class="col-12 col-lg-9 mb-4">
                    <div class="row">
                        <div class="col-12 d-md-none">
                            <div class="d-flex justify-content-center align-items-center">
                                <el-button class="btn btn-primary mt-0 w-100" @click="save(false)">
                                    Verify by taking a photo
                                </el-button>
                            </div>
                        </div>
                        <div class="col-12 d-md-none">
                            <div class="d-flex justify-content-center align-items-center my-1">
                                <span class="p-2">
                                    or
                                </span>
                            </div>
                        </div>

                        <div class="col-12 d-md-none">
                            <div class="d-flex justify-content-center align-items-center">
                                <el-button class="btn btn-primary mt-0 w-100" @click="save(true)">
                                    Verify without taking a photo
                                </el-button>
                            </div>
                        </div>

                        <div class="col-12 d-none d-md-block">
                            <div class="d-flex justify-content-start align-items-center">
                                <el-button class="btn btn-primary mt-0 mr-3" @click="save(false)">
                                    Verify by taking a photo
                                </el-button>

                                <span class="p-2">
                                    or
                                </span>

                                 <el-button class="btn btn-primary mt-0 ml-3" @click="save(true)">
                                    Verify without taking a photo
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row"> -->
                <!-- <div class="col-12">
                    <h3 class="text-blue font-heading" style="margin-bottom: 20px;">Upload Identity Verification Photo</h3>
                    <p class="app-card-intro">Please ensure your verification photo fulfills the following requirements*</p>

                    <ul>
                        <li>
                            <p>Nominated Photo ID held up next to your face clearly visible (not cut off)</p>
                        </li>
                        <li>
                            <p>Clear, focused image with good lighting</p>
                        </li>
                        <li>
                            <p>Face centred and looking at the camera straight on; not tilted in any direction</p>
                        </li>
                        <li>
                            <p>Hair off your face, so that the edges of your face are visible</p>
                        </li>
                        <li>
                            <p>Eyes open, mouth closed</p>
                        </li>
                        <li>
                            <p>Neutral expression (not smiling, laughing or frowning)</p>
                        </li>
                    </ul>
                </div> -->

                <!-- Acceptable images -->
                <!-- <div class="col-12 col-lg-6 mb-4">
                    <div class="row">
                        <div class="col-12 mb-4">
                            <p class="text-green mb-2"><i class="fas fa-check text-green mr-2"></i> Acceptable</p>
                            <img style="border: 1px solid #8ea604;" class="img-fluid mb-1" src="/img/elements/verification-acceptable.png" />
                        </div>

                        <div class="col-12 col-lg-6">
                            <p class="text-red mb-2"><i class="fas fa-times text-red mr-2"></i>Unacceptable</p>
                            <img style="border: 1px solid #ff4e00;" class="img-fluid mb-1 w-100" src="/img/elements/verification-unacceptable-1.png" />
                            <p class="text-red">Face is not included</p>
                        </div>

                        <div class="col-12 col-lg-6">
                            <p class="text-red mb-2"><i class="fas fa-times text-red mr-2"></i>Unacceptable</p>
                            <img style="border: 1px solid #ff4e00;" class="img-fluid mb-1 w-100" src="/img/elements/verification-unacceptable-2.png" />
                            <p class="text-red">Photo ID is cut off</p>
                        </div>
                    </div>
                </div> -->
            <!-- </div> -->





            <!-- Upload ID row -->
            <!-- <div class="row"> -->
                <!-- <div
                    class="col-12"
                    v-if="
                        !image && application.media.filter(
                            m =>
                                m.collection_name == 'documents' ||
                                m.collection_name == 'face_proof'
                        ).length == 0
                    "
                > -->
                    <!-- <div class="row"> -->
                        <!-- <div class="col-md-5 col-12">
                            <button
                                class="d-flex flex-column bg-white bg-hover-white"
                                style="padding: 20px; border: 1px solid rgb(227, 227, 227); border-radius: 5px;"
                                @click.prevent="openFile"
                                :disabled="!application.id"
                            >
                                <span class="text-d-grey font-14 mb-3">Upload Photo of Identification</span>
                                <span class="text-m-grey font-12 text-left mb-3">Please upload a photo of yourself holding your <strong class="text-m-grey">NOMINATED PHOTO ID beside your face</strong> to allow us to verify your identity. Accepted file formats: jpg, png</span>
                                <span class="text-green fw-700">Upload File</span>
                            </button>
                        </div> -->

                        <!-- <div
                            class="col-md-2 col-12 d-flex justify-content-center align-items-center"
                            v-if="!isMobile"
                        >
                            <p class="mb-0 text-center py-3 w-100">Or</p>
                        </div> -->

                        <!-- <div
                            class="col-md-5 col-12 d-flex justify-content-center"
                            v-if="!isMobile"
                        > -->
                            <!-- TODO: Wire button -->
                            <!-- <a
                                :disabled="!application.id"
                                class="d-flex flex-column bg-white bg-hover-white"
                                style="padding: 20px; border: 1px solid rgb(227, 227, 227); border-radius: 5px;"
                                :href="application.link_to_face"
                                target="_blank"
                            >
                                <span class="text-d-grey font-14 mb-3">Take Photo</span>
                                <span class="text-m-grey font-12 text-left mb-3">Use our photo software and your web cam to take your verification photo.</span>
                                <span class="text-green fw-700 mt-auto">Take Photo</span>
                            </a> -->
                        <!-- </div> -->
                    <!-- </div> -->
                <!-- </div> -->


                <!-- <div
                    v-else-if="image"
                    class="col-12 d-flex align-items-center"
                >
                    <img :src="image.url" style="max-width:200px;" />

                    <p
                        class="mb-0 mt-2 text-red has-interaction"
                        @click="deleteMedia(image, false)"
                    >
                        <i class="fas fa-times text-red p-1 fa-sm"></i>
                    </p>
                </div> -->


                <!-- <div
                    class="col-12 d-flex flex-column mb-3"
                    v-else-if="
                        application.media && application.media.filter(
                            m => m.collection_name == 'documents'
                        ).length > 0
                    "
                > -->
                    <!-- <div
                        v-for="media in application.media.filter(
                            m => m.collection_name == 'documents'
                        )"
                        :key="media.id"
                        class="mb-3 d-flex align-items-center"
                    > -->
                        <!-- <a
                            class="text-black text-underline mr-2"
                            :href="media.url"
                            _target="blank"
                        >
                            {{ media.file_name }}
                        </a> -->

                        <!-- <div
                            @click="deleteMedia(media, true)"
                            class="text-red has-interaction"
                            style="cursor:pointer;"
                        ><i class="fas fa-times text-red p-1 fa-sm"></i>
                        </div> -->
                    <!-- </div> -->

                    <!-- <div
                        @click="openFile"
                        class="d-flex align-items-center justify-content-center has-interaction"
                    >
                        <p class="mb-0"><strong class="text-green">Add More Files</strong></p>
                    </div> -->
                <!-- </div> -->

                <!-- <input
                    type="file"
                    v-show="false"
                    multiple
                    ref="file"
                    @change="uploadFiles"
                /> -->

                <!-- <div class="col-12" style="margin-top: 30px;">
                    <el-checkbox v-model="idTc"
                        >I confirm that my identity verification photo has been attached as per the required format.</el-checkbox>
                </div> -->
            <!-- </div> -->
            <!-- End Upload ID row -->
        </div>

        <div
            class="d-flex flex-md-row flex-column align-items-center justify-content-between mb-5 px-3 px-md-0"
        >
            <button
                class="btn-white-green-text mb-3 mb-md-0 mutable-mobile-width"
                @click="back"
            >
                Back
            </button>

            <!-- <el-button class="btn btn-primary" @click="save" :disabled="!idTc && !application.id"
                >Confirm and continue</el-button
            > -->
        </div>
    </div>
</template>

<script>
import Supplier from "../../../models/Supplier";
import Application from "../../../models/Application";
import IdentificationMethods from "../../../constants/IdentificationMethods";
import States from "../../../constants/States";
import Form from "form-backend-validation";
import Media from "../../../models/Media";
import axios from "axios";
import CreateLead from "../../../modules/CreateLead";
import LeadIdentity from '../../../mixins/LeadIdentity';
import {mapGetters} from 'vuex';
export default {
    mixins:[LeadIdentity],
    props: {
        initial_supplier: {
            type: Object,
            required: true
        },
        initial_application: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            application: this.initial_application,
            supplier: this.initial_supplier,
            idTc: false,
            identification: this.initial_application.identifications[0] || {
                identification_type: null,
                identification_number: null,
                issuing_state: null
            },
            form: new Form(),
            loading: false
        };
    },
    mounted() {
        this.getDefaultCustomer();
        this.getDefaultIdentity();
    },
    beforeDestroy() {
    },
    computed: {
        ...mapGetters({
            linkToFace: 'Identification/getLinkToFace',
            vxIdentity: 'Identification/getIdentification',
            vxCustomer: 'Customer/getCustomer'
        }),
        options() {
            return Object.keys(IdentificationMethods).map(key => {
                return IdentificationMethods[key];
            });
        },
        states() {
            return Object.keys(States).map(key => {
                return States[key];
            });
        },
        createNow() {
            return this.application.customer.first_name && this.application.customer.email && this.application.customer.phone;
        },
    },
    methods: {
        back() {
            this.$store.commit("Customer/setCustomer", this.application.customer);
            this.$store.commit('Identification/setIdentification', this.identification);
            this.$emit("update-application", this.application);
            this.$emit('previous', true);
        },
        async _createLead() {
            if (this.application.id) return;
            else if (this.createNow) {
                const extra_attributes = this.application.customer.extra_attributes;
                const dob = this.application.customer.date_of_birth;
                const middle_name = this.application.customer.middle_name;
                try {
                    this.form.clear();
                    this.loading = true;
                    const createLead = new CreateLead(
                        this.supplier,
                        this.application,
                        this.application.customer
                    );
                    const res = await createLead.save();
                    const application = await createLead.getApplication(
                        res.applications[0]
                    );
                    this.application = application;

                    // override: lead, customer
                    this.application.customer.extra_attributes = extra_attributes;
                    this.application.customer.date_of_birth = dob;
                    this.application.customer.middle_name = middle_name;

                    // store the link to face;
                    this.$emit("update-application", this.application);
                    this.$store.commit("Customer/setCustomer", this.application.customer);
                    this.$store.commit('Identification/setLinkToFace', this.application.link_to_face); // store in vuex
                    this.loading = false;
                } catch (err) {
                    this.loading = false;
                    console.error(err);
                    this.form.errors.record(err.response.data.errors);
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }
            }
        },
        async save(skippingIdentity = false) {
            if (this.application.id) this.saveIdentification(skippingIdentity);
            else this._createLead();
        },
        async saveIdentification(skippingIdentity = false) {
            try {
                this.form.clear();
                const res = await axios.post(
                    `/api/v1/customer/suppliers/${this.supplier.id}/applications/${this.application.id}/identifications`,
                    {
                        identification: this.identification,
                        customer: this.application.customer,
                        media_check: this.idTc
                    }
                );
                // store customer data
                this.$store.commit('Customer/setCustomer', res.data.customer);
                this.$store.commit('Identification/setIdentification', {}); // reset identification 

                let payload = res.data;
                payload.link_to_face = this.linkToFace;
                payload.skip_identity = skippingIdentity;
                
                this.$emit("update-application", payload);
                this.$emit("next", true);
            } catch (err) {
                console.error(err);
                this.form.errors.record(err.response.data.errors);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
        },
    }
};
</script>
