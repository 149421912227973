const RELEASE_ELEPAY_EMAIL_FLOW = process.env.MIX_RELEASE_ELEPAY_EMAIL_FLOW;
export default {
    lead: {
        id: 1,
        name: "Lead",
    },
    full_application: {
        id: 2,
        name: "FastTrack Full Application",
    },
    ...RELEASE_ELEPAY_EMAIL_FLOW === 'true' ? {
        elepay_full_application: {
            id: 3,
            name: "Elepay Full Application",
        }
    } : null,
    plan_variation: {
        id: 4,
        name: 'Send Plan Variation'
    },
    accepted_plan_variation_supplier: {
        id: 5,
        name: 'Supplier Accepted Plan Variation'
    },
    accepted_plan_variation_admin: {
        id: 6,
        name: 'Admin Accepted Plan Variation'
    }
};
