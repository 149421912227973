var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "container-fluid bg-white card application-card mk-thankyou"
      },
      [
        _c("div", { staticClass: "row" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "btn btn-primary mutable-mobile-width mb-2",
                  on: {
                    click: function($event) {
                      return _vm.$emit("next", true)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                    Agree and continue\n                "
                  )
                ]
              )
            ],
            1
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-md-row flex-column align-items-center justify-content-between mb-5"
      },
      [
        _c(
          "button",
          {
            staticClass:
              "btn-white-green-text mb-3 mb-md-0 mutable-mobile-width",
            on: {
              click: function($event) {
                return _vm.$emit("previous", true)
              }
            }
          },
          [_vm._v("\n            Back\n        ")]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 position-relative" }, [
      _c("div", { staticClass: "app-card-decoration" }),
      _vm._v(" "),
      _c("h1", {}, [_vm._v("Verify Your Identity")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "p",
        {
          staticClass: "app-card-intro",
          staticStyle: { "margin-bottom": "60px" }
        },
        [
          _vm._v(
            "\n                    My preference is not to submit a photo ID but rather\n                    provide Elepay with permission as follows:\n                "
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _vm._v(
          '\n                        "I confirm that I am authorised to provide the\n                        personal details presented and I consent to my\n                        information being checked with the document issuer\n                        or official record holder via third party systems\n                        for the purpose of confirming my identity"\n                    '
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }