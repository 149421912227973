var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "container-fluid d-flex justify-content-center mt-3",
        attrs: { id: "camera-div" }
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "margin",
            staticStyle: { position: "absolute" }
          },
          [
            _c("video", {
              attrs: {
                id: "video",
                width: "720",
                height: "560",
                autoplay: "",
                muted: "",
                playsinline: ""
              },
              domProps: { muted: true },
              on: { play: _vm.playInit }
            }),
            _vm._v(" "),
            _c("canvas", { attrs: { id: "overlay" } })
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "action-row" }, [
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-center align-items-center py-3 mx-3"
        },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass: "d-flex align-items-center justify-content-center"
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btn btn-primary mx-3",
                    attrs: { disabled: _vm.detecteds.length < 11 },
                    on: { click: _vm.takepicture }
                  },
                  [_vm._v("Capture Image and Submit\n                    ")]
                ),
                _vm._v(" "),
                _vm.detecteds.length < 11
                  ? _c("p", { staticClass: "text-danger mb-0" }, [
                      _vm._v(
                        "\n                        Face not detected\n                    "
                      )
                    ])
                  : _c("p", { staticClass: "text-success mb-0" }, [
                      _vm._v(
                        "\n                        Face Detected\n                    "
                      )
                    ])
              ],
              1
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }