<template>
    <div class="mk-application-form">

        <div class="container-fluid" style="padding-top: 20px;">
            <div class="row mb-4">
                <div class="col-12 elv-progress-bar">
                    <el-progress
                        class="w-100 pr-0 mr-0"
                        :percentage="progress"
                        :format="format"
                    ></el-progress>
                </div>
            </div>
        </div>

        <div class="container-fluid pb-5 px-0 px-sm-3" style="">
            <introduction
                v-if="step == 1"
                :step="step"
                key="introduction"
                @next="next"
            ></introduction>

            <create-lead
                key="lead"
                @next="next"
                @previous="before"
                @update-application="value => (application = value)"
                :step="step"
                :initial_application="application"
                :initial_supplier="supplier"
                :lead-form="leadForm"
                :fund-purposes="fundPurposes"
                v-else-if="step == 2"
            ></create-lead>

            <lead-identity
                key="identify"
                :step="step"
                @next="next"
                @previous="before"
                @update-application="value => (application = value)"
                :initial_application="application"
                :initial_supplier="supplier"
                v-else-if="step == 3"
            ></lead-identity>

            <verify-identity
                key="verify_identity"
                :step="step"
                @previous="before"
                @update-application="value => (application = value)"
                :initial_application="application"
                :initial_supplier="supplier"
                :link-to-face="linkToFace"
                v-else-if="
                    step == 4 &&
                        !application.skip_identity &&
                        linkToFace &&
                        !application.media
                            .filter(
                                m =>
                                    m.collection_name == 'face_proof' ||
                                    m.collection_name == 'documents'
                            )
                            .pop()
                "
            >
            </verify-identity>

            <step2-identity
                key="step2_identity"
                :step="step"
                @next="next"
                @previous="before"
                @update-application="value => (application = value)"
                :initial_application="application"
                :initial_supplier="supplier"
                :link-to-face="linkToFace"
                v-else-if="
                    step == 4 &&
                        linkToFace &&
                        !application.skip_identity &&
                        application.media
                            .filter(
                                m =>
                                    m.collection_name == 'face_proof' ||
                                    m.collection_name == 'documents'
                            )
                            .pop()
                "
            >
            </step2-identity>

            <skiping-identity
                v-else-if="step == 4 && application.skip_identity"
                @next="next"
                @previous="before"
            >
            </skiping-identity>

            <property
                key="property"
                :step="step"
                @next="next"
                @previous="before"
                @update-application="value => (application = value)"
                :initial_application="application"
                :initial_supplier="supplier"
                v-else-if="step == 5"
            >
            </property>

            <personal-declaration
                key="personal-declaration"
                :step="step"
                @next="next"
                @previous="before"
                @update-application="value => (application = value)"
                :initial_application="application"
                :initial_supplier="supplier"
                :income-ranges="incomeRanges"
                :employment-types="employmentTypes"
                :employment-durations="employmentDurations"
                v-else-if="step == 6"
            >
            </personal-declaration>

            <application-summary
                key="app-summary"
                :step="step"
                @next="next"
                @previous="before"
                :initial_application="application"
                :initial_supplier="supplier"
                v-else-if="step == 7"
            >
            </application-summary>

            <thank-you v-else-if="step == 8"></thank-you>
        </div>


    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        supplier: {
            type: Object,
            required: true
        },
        incomeRanges: {
            type: Array,
            required: true
        },
        employmentTypes: {
            type: Array,
            required: true
        },
        employmentDurations: {
            type: Array,
            required: true
        },
        leadForm: {
            type: Object,
            required: true
        },
        fundPurposes: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            step: 1,
            application: {
                dollar_expected_low: 0,
                dollar_expected_high: 0,
                date_listed: null,
                is_not_listed_as_sale: true,
                listing_agency: "",
                ownership_id: null,
                self_ownership: 1,
                listing_date_type: 1,
                lead: {
                    dollar_requested_amount: 0, // required
                    start_date: null,
                    sales_representative: null,
                    extra_attributes: {
                        address: {
                            formatted_address: null
                        }
                    },
                    other_purpose: null,
                    fund_purpose_ids: [],
                    fund_purposes: []
                },
                identifications: [],
                employments: [],
                creditWorthiness: [],
                media: [],
                customer: {
                    first_name: null, // required
                    last_name: null, // required
                    email: null, // required
                    phone: null, // required
                    date_of_birth: null,
                    middle_name: null,
                    extra_attributes: {
                        address: {
                            residential_address: {
                                formatted_address: null
                            }
                        }
                    }
                }
            }
        };
    },
    methods: {
        before() {
            if (this.step > 1) this.step--;
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        next() {
            this.step++;
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        format(percentage) {
            return "";
        }
    },
    computed: {
        ...mapGetters({
            linkToFace: "Identification/getLinkToFace"
        }),
        progress() {
            return ((this.step - 1) / 7) * 100;
        }
    }
};
</script>
