var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "container-fluid bg-white card application-card mk-pdeclaration"
        },
        [
          _c("div", { staticClass: "row" }, [
            _vm.form.errors.any()
              ? _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "show-form-error" },
                    _vm._l(_vm.form.errors.all(), function(errors, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "ul",
                          { staticClass: "mb-0" },
                          _vm._l(errors, function(error, i) {
                            return _c("li", { key: i }, [_vm._v(_vm._s(error))])
                          }),
                          0
                        )
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1)
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _vm._m(2),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "el-checkbox",
                  {
                    staticStyle: { "margin-bottom": "30px" },
                    attrs: { value: !!_vm.declaration.is_not_bankrupt },
                    on: {
                      change: function(value) {
                        return (_vm.declaration.is_not_bankrupt = value)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "I declare that I am not bankrupt or insolvent and I am not subject to any unsatisfied judgements\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-checkbox",
                  {
                    staticStyle: { "margin-bottom": "30px" },
                    attrs: {
                      value: !!_vm.declaration
                        .is_not_property_subject_to_changes
                    },
                    on: {
                      change: function(value) {
                        return (_vm.declaration.is_not_property_subject_to_changes = value)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "The property is not subject to any event (for example mortgage default) which would affect my (or the owners) ability to sell the property.\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "app-card-intro",
                    staticStyle: { "margin-bottom": "20px" }
                  },
                  [
                    _vm._v(
                      "If you were unable to tick both of the above boxes, simply tick this box below and one of our support crew will reach out to have a chat once we receive your application so we can understand more about your current situation."
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-checkbox",
                  {
                    staticStyle: { "margin-bottom": "60px" },
                    attrs: {
                      disabled: _vm.disableThirdOption,
                      value: !!_vm.declaration.speak_to_elevare_credit_analyst
                    },
                    on: {
                      change: function(value) {
                        return (_vm.declaration.speak_to_elevare_credit_analyst = value)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "Yes please contact me to discuss, thanks.\n\n                "
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _vm._m(3),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-6" },
              [
                _c("label", [_vm._v("Current Employer")]),
                _vm._v(" "),
                _c("el-input", {
                  class: {
                    "is-error": _vm.form.errors.has(
                      "employment.current_employer"
                    )
                  },
                  model: {
                    value: _vm.employment.current_employer,
                    callback: function($$v) {
                      _vm.$set(_vm.employment, "current_employer", $$v)
                    },
                    expression: "employment.current_employer"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-12 col-lg-6 d-flex flex-column has-dropdown-element"
              },
              [
                _c("label", [_vm._v("Duration of Employment")]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "margin-b-30",
                    class: {
                      "is-error": _vm.form.errors.has(
                        "employment.employment_duration_id"
                      )
                    },
                    model: {
                      value: _vm.employment.employment_duration_id,
                      callback: function($$v) {
                        _vm.$set(_vm.employment, "employment_duration_id", $$v)
                      },
                      expression: "employment.employment_duration_id"
                    }
                  },
                  _vm._l(_vm.employmentDurations, function(duration) {
                    return _c("el-option", {
                      key: duration.id,
                      attrs: { value: duration.id, label: duration.name }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-lg-6" },
              [
                _c("label", [_vm._v("Position")]),
                _vm._v(" "),
                _c("el-input", {
                  class: {
                    "is-error": _vm.form.errors.has("employment.position")
                  },
                  model: {
                    value: _vm.employment.position,
                    callback: function($$v) {
                      _vm.$set(_vm.employment, "position", $$v)
                    },
                    expression: "employment.position"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-12 col-lg-6 d-flex flex-column has-dropdown-element"
              },
              [
                _c("label", [_vm._v("Type of Employment")]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "margin-b-30",
                    class: {
                      "is-error": _vm.form.errors.has(
                        "employment.employment_type_id"
                      )
                    },
                    model: {
                      value: _vm.employment.employment_type_id,
                      callback: function($$v) {
                        _vm.$set(_vm.employment, "employment_type_id", $$v)
                      },
                      expression: "employment.employment_type_id"
                    }
                  },
                  _vm._l(_vm.employmentTypes, function(type) {
                    return _c("el-option", {
                      key: type.id,
                      attrs: { value: type.id, label: type.name }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-12 col-lg-6 d-flex flex-column has-dropdown-element",
                staticStyle: { "margin-bottom": "60px" }
              },
              [
                _c("label", [_vm._v("Gross Annual Income")]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    class: {
                      "is-error": _vm.form.errors.has(
                        "employment.income_range_id"
                      )
                    },
                    model: {
                      value: _vm.employment.income_range_id,
                      callback: function($$v) {
                        _vm.$set(_vm.employment, "income_range_id", $$v)
                      },
                      expression: "employment.income_range_id"
                    }
                  },
                  _vm._l(_vm.incomeRanges, function(incomeRange) {
                    return _c("el-option", {
                      key: incomeRange.id,
                      attrs: { value: incomeRange.id, label: incomeRange.name }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _vm._m(4),
            _vm._v(" "),
            _vm._m(5),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btn btn-primary mutable-mobile-width",
                    staticStyle: { "margin-bottom": "30px" },
                    attrs: { disabled: !_vm.canProgress },
                    on: {
                      click: function($event) {
                        _vm.openTCModal = true
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                    View Terms & Conditions\n                "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-md-row flex-column align-items-center justify-content-between mb-5 px-3 px-md-0"
        },
        [
          _c(
            "button",
            {
              staticClass:
                "btn-white-green-text mb-3 mb-md-0 mutable-mobile-width",
              on: {
                click: function($event) {
                  return _vm.$emit("previous", true)
                }
              }
            },
            [_vm._v("Back")]
          )
        ]
      ),
      _vm._v(" "),
      _c("terms-and-conditions-modal", {
        attrs: { "open-modal": _vm.openTCModal },
        on: {
          "update:openModal": function($event) {
            _vm.openTCModal = $event
          },
          "update:open-modal": function($event) {
            _vm.openTCModal = $event
          },
          accepted: _vm.save
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 position-relative" }, [
      _c("div", { staticClass: "app-card-decoration" }),
      _vm._v(" "),
      _c("h1", [_vm._v("Personal Declaration")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "p",
        {
          staticClass: "app-card-intro",
          staticStyle: { "margin-bottom": "60px" }
        },
        [
          _vm._v(
            "Here are a few basic questions which provide us with an insight into your current financial position."
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "h3",
        {
          staticClass: "text-blue font-heading",
          staticStyle: { "margin-bottom": "30px" }
        },
        [_vm._v("Credit Declaration")]
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v("Please read the following statements and tick both to agree.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "h3",
        {
          staticClass: "text-blue font-heading",
          staticStyle: { "margin-bottom": "30px" }
        },
        [_vm._v("Employment Details")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "h3",
        {
          staticClass: "text-blue font-heading",
          staticStyle: { "margin-bottom": "30px" }
        },
        [_vm._v("Terms & Conditions")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "p",
        {
          staticClass: "app-card-intro",
          staticStyle: { "margin-bottom": "30px" }
        },
        [
          _vm._v(
            "Before we proceed to the final application submission, we want to be sure that you’ve read our terms and conditions, and that you are happy and comfortable with all the provisions in this policy."
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }