var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "container-fluid bg-white card application-card mk-thankyou"
        },
        [
          _c("div", { staticClass: "row" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("p", [
                _vm._v(
                  "\n                    Make sure your face and photo ID are clearly visible in\n                    the frame. Click\n                    "
                ),
                _c(
                  "a",
                  {
                    staticClass: "help-link",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.openModal = true
                      }
                    }
                  },
                  [_vm._v("here")]
                ),
                _vm._v(
                  "\n                    to view the verification photo requirements again.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 mt-3 col-lg-8" }, [
              _c(
                "div",
                { staticClass: "d-flex flex-column align-items-center" },
                [
                  _vm.image
                    ? _c("img", {
                        key: _vm.image.url,
                        staticClass: "img-fluid",
                        attrs: { src: _vm.image.url }
                      })
                    : _vm._e()
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-4 col-12 p-3" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center flex-column"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    ref: "file",
                    attrs: { type: "file" },
                    on: { change: _vm.uploadFiles }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn btn-primary w-100 my-2",
                      on: { click: _vm.openFile }
                    },
                    [
                      _c("i", {
                        staticClass: "fas fa-cloud-upload-alt mr-2 text-white"
                      }),
                      _vm._v(
                        "\n                        Select another photo\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isMobile
                    ? _c("span", [
                        _vm._v(
                          "\n                        or\n                    "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isMobile
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-primary w-100 my-2",
                          attrs: { href: "#", target: "_blank" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openFace($event)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-camera mr-2 text-white"
                          }),
                          _vm._v(
                            "\n                        Retake Photo\n                    "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v("\n\n                    or\n\n                    "),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn btn-primary w-100 my-2",
                      on: { click: _vm.done }
                    },
                    [
                      _c("i", {
                        staticClass: "fas fa-check-circle mr-2 text-white"
                      }),
                      _vm._v(
                        "\n                        I'm happy with my photo\n                    "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-md-row flex-column align-items-center justify-content-between mb-5"
        },
        [
          _c(
            "button",
            {
              staticClass:
                "btn-white-green-text mb-3 mb-md-0 mutable-mobile-width",
              on: {
                click: function($event) {
                  return _vm.$emit("previous", true)
                }
              }
            },
            [_vm._v("\n            Back\n        ")]
          )
        ]
      ),
      _vm._v(" "),
      _c("upload-rule", {
        attrs: { "open-modal": _vm.openModal },
        on: {
          "update:openModal": function($event) {
            _vm.openModal = $event
          },
          "update:open-modal": function($event) {
            _vm.openModal = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 position-relative" }, [
      _c("div", { staticClass: "app-card-decoration" }),
      _vm._v(" "),
      _c("h1", {}, [_vm._v("Verify Your Identity")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 mt-3" }, [
      _c(
        "h3",
        {
          staticStyle: {
            "margin-bottom": "60px",
            "font-family": "aktiv-grotesk-extended, sans-serif"
          }
        },
        [
          _vm._v(
            "\n                    Step 2: Review your photo\n                "
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }