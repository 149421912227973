<template>
    <div>
        <div
            id="camera-div"
            class="container-fluid d-flex justify-content-center mt-3"
        >
            <div class="margin" style="position: absolute;" v-loading="loading">
                <video
                    id="video"
                    width="720"
                    height="560"
                    autoplay
                    muted
                    playsinline
                    @play="playInit"
                ></video>
                <canvas id="overlay" />
            </div>
        </div>
        <div class="action-row">
            <div
                class="d-flex justify-content-center align-items-center py-3 mx-3"
            >
                <div class="container">
                    <div
                        class="d-flex align-items-center justify-content-center"
                    >
                        <!-- <el-button @click="openFile"
                            ><i class="fas fa-upload"></i> Upload Files ({{
                                files.length
                            }}
                            Files)
                        </el-button>
                        <input
                            type="file"
                            v-show="false"
                            multiple
                            ref="file"
                            @change="uploadFiles"
                        /> -->
                        <!-- disabled button if detection less than 11 -->

                        <el-button
                            @click="takepicture"
                            class="btn btn-primary mx-3"
                            :disabled="detecteds.length < 11"
                            >Capture Image and Submit
                        </el-button>
                        <p
                            v-if="detecteds.length < 11"
                            class="text-danger mb-0"
                        >
                            Face not detected
                        </p>
                        <p v-else class="text-success mb-0">
                            Face Detected
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    createCanvasFromMedia,
    detectAllFaces,
    resizeResults,
    TinyFaceDetectorOptions,
    draw,
    matchDimensions,
    nets,
    detectSingleFace,
    SsdMobilenetv1Options,
    MtcnnOptions,
    tinyFaceDetector,
    isWithFaceLandmarks
} from "face-api.js";
import axios from "axios";
export default {
    props: {
        supplier: {
            required: true,
            type: Object
        },
        application: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            video: null,
            files: [],
            loading: true,
            detecteds: [],
            isMobile: false
        };
    },
    async mounted() {
        this.video = document.getElementById("video");
        Promise.all([
            nets.tinyFaceDetector.loadFromUri("/models"),
            nets.faceRecognitionNet.loadFromUri("/models"),
            nets.ssdMobilenetv1.loadFromUri("/models")
        ]).then(this.startVideo());

        if (window.matchMedia("screen and (max-width: 768px)").matches)
            this.isMobile = true;
    },
    methods: {
        openFile() {
            this.$refs.file.click();
        },
        startVideo() {
            navigator.mediaDevices
                .getUserMedia({ video: {} })
                .then(stream => {
                    this.video.srcObject = stream;
                })
                .catch(err => {
                    console.error(err);
                });
        },
        playInit() {
            // displaySize will help us to match the dimension with video screen and accordingly it will draw our detections
            // on the streaming video screen
            const displaySize = {
                width: this.video.width,
                height: this.video.height
            };

            const canvas = document.getElementById("overlay");

            const dims = matchDimensions(canvas, this.video, true);
            const { width } = dims;
            const boundingX = width / 2 - 50;

            let ctx = canvas.getContext("2d");

            /**
             * flipping direction
             * x change
             * dimensions same
             * y same
             */

            // draw rectangle for face
            if (!this.isMobile) this.drawBoxesLeft(ctx, canvas);

            setInterval(async () => {
                const detection = await detectSingleFace(
                    this.video,
                    new TinyFaceDetectorOptions({
                        inputSize: 128,
                        scoreThreshold: 0.55
                    })
                );

                if (detection) {
                    const topRight = detection.box.topRight;
                    // console.log("top right");
                    // console.log(topRight);

                    if (!this.isMobile) {
                        if (topRight.x > boundingX)
                            this.drawBoxesLeft(ctx, canvas);
                        else this.drawBoxesRight(ctx, canvas);
                    }

                    // get ready to flip
                    // draw.drawDetections(canvas, resizeResults(detection, dims));
                    if (this.detecteds.length <= 10)
                        this.detecteds.push(detection);
                }
                this.loading = false;
            }, 100);
        },
        drawBoxesLeft(ctx, canvas) {
            // clear all first
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            ctx.beginPath();
            ctx.lineWidth = "3";
            ctx.strokeStyle = "red";
            ctx.rect(100, 100, 240, 300);

            ctx.stroke();

            ctx.font = "20px Arial";
            ctx.fillStyle = "red";
            ctx.fillText("Your face here", 120, 125);

            // draw rectangle for id
            ctx.beginPath();
            ctx.lineWidth = "3";
            ctx.strokeStyle = "green";
            ctx.rect(400, 200, 225, 170);
            ctx.stroke();

            ctx.font = "20px Arial";
            ctx.fillStyle = "green";
            ctx.fillText("Your ID here", 420, 225);
        },
        drawBoxesRight(ctx, canvas) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            ctx.beginPath();
            ctx.lineWidth = "3";
            ctx.strokeStyle = "red";
            ctx.rect(400, 100, 240, 300);
            ctx.stroke();

            ctx.font = "20px Arial";
            ctx.fillStyle = "red";
            ctx.fillText("Your face here", 420, 125);

            // draw rectangle for id
            ctx.beginPath();
            ctx.lineWidth = "3";
            ctx.strokeStyle = "green";
            ctx.rect(100, 200, 225, 170);
            ctx.stroke();

            ctx.font = "20px Arial";
            ctx.fillStyle = "green";
            ctx.fillText("Your ID here", 120, 225);
        },
        uploadFiles(e) {
            let files = e.target.files;
            let local_files = [];
            for (let i = 0; i < files.length; i++) {
                local_files.push(files[i]);
            }

            this.files = local_files;
        },
        async takepicture() {
            const canvas = document.getElementById("overlay");
            const context = canvas.getContext("2d");

            context.drawImage(this.video, 0, 0, canvas.width, canvas.height);

            var data = canvas.toDataURL("image/png");
            // photo.setAttribute("src", data);
            // console.log(data);
            let form = new FormData();
            form.append("type", "face");
            form.append("face", data);
            this.files.forEach(file => {
                form.append("files[]", file);
            });

            try {
                const res = await axios.post(
                    `/api/v1/customer/suppliers/${this.supplier.id}/applications/${this.application.id}/medias`,
                    form,
                    {
                        headers: { "content-type": "multipart/form-data" }
                    }
                );
                this.$message({
                    type: "success",
                    message: "Upload Success"
                });
                // break before exit
                setTimeout(() => {
                    // window.opener.
                    window.close();
                }, 200);
            } catch (err) {
                console.error(err);
                var errorText = "";
                _.forEach(err.response.data.errors, function(value) {
                    errorText += "" + value + " ";
                });
                this.$message({
                    type: "error",
                    message: errorText
                });
            }
        }
    }
};
</script>

<style>
#overlay,
.overlay {
    position: absolute;
    top: 0;
    left: 0;
}
video {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.action-row {
    position: fixed;
    bottom: 0px;
    z-index: 100000;
    left: 0px;
    background: #fff;
    width: 100%;
}
</style>
