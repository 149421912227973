<template>
    <div class="mk-be-dash-appDashboard">
        <div class="row">
            <div class="col">
                <h3>Applications</h3>
            </div>
        </div>
        <div class="row mk-be-dashboard">
            <div class="col-12">
                <paginated-table
                    v-if="applications.data"
                    :tableData="tableData"
                    :paginatedData="applications"
                    :initial_page="5"
                    @pagination-change-page="getData"
                    @on-view="open"
                >
                    <template slot="after-search-slot">
                        <div
                            class="d-flex justify-content-end align-items-center ml-3"
                        >
                            <el-select
                                v-model="supplierId"
                                filterable
                                remote
                                reserve-keyword
                                class="mr-3"
                                clearable
                                placeholder="Type in supplier name"
                                :remote-method="getSuppliers"
                                :loading="loading"
                                @clear="clearSupplier"
                                @change="getData(1)"
                            >
                                <el-option
                                    v-for="item in suppliers.data"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>

                            <el-date-picker
                                v-model="dateRange"
                                @change="getData(1)"
                                type="daterange"
                                range-separator="-"
                                format="dd/MM/yyyy"
                                value-format="yyyy-MM-dd"
                                start-placeholder="Start date"
                                end-placeholder="End date"
                            >
                            </el-date-picker>
                        </div>
                    </template>
                </paginated-table>
            </div>
        </div>
    </div>
</template>
<script>
import Application from "../../models/Application";
import Supplier from "../../models/Supplier";
export default {
    data() {
        return {
            applications: {},
            suppliers: {},
            page: 1,
            loading: false,
            supplierId: null,
            search: null,
            dateRange: null,
            tableData: {
                columns: [
                    {
                        label: "First Name",
                        name: "customer.first_name",
                        sortable: false
                    },
                    {
                        label: "Last Name",
                        name: "customer.last_name",
                        sortable: false
                    },
                    { label: "Email", name: "customer.email", sortable: false },
                    { label: "Phone", name: "customer.phone", sortable: false },
                    {
                        label: "Supplier",
                        name: "supplier.name",
                        sortable: false
                    },
                    {
                        label: "Source",
                        name: "lead.source",
                        badge: true,
                        badge_coloring: value => {
                            return value.lead && value.lead.source
                                ? "#18206F"
                                : "#FF968D";
                        },
                        text_color: "#fff",
                        conditional_value: value => {
                            return value.lead && value.lead.source
                                ? "elepay"
                                : "Fast Track";
                        },
                        sortable: false
                    },
                    {
                        label: "Status",
                        name: "application_status.status",
                        sortable: false
                    },

                    {
                        label: "Created At",
                        name: "created_at",
                        datetime: true,
                        sortable: true
                    },
                    {
                        label: "Estimated Start Date",
                        name: "lead.start_date",
                        whole_date: true,
                        sortable: false
                    }
                ],
                edit: true,
                showSearch: true,
                showPerPage: true,
                overrideView: true
            }
        };
    },
    async mounted() {
        this.getSuppliers("");
        this.getData(1);
    },
    methods: {
        open(event) {
            window.location.href = `/admin/suppliers/${event.supplier_id}/applications/${event.id}/edit`;
        },
        clearSupplier() {
            this.supplierId = null;
            this.getData(1);
        },
        async getSuppliers(query) {
            try {
                const suppliers = await new Supplier()
                    .where("name", query)
                    .page(1)
                    .get();
                this.suppliers = suppliers;
            } catch (err) {
                console.error(err);
            }
        },
        getData(
            page = 1,
            sortColumn = null,
            query = null,
            per_page = 5,
            desc = false
        ) {
            this.page = page ? page : 1;
            let applications = Application.page(this.page)
                .include("customer", "supplier", "application_status", "lead")

                .params({
                    per_page: per_page
                });
            if (sortColumn) {
                applications.orderBy(
                    (desc ? "-" : "") +
                        (sortColumn.sort_name || sortColumn.name)
                );
            } else {
                applications.orderBy("-created_at");
            }

            if (query) applications.where("where_like", query);
            if (this.supplierId)
                applications.where("supplier_id", this.supplierId);

            if (this.dateRange)
                applications.where("created_between", this.dateRange.join(","));

            applications
                .get()
                .then(res => {
                    this.loading = false;
                    this.applications = res;
                })
                .catch(errors => {
                    this.loading = false;
                    console.error(errors);
                });
        }
    }
};
</script>
