<template>
    <el-dialog
        title="Send Email"
        :visible.sync="open"
        width="30%"
        v-loading="sendLoading"
    >
        <div class="row">
            <div class="col-12 d-flex justify-content-between flex-column">
                <label>
                    Email Template
                </label>
                <el-select
                    v-model="selectedTemplate"
                    filterable
                    remote
                    reserve-keyword
                    class="w-100"
                    placeholder="Search Template"
                    :remote-method="getTemplates"
                    :loading="loading"
                >
                    <el-option
                        v-for="template in templates"
                        :key="template.id"
                        :label="template.name"
                        :value="template.id"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="col-12 mt-3">
                <label>
                    Send To
                </label>
                <div class="d-flex justify-content-between">
                    <el-checkbox
                        label="Elevare Admins"
                        class="mr-2"
                        v-model="sendToAdmin"
                    ></el-checkbox>
                    <el-checkbox
                        label="Agency"
                        class="mr-2"
                        v-model="sendToSupplier"
                    ></el-checkbox>
                    <el-checkbox
                        label="Customer"
                        class="mr-2"
                        v-model="sendToCustomer"
                    ></el-checkbox>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="open = false">Cancel</el-button>
            <el-button type="primary" @click="send">Confirm</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Email from "@/backend/models/Email";
import Supplier from "@/backend/models/Supplier";
import EmailTemplate from "@/backend/models/EmailTemplate";
import Lead from "../../../models/Lead";
export default {
    props: {
        supplier: {
            type: Object,
            required: true
        },
        lead: {
            type: Object,
            required: true
        },
        openModal: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            open: false,
            templates: [],
            loading: false,
            selectedTemplate: null,
            sendToAdmin: false,
            sendToSupplier: false,
            sendToCustomer: false,
            sendLoading: false
        };
    },
    mounted() {
        this.getTemplates();
    },
    methods: {
        async getTemplates(query = "") {
            try {
                const res = await new EmailTemplate()
                    .for(new Supplier(this.supplier))

                    .orderBy("name")
                    .where("where_like", query)
                    .where("where_flow", 1) // query only email template for lead
                    .page(1)
                    .get();
                this.loading = false;
                this.templates = res.data;
            } catch (errors) {
                this.loading = false;
                console.error(errors);
            }
        },
        async send() {
            try {
                this.sendLoading = true;
                const res = await new Email({
                    email_template_id: this.selectedTemplate,
                    email_rule: {
                        send_to_admin: this.sendToAdmin || false,
                        send_to_supplier: this.sendToSupplier || false,
                        send_to_customer: this.sendToCustomer || false
                    }
                })
                    .for(new Supplier(this.supplier), new Lead(this.lead))
                    .save();

                console.log(res);
                this.sendLoading = false;
                this.open = false;
            } catch (err) {
                console.error(err);
                this.sendLoading = false;
            }
        }
    },

    watch: {
        openModal(to, from) {
            this.open = to;
        },
        open(to, from) {
            this.$emit("update:openModal", to);
        }
    }
};
</script>
