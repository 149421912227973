<template>
    <div class="container-fluid px-0 mb-5" v-loading="loading">
        <div class="row mb-3">
            <div class="col-8 d-flex justify-content-between">
                <h1>Application</h1>
            </div>
            <div class="col-4 d-flex justify-content-end">
                <button class="btn btn-primary mr-3" @click="downloadPdf">
                    <i class="fas fa-file-pdf mr-2 text-white"></i> Download PDF
                </button>
                <button class="btn btn-primary" @click="save">Save</button>
            </div>
        </div>
        <div class="row px-3">
            <div class="col-12 py-3 bg-white">
                <div class="row">
                    <div class="col-12">
                        <label>
                            Application Status
                        </label>
                        <el-select
                            v-model="application.application_status_id"
                            class="w-100"
                        >
                            <el-option
                                v-for="status in applicationStatuses"
                                :key="status.id"
                                :value="status.id"
                                :label="status.status"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row px-3">
            <div class="col-12 py-3 bg-white">
                <lead
                    key="lead"
                    :initial_application="application"
                    :initial_supplier="supplier"
                ></lead>
                <identity
                    key="identify"
                    :initial_application="application"
                    :initial_supplier="supplier"
                ></identity>
                <property
                    key="property"
                    :initial_application="application"
                    :initial_supplier="supplier"
                >
                </property>
                <personal-declaration
                    key="personal-declaration"
                    :initial_application="application"
                    :initial_supplier="supplier"
                    :income-ranges="incomeRanges"
                    :employment-types="employmentTypes"
                    :employment-durations="employmentDurations"
                >
                </personal-declaration>
                <application-summary
                    key="app-summary"
                    :initial_application="application"
                    :initial_supplier="supplier"
                >
                </application-summary>
            </div>
        </div>

        <email-queue-index
            :supplier="supplier"
            :application="application"
        ></email-queue-index>
    </div>
</template>
<script>
import Application from "../../models/Application";
import Supplier from "../../models/Supplier";
import Pdf from "@/backend/models/Pdf";
export default {
    props: {
        supplier: {
            type: Object
        },
        initial_application: {
            type: Object
        },
        incomeRanges: {
            type: Array,
            required: true
        },
        employmentTypes: {
            type: Array,
            required: true
        },
        employmentDurations: {
            type: Array,
            required: true
        },
        applicationStatuses: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            application: this.initial_application,
            loading: false
        };
    },
    methods: {
        async downloadPdf() {
            try {
                this.loading = true;
                const res = await new Pdf({})
                    .for(
                        new Supplier(this.supplier),
                        new Application(this.application)
                    )
                    .save();
                this.loading = false;
                window.open(res.url, "blank");
            } catch (err) {
                this.loading = false;
                console.error(err);
            }
        },
        async save() {
            try {
                const res = await new Application(this.application)
                    .for(new Supplier(this.supplier))
                    .save();
                this.$message({
                    type: "success",
                    message: "Application Saved"
                });
            } catch (err) {
                console.error(err);
            }
        }
    }
};
</script>
