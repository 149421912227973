var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "container-fluid bg-white card application-card mk-property"
      },
      [
        _c("div", { staticClass: "row" }, [
          _vm.form.errors.any()
            ? _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "show-form-error" },
                  _vm._l(_vm.form.errors.all(), function(errors, index) {
                    return _c("div", { key: index }, [
                      _c(
                        "ul",
                        { staticClass: "mb-0" },
                        _vm._l(errors, function(error, i) {
                          return _c("li", { key: i }, [_vm._v(_vm._s(error))])
                        }),
                        0
                      )
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1)
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row", staticStyle: { "margin-bottom": "60px" } },
          [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("label", { attrs: { for: "property_address" } }, [
                  _vm._v("Property Address")
                ]),
                _vm._v(" "),
                _c("el-input", {
                  class: {
                    "is-error": _vm.form.errors.has(
                      "lead.extra_attributes.address.formatted_address"
                    )
                  },
                  model: {
                    value:
                      _vm.application.lead.extra_attributes.address
                        .formatted_address,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.application.lead.extra_attributes.address,
                        "formatted_address",
                        $$v
                      )
                    },
                    expression:
                      "\n                        application.lead.extra_attributes.address\n                            .formatted_address\n                    "
                  }
                })
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-lg-8 d-flex flex-column" }, [
            _c("label", { attrs: { for: "date_listed" } }, [
              _vm._v(_vm._s(_vm.currentDateName))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "w-100 mr-3",
                    class: {
                      "is-error": _vm.form.errors.has("listing_date_type")
                    },
                    model: {
                      value: _vm.application.listing_date_type,
                      callback: function($$v) {
                        _vm.$set(_vm.application, "listing_date_type", $$v)
                      },
                      expression: "application.listing_date_type"
                    }
                  },
                  _vm._l(_vm.listingDateTypes, function(type) {
                    return _c("el-option", {
                      key: type.id,
                      attrs: { value: type.id, label: type.name }
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("el-date-picker", {
                  staticClass: "w-100",
                  class: { "is-error": _vm.form.errors.has("date_listed") },
                  attrs: {
                    type: "date",
                    format: "dd/MM/yyyy",
                    "value-format": "yyyy-MM-dd"
                  },
                  model: {
                    value: _vm.application.date_listed,
                    callback: function($$v) {
                      _vm.$set(_vm.application, "date_listed", $$v)
                    },
                    expression: "application.date_listed"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-4 d-flex justify-content-start justify-content-lg-end align-items-center"
            },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { value: !!_vm.application.is_not_listed_as_sale },
                  on: {
                    change: function(value) {
                      return (_vm.application.is_not_listed_as_sale = value)
                    }
                  }
                },
                [_vm._v("Property is not being listed for sale")]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        !_vm.application.is_not_listed_as_sale
          ? _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("label", { attrs: { for: "sales_representative" } }, [
                    _vm._v("Listing Agency")
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    class: {
                      "is-error": _vm.form.errors.has("listing_agency")
                    },
                    attrs: {
                      placeholder: "Enter Listing Agent",
                      disabled: !_vm.canFill
                    },
                    model: {
                      value: _vm.application.listing_agency,
                      callback: function($$v) {
                        _vm.$set(_vm.application, "listing_agency", $$v)
                      },
                      expression: "application.listing_agency"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-lg-6" },
                [
                  _c("label", { attrs: { for: "expected_low" } }, [
                    _vm._v("Expected Low Range Price")
                  ]),
                  _vm._v(" "),
                  _c("currency-input", {
                    staticClass: "el-input__inner",
                    class: { "is-error": _vm.form.errors.has("expected_low") },
                    attrs: { disabled: !_vm.canFill },
                    on: {
                      change: function(value) {
                        return (_vm.application.expected_low = value * 100)
                      }
                    },
                    model: {
                      value: _vm.application.dollar_expected_low,
                      callback: function($$v) {
                        _vm.$set(_vm.application, "dollar_expected_low", $$v)
                      },
                      expression: "application.dollar_expected_low"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-lg-6" },
                [
                  _c("label", { attrs: { for: "expected_low" } }, [
                    _vm._v("Expected High Range Price")
                  ]),
                  _vm._v(" "),
                  _c("currency-input", {
                    staticClass: "el-input__inner",
                    class: { "is-error": _vm.form.errors.has("expected_high") },
                    attrs: { disabled: !_vm.canFill },
                    on: {
                      change: function(value) {
                        return (_vm.application.expected_high = value * 100)
                      }
                    },
                    model: {
                      value: _vm.application.dollar_expected_high,
                      callback: function($$v) {
                        _vm.$set(_vm.application, "dollar_expected_high", $$v)
                      },
                      expression: "application.dollar_expected_high"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _vm._m(3),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "el-radio",
                {
                  staticStyle: { "margin-bottom": "30px" },
                  attrs: { label: 1 },
                  model: {
                    value: _vm.application.self_ownership,
                    callback: function($$v) {
                      _vm.$set(_vm.application, "self_ownership", $$v)
                    },
                    expression: "application.self_ownership"
                  }
                },
                [
                  _c("span", { staticClass: "font-16 text-black" }, [
                    _vm._v(
                      "I am the registered owner of the property and my name appears on the current title deed which is held with the Australian Titles Office."
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  staticClass: "d-flex",
                  attrs: { label: 2 },
                  model: {
                    value: _vm.application.self_ownership,
                    callback: function($$v) {
                      _vm.$set(_vm.application, "self_ownership", $$v)
                    },
                    expression: "application.self_ownership"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column has-dropdown-element" },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-16 text-black",
                          staticStyle: { "margin-bottom": "10px" }
                        },
                        [
                          _vm._v(
                            "No I AM NOT the registered owner of the property, however my relationship with the registered owner is as follows:"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.ownerships.length
                        ? _c(
                            "el-select",
                            {
                              class: {
                                "is-error": _vm.form.errors.has("ownership_id")
                              },
                              staticStyle: { width: "210px" },
                              model: {
                                value: _vm.application.ownership_id,
                                callback: function($$v) {
                                  _vm.$set(_vm.application, "ownership_id", $$v)
                                },
                                expression: "application.ownership_id"
                              }
                            },
                            _vm._l(_vm.ownerships, function(ownership) {
                              return _c("el-option", {
                                key: ownership.id,
                                attrs: {
                                  value: ownership.id,
                                  label: ownership.name
                                }
                              })
                            }),
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-md-row flex-column align-items-center justify-content-between mb-5 px-3 px-md-0"
      },
      [
        _c(
          "button",
          {
            staticClass:
              "btn-white-green-text mutable-mobile-width order-2 order-md-1",
            on: {
              click: function($event) {
                return _vm.$emit("previous", true)
              }
            }
          },
          [_vm._v("\n            Back\n        ")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass:
              "btn btn-primary mutable-mobile-width order-1 order-md-2 mb-3 mb-md-0",
            on: { click: _vm.save }
          },
          [_vm._v("Confirm and continue")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 position-relative" }, [
      _c("div", { staticClass: "app-card-decoration" }),
      _vm._v(" "),
      _c("h1", [_vm._v("Your Property")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "app-card-intro" }, [
        _vm._v(
          "\n                    This is the property where the funds provided will be\n                    invested.\n                "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "h3",
          {
            staticClass: "text-blue font-heading",
            staticStyle: { "margin-bottom": "20px" }
          },
          [_vm._v("\n                    Listing Details\n                ")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "app-card-intro" }, [
          _vm._v(
            "Please provide us with details for when your property is being listed for sale on the market"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-12", staticStyle: { "margin-top": "60px" } },
      [
        _c(
          "h3",
          {
            staticClass: "text-blue font-heading",
            staticStyle: { "margin-bottom": "20px" }
          },
          [_vm._v("Ownership Declaration")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "app-card-intro" }, [
          _vm._v("Please select the ownership statement that applies to you.")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }