<template>
    <div class="container-fluid px-0 mb-5">
        <div class="row my-3">
            <div class="col-12 d-flex justify-content-between">
                <h1>
                    Plan variation
                </h1>
            </div>
        </div>

        <div class="row px-3">
            <div class="col-12 py-3 bg-white px-5">
                <div class="row">
                    <div class="col-md-3 my-3">
                        First Name
                    </div>
                    <div class="col-md-9 my-3">
                        {{ application.customer.first_name }}
                    </div>
                    <div class="col-md-3 my-3">
                        Last Name
                    </div>
                    <div class="col-md-9 my-3">
                        {{ application.customer.last_name }}
                    </div>
                    <div class="col-md-3 my-3">
                        Authorised Supplier
                    </div>
                    <div class="col-md-9 my-3">
                        {{ application.supplier.name }}
                    </div>

                    <div class="col-md-3 my-3">
                        Requested Plan Amount
                    </div>
                    <div class="col-md-9 my-3">
                        {{ application.lead.dollar_requested_amount }}
                    </div>
                </div>

                <div
                    class="row my-5 py-3 w-50"
                    style="
                        border-top: 1px; 
                        border-top-style:solid; 
                        border-top-color: #FF4E00; 
                        border-bottom: 1px; 
                        border-bottom-style:solid; 
                        border-bottom-color: #FF4E00;
                    "
                >
                    <div class="form-group col-md-3 col-3 my-3">
                        <p class="mb-0">
                            Revised Plan Amount
                        </p>
                    </div>
                    <div class="form-group col-md-9 col-9 my-3">
                        <el-input
                            placeholder="Revised Plan Amount"
                            v-model="planVariation.dollar_revised_plan_amount"
                            disabled
                        ></el-input>
                    </div>

                    <div class="form-group col-md-3 col-3 my-3">
                        <p class="mb-0">
                            Revised Plan Date
                        </p>
                    </div>
                    <div class="form-group col-md-9 col-9 my-3">
                        <el-date-picker
                            placeholder="Revised Plan Date"
                            type="date"
                            format="dd/MM/yyyy"
                            value-format="yyyy-MM-dd"
                            class="w-100"
                            v-model="planVariation.revised_start_date"
                            disabled
                        ></el-date-picker>
                    </div>
                </div>

                <div class="row w-100 py-3">
                    <div class="col-12">
                        <p>
                            Property Owners Payment Schedule
                        </p>
                    </div>

                    <div class="col-12 col-md-4">
                        <pay-before-card
                            :key="starterKey"
                            paydayCardBg="rgb(249, 249, 249)"
                            :payBeforeDays="45"
                            payBeforeFee="2.0%"
                            :payBeforeHighlightFee="true"
                            payBeforeInterest="0.0%"
                            :payBeforeAmountPayable="getTotalPayable(2, 0)"
                            :due-date="getDueDate(44)"
                        ></pay-before-card>
                    </div>

                    <div class="col-12 col-md-4">
                        <pay-before-card
                            :key="starterKey"
                            paydayCardBg="rgba(255, 78, 0, 0.03)"
                            :payBeforeDays="60"
                            payBeforeFee="5.0%"
                            :payBeforeHighlightFee="false"
                            payBeforeInterest="0.0%"
                            :payBeforeAmountPayable="getTotalPayable(5, 0)"
                            :due-date="getDueDate(59)"
                        >
                        </pay-before-card>
                    </div>

                    <div class="col-12 col-md-4 schedule-mobile-card">
                        <pay-before-card
                            :key="starterKey"
                            paydayCardBg="rgba(255, 78, 0, 0.12)"
                            :payBeforeDays="107"
                            payBeforeFee="5.0%"
                            :payBeforeHighlightFee="false"
                            payBeforeInterest="0.9%"
                            :payBeforeAmountPayable="getTotalPayable(5, 0.9)"
                            :payBeforeIsFinalCard="true"
                            :due-date="getDueDate(107)"
                        >
                        </pay-before-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Supplier from "@/frontend/supplier/models/Supplier";
import PlanVariation from "@/frontend/supplier/models/PlanVariation";
import PayBeforeCard from "@/frontend/views/applications/components/PayBeforeCard";
export default {
    components: {
        PayBeforeCard
    },
    props: {
        supplier: {
            type: Object,
            required: true
        },
        application: {
            type: Object,
            required: true
        },
        initial_plan_variation: {
            type: Object,
            required: false,
            default: () => {
                return {
                    revised_plan_amount: 0,
                    dollar_revised_plan_amount: 0,
                    revised_start_date: null
                };
            }
        }
    },
    data() {
        return {
            starterKey: 0,
            loading: false,
            planVariation: this.initial_plan_variation
        };
    },
    mounted() {},
    methods: {
        /**
         * @param {number} interest
         * @param {number} fee
         *
         * @return {string}
         */
        getTotalPayable(interest, fee) {
            const totalPercentage = interest + fee;
            let amount =
                this.planVariation.dollar_revised_plan_amount ||
                this.application.lead.dollar_requested_amount;

            return Number(amount * (totalPercentage / 100 + 1)).toLocaleString(
                "en-AU",
                {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }
            );
        },
        /**
         * @param {number} addDay
         * @return {string}
         */
        getDueDate(addDay) {
            let startDay = new Date(
                this.planVariation.revised_start_date ||
                    this.application.lead.start_date
            );
            return moment(
                new Date(startDay.setDate(startDay.getDate() + addDay))
            ).format("ddd, Do MMMM YYYY");
        }
    },
    watch: {
        planVariation: {
            handler(to, from) {
                this.starterKey += 1; // make components recompile
            },
            deep: true
        }
    }
};
</script>
