var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "div",
        {
          staticClass:
            "container-fluid bg-white card application-card mk-thankyou"
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 position-relative" }, [
              _c("div", { staticClass: "app-card-decoration" }),
              _vm._v(" "),
              _c("h1", {}, [_vm._v("Thank You!")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c(
                "p",
                {
                  staticClass: "app-card-intro",
                  staticStyle: { "margin-bottom": "60px" }
                },
                [
                  _vm._v(
                    "Thank you for providing your details, our team will be in contact with you very shortly"
                  )
                ]
              ),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Please keep an eye on your email for our next piece of correspondence, which you should receive shortly. Be sure to add us to your approved contacts to prevent our emails going into your junk mail."
                )
              ]),
              _vm._v(" "),
              _c("p", { staticStyle: { "margin-bottom": "30px" } }, [
                _vm._v("Thank you for your time.")
              ]),
              _vm._v(" "),
              _c(
                "h3",
                {
                  staticClass: "text-blue font-heading",
                  staticStyle: { "margin-bottom": "30px" }
                },
                [_vm._v("Team Elevare")]
              )
            ])
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }