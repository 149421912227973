<template>
    <div>
        <!-- <div class="container-fluid mb-4 py-3 bg-white">
            <div class="row">
                <div
                    class="col-12 d-flex align-items-center justify-content-between"
                >
                    
                    <a
                        :href="
                            `/admin/suppliers/${supplier.id}/lead_forms/create`
                        "
                        class="btn btn-primary"
                        >Create Lead Form</a
                    >
                </div>
            </div>
        </div> -->

        <div class="container-fluid mb-4 bg-white card mk-leadformindex">
            <div class="row">
                <div class="col-12">
                    <div class="customer-card bg-white pt-3">
                        <div>
                            <h2 class="mb-0">Lead Form</h2>
                        </div>
                        <paginated-table
                            v-if="leadForms.data"
                            :tableData="tableData"
                            :paginatedData="leadForms"
                            @pagination-change-page="getLeadForms"
                        >
                            <template v-slot:right-row="row">
                                <div
                                    class="d-flex align-items-center justify-content-end"
                                    @click.stop
                                >
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        content="Feedback form link"
                                        placement="top-start"
                                    >
                                        <a
                                            :href="row.signed_link"
                                            style="text-decoration:none;"
                                            class="btn-light-blue icon-btn mr-3 d-flex justify-content-center align-items-center"
                                            type="primary"
                                        >
                                            <i
                                                class="fas fa-link text-green"
                                            ></i>
                                        </a>
                                    </el-tooltip>
                                    <el-input
                                        :value="
                                            `<iframe src='${row.signed_link}' title='${supplier.name} Lead Form'></iframe>`
                                        "
                                        disabled
                                    >
                                    </el-input>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        content="Copy iFrame Tag"
                                        placement="top-start"
                                    >
                                        <a
                                            href="#"
                                            type="primary"
                                            class="btn-light-blue icon-btn ml-1 d-flex justify-content-center align-items-center"
                                            style="text-decoration:none;"
                                            @click.prevent="
                                                copyiframe(row.signed_link)
                                            "
                                        >
                                            <i
                                                class="fas fa-code text-green"
                                            ></i>
                                        </a>
                                    </el-tooltip>
                                </div>
                            </template>
                        </paginated-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LeadForm from "../../models/LeadForm";
import Supplier from "../../models/Supplier";
export default {
    props: {
        supplier: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            leadForms: {},
            tableData: {
                columns: [
                    // {
                    //     label: "Created At",
                    //     name: "created_at",
                    //     datetime: true,
                    //     sortable: false
                    // },
                    {
                        custom_component: true
                    }
                ],
                edit: true,
                showSearch: false,
                showPerPage: true,
                viewUrl: `/admin/suppliers/${this.supplier.id}/lead_forms`
            }
        };
    },
    mounted() {
        this.getLeadForms();
    },
    methods: {
        copyiframe(link) {
            let iframe = `<iframe src="${link}" title="${this.supplier.name} Lead Form"></iframe>`;

            this.$copyText(iframe).then(
                e => {
                    this.$message({
                        type: "success",
                        message: "iFrame Tag Copied"
                    });
                },
                e => {
                    console.log(e);
                }
            );
        },
        async getLeadForms(
            page = 1,
            sortColumn = null,
            query = null,
            per_page = 100,
            desc = false
        ) {
            let leadForms = LeadForm.page(page).params({
                per_page: per_page
            });
            if (sortColumn) {
                leadForms.orderBy(
                    (desc ? "-" : "") +
                        (sortColumn.sort_name || sortColumn.name)
                );
            } else {
                leadForms.orderBy("created_at");
            }

            leadForms
                .for(new Supplier(this.supplier))
                .include("user", "leads_count")
                .append("signed_link")
                .get()
                .then(res => {
                    this.loading = false;
                    this.leadForms = res;
                })
                .catch(errors => {
                    this.loading = false;
                    console.error(errors);
                });
        }
    }
};
</script>
