<template>
    <div class="container-fluid mk-leadform py-5">
        <div class="row">
            <div class="col-12 col-md-10 col-lg-8 mx-auto pb-3 bg-white">
                <div class="row py-3" v-if="!thankYou">
                    <div class="col-12 mb-5">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <img
                                alt="Elevare Logo"
                                border="0"
                                class="center fixedwidth"
                                src="/img/backend/brand/elepay-no-tag.png"
                                style="width: 150px;margin-top: 5px;"
                                title="Elevare Logo"
                            />

                            <img
                                v-if="supplier.icon_link"
                                alt="Elevare Logo"
                                border="0"
                                class="center fixedwidth"
                                :src="supplier.icon_link"
                                style="width: 150px;"
                                :title="`${supplier.name} Icon`"
                            />
                        </div>
                        <div
                            class="mb-3"
                            style="margin-top: 36px; width: 60px; height: 4px; background-color: #ff968d;"
                        ></div>

                        <h1 class="mb-3 font-heading">Client Information</h1>
                        <p class="font-16" style="text-align:justify;">
                            You're one step closer to closing the deal. We can
                            help you make it happen. Fill in your customers
                            details below (ensure they are correct). These
                            details will be used in the application process. As
                            soon as you submit this form Elevare will send your
                            customer an email to continue the application
                            online.
                        </p>

                        <!-- supplier details -->
                        <div class="row mt-5">
                            <div class="col-12 mb-3">
                                <h3 class="mb-0 font-heading">
                                    Supplier Details
                                </h3>
                            </div>

                            <div class="form-group col-12 col-md-6">
                                <label for="supplier_name">Supplier</label>
                                <el-input
                                    v-model="supplier.name"
                                    :disabled="true"
                                ></el-input>
                            </div>
                            <div class="form-group col-12 col-md-6">
                                <label for="sales_representative"
                                    >Sales Representative</label
                                >
                                <el-input
                                    placeholder="Sales Representative"
                                    v-model="lead.sales_representative"
                                ></el-input>
                            </div>
                        </div>
                    </div>

                    <!-- customer details -->
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <h3 class="mb-0 font-heading">
                                    Customer Details
                                </h3>
                            </div>
                            <div class="form-group col-12 col-md-6">
                                <label for="name">First Name</label>
                                <el-input
                                    placeholder="John"
                                    v-model="lead.customer.first_name"
                                ></el-input>
                            </div>

                            <div class="form-group col-12 col-md-6">
                                <label for="name">Last Name</label>
                                <el-input
                                    placeholder="Doe"
                                    v-model="lead.customer.last_name"
                                ></el-input>
                            </div>

                            <div class="form-group col-12 col-md-6">
                                <label for="email">Email</label>
                                <el-input
                                    placeholder="Email"
                                    v-model="lead.customer.email"
                                ></el-input>
                            </div>

                            <div class="form-group col-12 col-md-6">
                                <label for="phone">Phone</label>

                                <vue-tel-input
                                    mode="international"
                                    :class="['w-100 form-control-borders']"
                                    :onlyCountries="['AU', 'GB', 'NZ', 'US']"
                                    style="min-height:38px !important"
                                    v-model="lead.customer.phone"
                                ></vue-tel-input>
                            </div>

                            <div class="form-group col-12 col-md-6">
                                <label for="requested_amount"
                                    >Requested Amount</label
                                >
                                <currency-input
                                    placeholder="Amount"
                                    class="el-input__inner"
                                    v-model="lead.dollar_requested_amount"
                                />
                            </div>

                            <div class="form-group col-12 col-md-6">
                                <label for="property_address"
                                    >Property Address</label
                                >
                                <el-input
                                    placeholder="Property Address"
                                    v-model="
                                        lead.extra_attributes.address
                                            .formatted_address
                                    "
                                ></el-input>
                            </div>

                            <div
                                class="form-group col-12 col-md-6 d-flex flex-column"
                            >
                                <label for="start_date"
                                    >Estimated Start Date</label
                                >
                                <el-date-picker
                                    class="w-100"
                                    placeholder="Estimated Start Date"
                                    type="date"
                                    v-model="lead.start_date"
                                    format="dd/MM/yyyy"
                                    value-format="yyyy-MM-dd"
                                ></el-date-picker>
                            </div>

                            <div
                                class="form-group col-12 col-md-6 d-flex flex-column"
                            >
                                <label for="fund_purpose_ids"
                                    >Fund Purpose</label
                                >
                                <el-select
                                    v-model="lead.fund_purpose_ids"
                                    multiple
                                    class="w-100"
                                >
                                    <el-option
                                        v-for="purpose in fundPurposes"
                                        :value="purpose.id"
                                        :key="purpose.id"
                                        :label="purpose.name"
                                    >
                                    </el-option>
                                </el-select>
                                <el-input
                                    class="mt-3"
                                    v-if="
                                        lead.fund_purpose_ids.filter(
                                            id => id == 4
                                        ).length > 0
                                    "
                                    v-model="lead.other_purpose"
                                    placeholder="Other Purpose"
                                >
                                </el-input>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="!thankYou">
                    <div
                        class="col-12 d-flex justify-content-stort align-items-center"
                    >
                        <el-button @click="submit" type="primary"
                            >Submit</el-button
                        >
                    </div>
                </div>

                <div v-else class="row">
                    <div
                        class="col-12 d-flex justify-content-center align-items-center flex-column"
                    >
                        <h1 class="text-center">Well Done!</h1>
                    </div>
                    <div class="col-12">
                        <p
                            class="app-card-intro text-center"
                            style="margin-bottom: 30px;"
                        >
                            A 107 day Pay Later email has now been sent to your
                            customer.<br />Elevare Pay Easy will take care of it
                            from here. We'll let you know when their online
                            application has been received.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Supplier from "../../models/Supplier";
import LeadForm from "../../models/LeadForm";
import Lead from "../../models/Lead";
export default {
    props: {
        supplier: {
            type: Object,
            required: true
        },
        fundPurposes: {
            type: Array,
            required: true
        },
        leadForm: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            thankYou: false,
            lead: {
                lead_form_id: this.leadForm.id,
                dollar_requested_amount: 0,
                requested_amount: 0,
                sales_representative: null,
                start_date: null,
                fund_purpose_ids: [],
                other_purpose: "",
                extra_attributes: {
                    address: {}
                },
                customer: {
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone: ""
                }
            }
        };
    },
    methods: {
        async submit() {
            try {
                this.lead.requested_amount =
                    this.lead.dollar_requested_amount * 100;
                const res = await new Lead(this.lead)
                    .for(new Supplier(this.supplier))
                    .save();
                this.thankYou = true;
            } catch (err) {
                console.error(err);
                var errorText = "";
                _.forEach(err.response.data.errors, function(value) {
                    errorText += "" + value + " ";
                });
                this.$message({
                    type: "error",
                    message: errorText
                });
            }
        }
    }
};
</script>
