<template>
    <div>
        <div class="container-fluid bg-white card py-5">
            <div class="row">
                <div class="col-12">
                    <h1>Personal Declaration</h1>
                </div>
                <div class="col-12 mt-3">
                    <p>
                        The details you provide below are critical before any
                        funding can be approved. So please make sure you provide
                        us with accurate information which can be verified via
                        various Australian external authorised credited sources.
                    </p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 mb-3">
                    <h4>Credit Declaration</h4>
                </div>
                <div class="col-12">
                    <el-checkbox
                        disabled
                        :value="!!declaration.is_not_bankrupt"
                        @change="value => (declaration.is_not_bankrupt = value)"
                    >
                        I declare that I am not bankrupt or insolvent and I am
                        not subject to any unsatisfied judgements
                    </el-checkbox>
                    <el-checkbox
                        disabled
                        :value="
                            !!declaration.is_not_property_subject_to_changes
                        "
                        @change="
                            value =>
                                (declaration.is_not_property_subject_to_changes = value)
                        "
                    >
                        The property is not subject to any event (for example
                        mortgage default) which would affect my (or the owners)
                        ability to sell the property.
                    </el-checkbox>
                    <p class="mt-1">
                        Even if you are not listing your property for sale, both
                        items above must be checked to proceed. If you have any
                        circumstances that prevent you from agreeing with the
                        declarations, select the bottom option in order to have
                        an Elevare Credit Analyst call you upon submission of
                        your application.
                    </p>
                    <el-checkbox
                        disabled
                        @change="
                            value =>
                                (declaration.speak_to_elevare_credit_analyst = value)
                        "
                        :value="!!declaration.speak_to_elevare_credit_analyst"
                    >
                        I would like to speak with an Elevare Credit Analyst to
                        discuss my situation further.
                    </el-checkbox>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-6">
                    <label>Current Employer</label>
                    <el-input v-model="employment.current_employer" disabled>
                    </el-input>
                </div>
                <div class="col-6 d-flex flex-column">
                    <label> Duration of Employment</label>
                    <el-select
                        v-model="employment.employment_duration_id"
                        disabled
                    >
                        <el-option
                            disabled
                            v-for="duration in employmentDurations"
                            :key="duration.id"
                            :value="duration.id"
                            :label="duration.name"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="col-6 mt-3">
                    <label> Position</label>
                    <el-input v-model="employment.position" disabled>
                    </el-input>
                </div>
                <div class="col-6 d-flex flex-column mt-3">
                    <label> Type of Employment</label>
                    <el-select v-model="employment.employment_type_id" disabled>
                        <el-option
                            v-for="type in employmentTypes"
                            :key="type.id"
                            :value="type.id"
                            :label="type.name"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="col-6 d-flex flex-column mt-3">
                    <label> Gross Annual Income</label>
                    <el-select v-model="employment.income_range_id" disabled>
                        <el-option
                            v-for="incomeRange in incomeRanges"
                            :key="incomeRange.id"
                            :value="incomeRange.id"
                            :label="incomeRange.name"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        initial_supplier: {
            type: Object
        },
        initial_application: {
            type: Object
        },
        incomeRanges: {
            type: Array
        },
        employmentTypes: {
            type: Array
        },
        employmentDurations: {
            type: Array
        }
    },
    data() {
        return {
            supplier: this.initial_supplier,
            application: this.initial_application,
            declaration: this.initial_application.credit_worthiness || {
                is_not_bankrupt: false,
                is_not_property_subject_to_changes: false,
                speak_to_elevare_credit_analyst: false
            },
            employment: this.initial_application.employments[0] || {
                current_employer: null,
                income_range_id: null,
                employment_type_id: null,
                employment_duration_id: null,
                position: null
            },
            tc: false
        };
    },
    methods: {}
};
</script>
