export default {
    spouse: {
        id: 1,
        name: "Spouse",
    },
    partner: {
        id: 2,
        name: "Partner",
    },
    director: {
        id: 3,
        name: 'Director'
    },
    shareholder: {
        id: 4,
        name: 'Shareholder',
    },
    trusteee: {
        id: 5,
        name: 'Trustee',
    },
    power_of_attorney: {
        id: 6,
        name: 'Power of Attorney'
    }
};
