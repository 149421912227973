var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "container-fluid bg-white card application-card mk-terms-conds p-3 mb-0"
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              ref: "tc_body",
              staticClass: "col-12 col-md-12 tc-body",
              attrs: { id: "tc_body" }
            },
            [
              _vm._m(1),
              _vm._v(" "),
              _c("p", [_vm._v("Standard Terms & Conditions ")]),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  '1.1. Your Agreement with Elevare ("the Agreement", "this Agreement" or\n                "your Agreement") comprises the Information Schedule (in\n                which you have provided details and in which you have agreed\n                to the terms in that Information Schedule), Elevare’s\n                Standard Terms and Conditions and Elevare’s Additional\n                Policies relating to privacy, security or otherwise.'
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "1.2. Each of Elevare and you may sign this Agreement electronically and\n                you consent to providing your signature electronically for\n                the purposes of this Agreement, any authorities under the\n                Privacy Act and any other document or matter relating to\n                this Agreement. "
                )
              ]),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "2.1. You must provide anyinformation or documentation reasonably requested by us or\n                the Supplier. You represent and warrant that the information\n                provided by you is complete, accurate, up to date, and not\n                misleading. If the information provided (including the\n                details in the Information Schedule) becomes inaccurate at\n                any time, then you must notify us of the inaccuracy, and you\n                must provide us with accurate and updated information. "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "2.2. You irrevocably authorise us (and our Team Members) to conduct\n                any assessment, investigation or review of you or your\n                circumstances (including obtaining a credit report or\n                similar about you) for the purpose of checking or verifying\n                information provided by you, deciding whether or not to\n                provide any funding to or for you or for any other purpose\n                relating to this Agreement. "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  " 2.3. You irrevocably authorise us to\n                disclose any information relating to you to the extent\n                reasonably required for our purposes or to the extent\n                required by any law. "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  " 2.4. You acknowledge and agree that we may\n                report any negative activity by you in relation to moneys\n                owing to us (including late payments, missed payments,\n                defaults etc.) to any credit reporting agency. "
                )
              ]),
              _vm._v(" "),
              _vm._m(4),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                     3.1. We may choose to approve or not approve your request\n                for funding of an Elevare Purchase, and we may withdraw any\n                approval (at our discretion) at any time before we provide\n                funding. "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  " 3.2. If we approve your request, then you will receive\n                confirmation of such approval (by notice from us) and we\n                will be treated as entering into this Agreement. "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "  3.4. If we approve your request and agree to provide funding for an\n                Elevare Purchase: "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(a) we may advance the Approved Amount to the\n                Supplier before the Advance Date, pursuant to the terms of\n                an agreement between Elevare and the Supplier; and \n                "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  " (b) we will advance the Approved Amount to you on the Advance Date by\n                paying that amount to the Supplier or Suppliers on your\n                behalf. "
                )
              ]),
              _vm._v(" "),
              _vm._m(5),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "4.1. If you Order a product or service from a Supplier and we agree to fund that purchase:\n                (a) that Order will be treated as an Elevare Purchase; \n                (b) you give us an unconditional and irrevocable direction (and\n                agreement) to pay (or cause its affiliate to pay) the\n                Supplier an amount equal to the Approved Amount for "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "that Elevare Purchase to the Supplier or Suppliers in accordance\n                with the Information Schedule; and \n                "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "  (c) you must pay the Approved Amount (together with any fees or charges payable by you) to\n                us in accordance with this Agreement. "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(" 4.2. You must pay the Approved Amount to us because: ")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "  (a) prior to the Advance Date,\n                that amount will be owing to us (as assignee of that debt\n                from the Supplier to us); and "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  " (b) after the Advance Date, that\n                amount will be owing to us (as lender of that Approved\n                Amount to you). "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  " 4.3. You must pay all moneys owing by you to us\n                on or before the Due Date for such payment and otherwise,\n                when we request you to do so. "
                )
              ]),
              _vm._v(" "),
              _vm._m(6),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                    5.1. You must pay the Approved Amount together with any fees and charges\n                    payable by you to us on or before the earlier of the\n                    following: \n                "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                    (a) the Repayment Date (which is sixty-two (62) days\n                after the Advance Date); the date on which the sale of the\n                Property is completed; \n                "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                     (b) the date on which deposit monies\n                relating to sale of the Property are released to you; "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(c) the date on which the Property is withdrawn from sale; and the\n                date on which the Agency (if any) ceases to act as your\n                Agency. "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "5.2. If you pay the Approved Amount to us before the\n                Advance Date, then that amount is paid on account of the\n                purchase price for your Elevare Purchase. "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "5.3. If you pay the Approved Amount to us after the Advance Date, then that\n                amount is paid in repayment of the advance we make to you on\n                the Advance Date. "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "5.4. You authorise us to charge (or withdraw)\n                the Approved Amount, our fees and any other amounts payable\n                by you to us (to the extent those amounts have not otherwise\n                been paid by you) to (or from) your Nominated Payment Source\n                on the relevant Due Date for payment. "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  " 5.5. You must pay all government duties, taxes and charges now, or in the future,\n                charged on or otherwise payable in relation to this\n                Agreement and you must pay any processing or other fees\n                imposed by any third party on or in relation to payments by you. \n                You agree to make all payments you owe us under this Agreement in full, \n                without any set-off or counterclaim. "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  " 5.6. You irrevocably authorise and direct any agent, \n                    solicitor or other person acting for you in relation to \n                    a sale of the Property to pay all amounts owing to us under this \n                    Agreement to us from any monies any such person holds for you or on \n                    your behalf (relating to a sale of the Property). "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                    5.7. You direct any such person to rely upon provision of a signed copy of this\n                    Agreement together with a written request for payment of an amount from Elevare\n                    as evidence of your irrevocable direction and authority for such person to\n                    pay the amount requested by Elevare.\n                "
                )
              ]),
              _vm._v(" "),
              _vm._m(7),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                   6.1. If you pay the Approved Amount to us prior to the Advance Date, you must also pay us our fee equal to 2% of the Approved Amount. \n                "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                   6.2. If you pay the Approved Amount to us on or after the Advance Date, you must pay us our fees calculated as follows:\n                "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                   (a) the amount equal to 5% of the Approved Amount;\n                "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                    (b) subject to clause 4.3, interest in an amount equal to 0.9% multiplied by the Approved Amount; and\n                "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                    (c) any late fees and charges in accordance with this Agreement. \n                "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                    6.3. If you repay all moneys you owe to us before the date which is fourteen (14) days after the Advance Date, we will charge our fees but we will not charge any interest under this Agreement.\n                "
                )
              ]),
              _vm._v(" "),
              _vm._m(8),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", [_vm._v("7.1. You acknowledge and agree that:")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(a) Elevare does not supply the Supplier's products or services to you;"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(b) any issue relating to a product or service provided by a Supplier to you is an issue between you and that Supplier and does not involve Elevare; and"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(c) irrespective of any issue between you and a Supplier, you must pay the Approved Amount to Elevare"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                    We are not liable for any refusal or failure of a Supplier to perform its obligations in relation to any\n                    transaction you may have with that Supplier. \n                    In particular, we are not liable for the quality, performance, suitability, availability or other matters\n                    relating to goods or services purchased by you from a Supplier.\n                "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                    We are not responsible for any defects in any goods or services acquired by you through the use of any Elevare\n                    Product and you acknowledge and agree that any complaints or issues about goods and services must be addressed\n                    to the Supplier who supplied those goods and services to you.\n                "
                )
              ]),
              _vm._v(" "),
              _vm._m(9),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "8.1. If you purchase goods or services from a Supplier (which purchase is treated as an Elevare Purchase)\n                    and you may be entitled to make or claim a Loss or Claim,\n                    including by way of refund (a Customer Claim) from that Supplier, then:"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(a) it is your responsibility to make, manage and settle any Customer Claim against or with the Supplier (not with Elevare) but where any refund or other amount is payable to or for you then you must direct that payment to be made to us so as to reduce the amount otherwise payable by you to us; "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(b) it is your responsibility to make and manage the return of any relevant goods or services to the Supplier;"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(c) we have no responsibility relating to any such Customer Claim except that the amount owing by you to us may be reduced under this Clause 8; and"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(d) you must pay the Approved Amount (subject to reduction under this Clause 8) to us, irrespective of any Customer\n                    Claim or other right you have or may have in relation to the goods or services or the Supplier of them."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                    8.2. If, for any reason, a Supplier (or any other person) pays (or refunds) money to us on account\n                    of any part of the Approved Amount then we will give you the benefit of that payment by applying\n                    that payment to reduce any amount owing by you to us and paying the balance (if any) to you.\n                "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                    8.3. It is your responsibility to deal with your Supplier or any other person in relation to any Customer Claim (or similar) issue."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                    You must make and we will continue to process any payments relating to the Approved Amount and any fees,\n                    even if the goods or services funded by the Approved Amount are subject to a dispute or other issue."
                )
              ]),
              _vm._v(" "),
              _vm._m(10),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "9.1. You have certain rights at law which cannot be limited or excluded. Nothing in this Agreement limits or excludes those rights, but our liability is limited to the maximum extent permitted by law.  If we are liable then our liability will not exceed the total amount that we pay or propose to pay or provide to you under this Agreement."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "9.2. To the extent permitted by law, we are not liable to you (or any other person) for any direct, special, indirect, consequential or incidental costs, losses, expenses or damages, or lost profits, or any other damages of any kind incurred or suffered by you or any other person in any way which is connected to our Products or any transaction between you and us (including as a result of our negligence or negligence by any of our Team Members). "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "9.3. To the extent permitted by law, we exclude all representations, conditions, guarantees and warranties, whether express or implied (by statute or otherwise) that relate to this Agreement or our Products. "
                )
              ]),
              _vm._v(" "),
              _vm._m(11),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", [_vm._v("10.1. You will be in default if:")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(a) you do not pay any amount owing to us by its Due Date for payment;  "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(b) you do not comply with any other term or condition of this Agreement; "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("(c) you are subject to an Insolvency Event; or")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(d) we believe, on reasonable grounds, that information provided by you was or is misleading or untrue."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "10.2. If you are in default, we may require you to immediately pay all amounts you owe us, and we may exercise our rights under this Agreement or at law.\n                "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "10.3. If you are in default and you have not paid moneys to us by the Due Date for payment, then:"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(a) we may charge you late payment interest equal to 0.06% of the overdue amount per day for the period from the Due Date to the date on which those moneys are paid; and"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(b) you must comply with the terms of our late payment policy and you must pay us any fees which are payable by you under that policy."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "10.4. You must pay to us any Enforcement Expenses reasonably incurred by us or our agents in exercising our rights because of your default. "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "10.5. You agree to pay to us and to indemnify us for and against any Loss or Claim that we suffer or incur, and which arises from or relates to a default by you under this Agreement."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "10.6. If you are in default under this Agreement, you acknowledge and agree that: "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(a) where goods or services you purchase using Elevare's Products are to be delivered after the time  "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "of your default, the Supplier or any other person may, under instructions from us, withhold delivery of those goods or services; and "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(b) you authorise us to act as your agent to suspend, terminate or cancel your agreement with the Supplier or any other person for delivery of any relevant goods or services. "
                )
              ]),
              _vm._v(" "),
              _vm._m(12),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "11.1. You charge your interest in the Property in favour of Elevare to secure payment of all moneys owing by you to us and performance of your obligations under this Agreement. "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "11.2. You consent to us lodging a caveat on the title for the Property to protect our rights under this Agreement.  You agree to execute and provide to us any documents we require to record, register or otherwise protect our interests under this Agreement.  We agree to release the Property immediately upon repayment of all money owing to us under this Agreement."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "11.3. You irrevocably appoint Elevare and each director of Elevare as your attorney to do anything you are required to do under this Agreement (including the execution of any document) but do not do within seven days of request from us."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "11.4. You authorise and direct all other persons to accept any document signed by an attorney under this clause together with a statutory declaration from that attorney (declaring the validity of that person's power) as sufficient and conclusive evidence of the validity of that person's exercise of that power."
                )
              ]),
              _vm._v(" "),
              _vm._m(13),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "12.1. party may give a document or notice to another party by: "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("(a) delivering it to the other party personally;")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(b) leaving it at or sending it by post or otherwise to the other party's last known residential or postal address; or"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("(c) emailing it to the other party's email address.")
              ]),
              _vm._v(" "),
              _vm._m(14),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "13.1. We may collect and use your personal information in accordance with our Privacy Policy (as amended from time to time).  You may view our Privacy Policy on our website, www.elevarepayeasy.com.au"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "13.2. You agree that we may collect your personal information (and share it with our Team Member) to identify you, provide our services to you, for our business development purposes and for any purpose related to this Agreement. Unless you tell us not to, we may use your information to let you know about products or services that may interest you and we may share your information with the Supplier. "
                )
              ]),
              _vm._v(" "),
              _vm._m(15),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "14.1. If any provision of the Agreement is or becomes invalid, unenforceable or breaches any mandatory law or applicable code then that provision is treated as severed from this Agreement and the Agreement will continue to be valid and operative without that severed provision."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "14.2. In the event that you comprise or include (by name or other method) two or more persons:"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(a) a liability of those persons (or any of them) under this Agreement is a joint liability of all of them and a several liability of each of them;  "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(b) a right given to those persons under this Agreement is a right given severally to each of them; and"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(c) we may act upon the request or direction (or notice) of any one of those persons."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "14.3. You agree and acknowledge that we can intercept, record, read or view by any means any communication you may have with or make to us by any means.  For that purpose, communications include telephone calls, emails and any other form of electronic or wireless communication."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "14.4. This Agreement will be governed by the law for the time being in force in the State in which the Property is situated and you agree to submit to the non-exclusive jurisdiction of the courts in that State. "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "14.5. If we do not exercise a right, remedy or power, this does not mean we cannot exercise it later and we are not stopped from doing so. We are not liable for any Loss or Claim caused by exercising, attempting to exercise or failing to exercise, a right, remedy or power under this Agreement (even if caused by our negligence)."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "14.6. You agree that any statement (including, but not limited to, a statement of account or a default notice) that has been given to you by us or our Team Member, is evidence of the facts or matters in the statement and that, unless contrary evidence is established, it is conclusive evidence of its content."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "14.7. This Agreement (or any of your rights or obligations under this Agreement) may not be assigned by you without our prior written consent. You authorise us to assign, transfer or otherwise deal with our rights under this Agreement without the need to obtain further consent from you. "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "14.8. We may take any action that we decide (in our discretion) in relation to any AML/CTF laws and without limitation, we may delay, limit or stop any payment to you if we are concerned about the application of those laws to this Agreement. "
                )
              ]),
              _vm._v(" "),
              _vm._m(16),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "In this Agreement, the following terms and expressions have the following meanings, unless the context requires otherwise:"
                )
              ]),
              _vm._v(" "),
              _vm._m(17),
              _vm._v(" "),
              _vm._m(18),
              _vm._v(" "),
              _vm._m(19),
              _vm._v(" "),
              _vm._m(20),
              _vm._v(" "),
              _vm._m(21),
              _vm._v(" "),
              _vm._m(22),
              _vm._v(" "),
              _vm._m(23),
              _vm._v(" "),
              _vm._m(24),
              _vm._v(" "),
              _c("p", [_vm._v("(b) Elevare approves the Order.")]),
              _vm._v(" "),
              _vm._m(25),
              _vm._v(" "),
              _vm._m(26),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(a) a controller, manager, trustee, administrator or similar officer is appointed in respect of any of your assets;"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(b) a liquidator or provisional liquidator is appointed to or in respect of you;"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(c) a voluntary administrator, receiver or receiver and manager is appointed in relation to you or any of your assets;"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(d) any application (not being an application withdrawn or dismissed within 7 days) is made to a court for an order, or an order is made, or a meeting is convened, or a resolution is passed, for the purpose of:\n                "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(i) appointing a person referred to in the earlier part of this Clause;"
                )
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("(ii) winding up you or your affairs; or")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(iii) proposing or implementing a scheme of arrangement;"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(e) any event or conduct occurs which would enable a court to grant a petition, or an order is made, for the bankruptcy of an individual or his or her estate under any insolvency law;\n                "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(f) a person becomes, or admits in writing that he or she is, is declared to be, or is deemed to be, insolvent or unable to pay that person's debts; or"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "(g) anything analogous to any of the above events.\n                "
                )
              ]),
              _vm._v(" "),
              _vm._m(27),
              _vm._v(" "),
              _vm._m(28),
              _vm._v(" "),
              _vm._m(29),
              _vm._v(" "),
              _vm._m(30),
              _vm._v(" "),
              _vm._m(31),
              _vm._v(" "),
              _vm._m(32),
              _vm._v(" "),
              _vm._m(33)
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 position-relative" }, [
        _c("div", { staticClass: "app-card-decoration" }),
        _vm._v(" "),
        _c(
          "h3",
          {
            staticClass: "my-3",
            staticStyle: { "font-family": "aktiv-grotesk-extended, sans-serif" }
          },
          [_vm._v("Terms and Conditions")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12" }, [
        _c(
          "p",
          {
            staticClass: "app-card-intro",
            staticStyle: { "margin-bottom": "60px" }
          },
          [
            _vm._v(
              "Kindly read through the terms and conditions carefully, and click the button at the end to accept. You must scroll to the end of the terms and conditions to continue."
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Elevare – Promote Customer Agreement ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("1. THE AGREEMENT BETWEEN ELEVARE AND YOU")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("2. INFORMATION AND PROCESS")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("3. THE ELEVARE PROCESS")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("4. YOU MUST PAY ELEVARE")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("5. DUE DATE FOR PAYMENT")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("6. OUR FEES AND CHARGES")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("7. SUPPLY OF PRODUCTS OR SERVICES ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("8. REFUNDS, REPAYMENTS OR ISSUES")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("9. EXCLUSIONS OF WARRANTIES AND REPRESENTATIONS")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("10. DEFAULT AND ITS CONSEQUENCES")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("11. OUR SECURITY")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("12. COMMUNICATION AND NOTICES")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("13. PRIVACY POLICY")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("14. GENERAL PROVISIONS")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("15. DEFINITIONS AND INTERPRETATION")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Advance Date")]),
      _vm._v(
        " means the date on which we will advance the Approved Amount to or for you and which is the date sixty two (62) days before the Repayment Date. "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Agency")]),
      _vm._v(
        " means the Real Estate Agency (if any) specified in the Information Schedule."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("AML/CTF ")]),
      _vm._v(
        " means the Anti-Money Laundering and Counter-Terrorism Financing (AML/CTF) laws."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Approved Amount")]),
      _vm._v(
        " means, in relation to a Supplier or an Elevare Purchase, the Approved Amount set out in the Information Schedule for that Supplier or such other amount that we agree to treat as the Approved Amount for the purposes of this Agreement.\n                "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Due Date")]),
      _vm._v(
        " for payment of moneys due means the day by which you are required to make the payment or pay the moneys.  \n                "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Elevare's Additional Policies")]),
      _vm._v(
        " means Elevare's Privacy Policy, Elevare's Complaints Policy, Elevare's PCI DSS Policy, any other formal policy published by Elevare (on Elevare's website) and any other policy which is incorporated into this Agreement by reference. \n                "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Elevare Product")]),
      _vm._v(" or "),
      _c("strong", [_vm._v("Product")]),
      _vm._v(" means a product offered by Elevare to its customers. ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Elevare Purchase")]),
      _vm._v(
        " means an Order by you where:\n                (a) you apply to Elevare to provide funding, in relation to the Order; and\n                "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Information Schedule")]),
      _vm._v(
        " means the schedule completed by you in relation to and for the purposes of this Agreement."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Insolvency Event")]),
      _vm._v(" includes but is not limited to:")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Loss")]),
      _vm._v(" or "),
      _c("strong", [_vm._v("claim")]),
      _vm._v(
        " includes any loss, claim, action, damage, liability, cost, charge, expenses, outgoing or payment."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Nominated Payment Source")]),
      _vm._v(
        " means the bank account (or card) nominated by you for the purpose of making payments to us under this Agreement. "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Order")]),
      _vm._v(
        " means a request, application or order made pursuant to which you (as customer) purchase or propose to purchase goods or services from a Supplier. \n                "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Property")]),
      _vm._v(
        " means the property (being land) which is described in the Information Schedule. \n                "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Repayment Date")]),
      _vm._v(" means the Repayment Date specified in the Information Schedule.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Supplier")]),
      _vm._v(
        " means each Supplier described in the Information Schedule (as relevant). \n                "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Team Member")]),
      _vm._v(
        " means, in relation to a person, any person who is an officer, employee, agent or representative of that person."
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }