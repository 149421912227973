<template>
    <div>
        <div class="container-fluid bg-white card application-card mk-thankyou">
            <div class="row">
                <div class="col-12 position-relative">
                    <div class="app-card-decoration"></div>
                    <h1 class="">Verify Your Identity</h1>
                </div>

                <div class="col-12 mt-3">
                    <h3
                        style="margin-bottom: 60px; font-family: aktiv-grotesk-extended, sans-serif"
                    >
                        Step 1: Upload your photo
                    </h3>
                </div>

                <div class="col-lg-8 col-12 order-2 order-sm-12">
                    <div>
                        <p>
                            Upload a photo from your computer or phone gallery,
                            or take a photo using your webcam or phone camera
                        </p>

                        <div class="mt-5 mb-3 row margin-mobile mr-3">
                            <div class="col-12 d-md-none">
                                <div
                                    class="d-flex justify-content-center align-items-center my-1"
                                >
                                    <input
                                        type="file"
                                        v-show="false"
                                        ref="file"
                                        @change="uploadFiles"
                                    />

                                    <el-button
                                        class="btn btn-primary w-100 mt-0"
                                        @click="openFile"
                                    >
                                        <i
                                            class="fas fa-cloud-upload-alt mr-2 text-white"
                                        ></i>
                                        Upload Photo
                                    </el-button>
                                </div>
                            </div>

                            <div class="col-12 d-md-none" v-if="!isMobile">
                                <div
                                    class="d-flex justify-content-center align-items-center my-1 p-2"
                                >
                                    <span> or</span>
                                </div>
                            </div>

                            <div class="col-12 d-md-none" v-if="!isMobile">
                                <div
                                    class="d-flex justify-content-center align-items-center my-1"
                                >
                                    <a
                                        :disabled="!application.id"
                                        class="btn btn-primary w-100 mt-0"
                                        href="#"
                                        @click.prevent="openFace"
                                        target="_blank"
                                    >
                                        <i
                                            class="fas fa-camera mr-2 text-white"
                                        ></i>
                                        Take Photo
                                    </a>
                                </div>
                            </div>

                            <div class="col-12 d-none d-md-block">
                                <div
                                    class="d-flex justify-content-start align-items-center my-1"
                                >
                                    <input
                                        type="file"
                                        v-show="false"
                                        ref="file"
                                        @change="uploadFiles"
                                    />

                                    <el-button
                                        class="btn btn-primary mt-0 mr-2"
                                        @click="openFile"
                                    >
                                        <i
                                            class="fas fa-cloud-upload-alt mr-2 text-white"
                                        ></i>
                                        Upload Photo
                                    </el-button>

                                    <span class="p-2" v-if="!isMobile">or</span>

                                    <a
                                        :disabled="!application.id"
                                        class="btn btn-primary mt-0 ml-2 el-button el-button--default"
                                        href="#"
                                        @click.prevent="openFace"
                                        v-if="!isMobile"
                                        target="_blank"
                                    >
                                        <i
                                            class="fas fa-camera mr-2 text-white"
                                        ></i>
                                        Take Photo
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="col-lg-4 p-3 col-12 order-1 order-sm-12 mb-3"
                    style="background: rgba(142, 166, 4, 0.1);"
                >
                    <p>
                        Note: The graphic below shows you the correct way to
                        take your verification photo for the best results on the
                        identify verification process.
                    </p>

                    <p>
                        For more information on the photo requirements click
                        <a
                            href="#"
                            @click.prevent="openModal = true"
                            class="help-link"
                            >here</a
                        >
                    </p>

                    <div>
                        <img
                            style="border: 1px solid #8ea604"
                            class="img-fluid mb-1"
                            src="/img/elements/verification-acceptable.png"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div
            class="d-flex flex-md-row flex-column align-items-center justify-content-between mb-5"
        >
            <button
                class="btn-white-green-text mb-3 mb-md-0 mutable-mobile-width"
                @click="$emit('previous', true)"
            >
                Back
            </button>
        </div>
        <upload-rule :open-modal.sync="openModal"> </upload-rule>
    </div>
</template>

<script>
import Supplier from "../../../models/Supplier";
import Application from "../../../models/Application";
import Form from "form-backend-validation";
import Media from "../../../models/Media";
import axios from "axios";
export default {
    props: {
        initial_supplier: {
            type: Object,
            required: true
        },
        initial_application: {
            type: Object,
            required: true
        },
        linkToFace: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            application: this.initial_application,
            supplier: this.initial_supplier,
            form: new Form(),
            isMobile: false,
            loading: false,
            openModal: false
        };
    },
    mounted() {
        if (window.matchMedia("screen and (max-width: 768px)").matches)
            this.isMobile = true;

        Echo.channel(`photo_submitted.applications.${this.application.id}`)
            .listen('.photo.submitted', e => {
                console.log(e);
                this.refreshApplication();
            });
    },
    methods: {
        openFace() {
            window.open(this.linkToFace, "_blank");
        },
        async refreshApplication() {
            try {
                const res = await new Application()
                    .for(new Supplier(this.supplier))
                    .find(this.application.id);
                    
                this.$emit("update-application", res);
            } catch(err) {
                console.error(err);
            }
        },
        openFile() {
            this.$refs.file.click();
        },
        async uploadFiles(e) {
            this.loading = true;
            let files = e.target.files;
            let local_files = [];

            for (let i = 0; i < files.length; i++) {
                local_files.push(files[i]);
            }

            let form = new FormData();
            form.append("type", "documents");
            local_files.forEach(file => {
                form.append("files[]", file);
            });

            try {
                const res = await axios.post(
                    `/api/v1/customer/suppliers/${this.supplier.id}/applications/${this.application.id}/medias`,
                    form,
                    {
                        headers: { "content-type": "multipart/form-data" }
                    }
                );

                this.$message({
                    type: "success",
                    message: "Upload Success"
                });
                this.loading = false;
                this.application.media = res.data.media;

                // emit to parents
                this.$emit("update-application", res.data);
            } catch (err) {
                this.loading = false;
                console.error(err);
                this.form.errors.record(err.response.data.errors);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
        }
    }
};
</script>
<style lang="sass" scoped>
@media only screen and (max-device-width: 480px)
    .margin-mobile
        margin-right: 0px !important
</style>
