var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid px-0 py-5" },
    [
      !_vm.done
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 py-3 bg-white" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "row px-5 mt-3" }, [
                _c("div", { staticClass: "col-12 col-md-12" }, [
                  _c("div", { staticClass: "row" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-9 my-2" }, [
                      _c("p", { staticClass: "font-16" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.application.customer.first_name) +
                            "\n                            "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(2),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-9 my-2" }, [
                      _c("p", { staticClass: "font-16" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.application.customer.last_name) +
                            "\n                            "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(3),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-9 my-2" }, [
                      _c("p", { staticClass: "font-16" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.supplier.name) +
                            "\n                            "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(4),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-9 my-2" }, [
                      _c("p", { staticClass: "font-16" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.application.lead.extra_attributes.address
                                .formatted_address
                            ) +
                            "\n                            "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(5),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-9 my-2" }, [
                      _c("p", [
                        _c("strong", { staticClass: "font-16 text-blue" }, [
                          _vm._v(
                            "\n                                    $" +
                              _vm._s(
                                Number(
                                  _vm.application.lead.dollar_requested_amount
                                ).toLocaleString("en-AU", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })
                              ) +
                              "\n                                "
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(6),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-9 my-2" }, [
                      _c("p", [
                        _c("strong", { staticClass: "font-16 text-blue" }, [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm
                                  .moment(_vm.application.lead.start_date)
                                  .format("dddd MMMM Do, Y")
                              ) +
                              "\n                                "
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "row px-5 mt-3",
                  staticStyle: { background: "rgba(255, 78, 0, 0.03)" }
                },
                [
                  _c("div", { staticClass: "col-12 col-md-12" }, [
                    _c("div", { staticClass: "row my-3 py-3" }, [
                      _vm._m(7),
                      _vm._v(" "),
                      _vm._m(8),
                      _vm._v(" "),
                      _vm.planVariation.dollar_revised_plan_amount
                        ? _c("div", { staticClass: "col-md-3 col-12 my-3" }, [
                            _vm._m(9)
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.planVariation.dollar_revised_plan_amount
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-md-9 col-12 my-3 d-flex align-items-center"
                            },
                            [
                              _c("p", { staticClass: "mb-0" }, [
                                _c("strong", { staticClass: "font-16" }, [
                                  _vm._v(
                                    "\n                                    $" +
                                      _vm._s(
                                        Number(
                                          _vm.planVariation
                                            .dollar_revised_plan_amount
                                        ).toLocaleString("en-AU", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })
                                      ) +
                                      "\n                                "
                                  )
                                ])
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.planVariation.revised_start_date
                        ? _c("div", { staticClass: "col-md-3 col-12 my-3" }, [
                            _vm._m(10)
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.planVariation.revised_start_date
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-md-9 col-12 my-3 d-flex align-items-center"
                            },
                            [
                              _c("p", { staticClass: "mb-0" }, [
                                _c("strong", { staticClass: "font-16" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.planVariation.revised_start_date
                                          ? _vm
                                              .moment(
                                                _vm.planVariation
                                                  .revised_start_date
                                              )
                                              .format("dddd MMMM Do, Y")
                                          : "N/A"
                                      ) +
                                      "\n                                "
                                  )
                                ])
                              ])
                            ]
                          )
                        : _vm._e()
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row px-5 mt-3" }, [
                _c("div", { staticClass: "col-12 col-md-12" }, [
                  _c("div", { staticClass: "row mt-5" }, [
                    _vm._m(11),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-4" },
                      [
                        _c("pay-before-card", {
                          attrs: {
                            paydayCardBg: "rgb(249, 249, 249)",
                            payBeforeDays: 45,
                            payBeforeFee: "2.0%",
                            payBeforeHighlightFee: true,
                            payBeforeInterest: "0.0%",
                            payBeforeAmountPayable: _vm.getTotalPayable(2, 0),
                            "due-date": _vm.getDueDate(44)
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-4" },
                      [
                        _c("pay-before-card", {
                          attrs: {
                            paydayCardBg: "rgba(255, 78, 0, 0.03)",
                            payBeforeDays: 60,
                            payBeforeFee: "5.0%",
                            payBeforeHighlightFee: false,
                            payBeforeInterest: "0.0%",
                            payBeforeAmountPayable: _vm.getTotalPayable(5, 0),
                            "due-date": _vm.getDueDate(59)
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-4 schedule-mobile-card" },
                      [
                        _c("pay-before-card", {
                          attrs: {
                            paydayCardBg: "rgba(255, 78, 0, 0.12)",
                            payBeforeDays: 107,
                            payBeforeFee: "5.0%",
                            payBeforeHighlightFee: false,
                            payBeforeInterest: "0.9%",
                            payBeforeAmountPayable: _vm.getTotalPayable(5, 0.9),
                            payBeforeIsFinalCard: true,
                            "due-date": _vm.getDueDate(107)
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-5 mb-3" }, [
                    _vm._m(12),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 mt-4" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn btn-primary mutable-mobile-width",
                            staticStyle: { "margin-bottom": "30px" },
                            on: {
                              click: function($event) {
                                _vm.openTCModal = true
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                                View Terms & Conditions\n                            "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._m(13),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        {
                          staticClass: "mb-3",
                          staticStyle: { height: "150px" }
                        },
                        [
                          _c("VueSignaturePad", {
                            ref: "signaturePad",
                            staticClass: "app-signature",
                            staticStyle: {
                              "margin-bottom": "8px",
                              border: "1px solid #e3e3e3",
                              width: "50%"
                            },
                            attrs: {
                              options: {
                                backgroundColor: "#fff"
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "mb-0 has-interaction",
                          on: { click: _vm.undo }
                        },
                        [
                          _c("i", { staticClass: "fas fa-undo text-red mr-2" }),
                          _c("strong", { staticClass: "text-red" }, [
                            _vm._v("Undo")
                          ])
                        ]
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-12 d-flex align-items-center justify-content-end mt-3"
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass:
                      "btn btn-primary mutable-mobile-width order-1 order-md-2 mb-3 mb-md-0",
                    attrs: { disabled: !_vm.tc },
                    on: { click: _vm.save }
                  },
                  [_vm._v("\n                Submit\n            ")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.done ? _c("success-page") : _vm._e(),
      _vm._v(" "),
      _c("terms-and-conditions-modal", {
        attrs: { "open-modal": _vm.openTCModal },
        on: {
          "update:openModal": function($event) {
            _vm.openTCModal = $event
          },
          "update:open-modal": function($event) {
            _vm.openTCModal = $event
          },
          accepted: function(value) {
            return (_vm.tc = value)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row px-5" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", {
          staticClass: "mb-3",
          staticStyle: {
            "margin-top": "36px",
            width: "60px",
            height: "4px",
            "background-color": "#ff968d"
          }
        }),
        _vm._v(" "),
        _c("h1", { staticClass: "mb-3 font-heading" }, [
          _vm._v(
            "\n                        Variation to Plan Details\n                    "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "mt-5 font-16" }, [
          _vm._v(
            "\n                        Your authorized Elepay Supplier has requested a\n                        variation to your existing Elepay Plan.\n                    "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3 my-2" }, [
      _c("p", { staticClass: "font-16" }, [
        _vm._v(
          "\n                                First Name:\n                            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3 my-2" }, [
      _c("p", { staticClass: "font-16" }, [
        _vm._v(
          "\n                                Last Name:\n                            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3 my-2" }, [
      _c("p", { staticClass: "font-16" }, [
        _vm._v(
          "\n                                Authorised Supplier:\n                            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3 my-2" }, [
      _c("p", { staticClass: "font-16" }, [
        _vm._v(
          "\n                                Property Address:\n                            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3 my-2" }, [
      _c("p", [
        _c("strong", { staticClass: "font-16 text-blue" }, [
          _vm._v(
            "\n                                    Original Plan Amount:\n                                "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3 my-2" }, [
      _c("p", [
        _c("strong", { staticClass: "font-16 text-blue" }, [
          _vm._v(
            "\n                                    Original Start Date:\n                                "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "h3",
        {
          staticClass: "font-heading",
          staticStyle: { "margin-bottom": "30px" }
        },
        [
          _vm._v(
            "\n                                Plan Variation\n                            "
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "font-16" }, [
        _vm._v(
          "\n                                The following is the variation to your\n                                current Elepay plan.\n                            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "d-inline-block",
        staticStyle: {
          padding: "5px 10px",
          "background-color": "#FF4E00",
          "border-radius": "5px"
        }
      },
      [
        _c("p", { staticClass: "mb-0 text-white" }, [
          _c("strong", { staticClass: "text-white font-16" }, [
            _vm._v(
              "\n                                        Revised Plan Value:\n                                    "
            )
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "d-inline-block",
        staticStyle: {
          padding: "5px 10px",
          "background-color": "#FF4E00",
          "border-radius": "5px"
        }
      },
      [
        _c("p", { staticClass: "mb-0 text-white" }, [
          _c("strong", { staticClass: "text-white font-16" }, [
            _vm._v(
              "\n                                        Revised Start Date:\n                                    "
            )
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "h3",
        {
          staticClass: "text-blue font-heading",
          staticStyle: { "margin-bottom": "30px" }
        },
        [
          _vm._v(
            "\n                                Revised Payment Schedule\n                            "
          )
        ]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "mb-1" }, [
        _c("strong", { staticClass: "font-16" }, [
          _vm._v(
            "The quicker you repay your plan, the\n                                    lower your fees."
          )
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                                Enjoy our low fees when you pay off your\n                                plan earlier.\n                            "
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "mb-3 d-inline-block",
          staticStyle: {
            padding: "5px 10px",
            "background-color": "#FF4E00",
            "border-radius": "5px"
          }
        },
        [
          _c("p", { staticClass: "mb-0 text-white" }, [
            _c("strong", { staticClass: "text-white" }, [
              _vm._v("Payment Schedule:")
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                                The table below provides you with the fees\n                                applicable if your payment is received by\n                                the designated date.\n                            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "mb-0 font-16" }, [
        _vm._v(
          "\n                                To proceed, please view the Terms &\n                                Conditions and sign and submit.\n                            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "h3",
        {
          staticClass: "text-blue font-heading",
          staticStyle: { "margin-bottom": "30px" }
        },
        [
          _vm._v(
            "\n                                Your Signature\n                            "
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }