<template>
    <div class="mk-identity">
        <div class="container-fluid bg-white card py-5">
            <div class="row">
                <div class="col-12">
                    <h1>Your Identity</h1>
                </div>
                <div class="col-12 mt-3">
                    <p>
                       Please make sure the details you complete below match the details on your nominated Photo ID.
                    </p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-4">
                    <label for="first_name">First Name</label>
                    <el-input
                        v-model="application.customer.first_name"
                        disabled
                    >
                    </el-input>
                </div>
                <div class="col-4">
                    <label for="middle_name">Middle Name</label>
                    <el-input
                        v-model="application.customer.middle_name"
                        disabled
                    >
                    </el-input>
                </div>
                <div class="col-4">
                    <label for="last_name">Last Name</label>
                    <el-input v-model="application.customer.last_name" disabled>
                    </el-input>
                </div>
                <!-- <div class="col-12 mt-2">
                    <p>Please write your full legal name as shown on your ID</p>
                </div> -->
            </div>
            <div class="row mt-5">
                <div class="col-6 d-flex flex-column">
                    <label for="date_of_birth">Date of Birth</label>
                    <el-date-picker
                        type="date"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        class="w-100"
                        v-model="application.customer.date_of_birth"
                        disabled
                    >
                    </el-date-picker>
                </div>
                <div class="col-6">
                    <label for="email">Email</label>
                    <el-input v-model="application.customer.email" disabled>
                    </el-input>
                </div>
                <div class="col-6 mt-3">
                    <label for="phone">Phone</label>
                    <vue-tel-input
                        mode="international"
                        :class="['w-100 form-control-borders']"
                        :onlyCountries="['AU', 'GB', 'NZ', 'US']"
                        style="min-height:38px !important"
                        v-model="application.customer.phone"
                        disabled
                    ></vue-tel-input>
                </div>
                <div class="col-12 mt-3">
                    <label for="residential_address"
                        >Current Residential Address</label
                    >

                    <el-input
                        disabled
                        v-if="
                            application.customer.extra_attributes
                                .residential_address
                        "
                        v-model="
                            application.customer.extra_attributes
                                .residential_address.formatted_address
                        "
                    >
                    </el-input>
                </div>
            </div>
            <div class="row">
                <div class="col-4 mt-3 d-flex flex-column">
                    <label for="identification_method"
                        >Identification Method</label
                    >
                    <el-select
                        v-model="identification.identification_type"
                        disabled
                    >
                        <el-option
                            v-for="opt in options"
                            :key="opt.id"
                            :value="opt.id"
                            :label="opt.name"
                        ></el-option>
                    </el-select>
                </div>
                <div
                    class="col-4 mt-3 d-flex flex-column"
                    v-if="identification.identification_type == 1"
                >
                    <label for="issuing_state">Issuing State</label>
                    <el-select v-model="identification.issuing_state" disabled>
                        <el-option
                            v-for="(state, index) in states"
                            :key="index"
                            :label="state.name"
                            :value="state.value"
                        ></el-option>
                    </el-select>
                </div>
                <div class="col-4 mt-3">
                    <label for="identification_number"
                        >Identification Number</label
                    >

                    <el-input
                        v-model="identification.identification_number"
                        disabled
                    >
                    </el-input>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <h4>
                        Documents
                    </h4>
                </div>
                <div class="col-12">
                    <div
                        class="card mt-2"
                        style="border-bottom:1px solid #efefef;"
                        v-for="media in application.media.filter(
                            media =>
                                media.collection_name == 'face_proof' ||
                                media.collection_name == 'documents'
                        )"
                        :key="media.id"
                    >
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div>
                                <p>{{ media.file_name }}</p>
                                <p>
                                    <i class="fas fa-tag"></i>
                                    {{
                                        _.startCase(
                                            _.replace(
                                                media.collection_name,
                                                "_",
                                                " "
                                            )
                                        )
                                    }}
                                </p>
                            </div>
                            <div>
                                <a :href="media.url" target="_blank">
                                    <i class="fas fa-eye text-blue"> </i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import IdentificationMethods from "../../../../frontend/constants/IdentificationMethods";
import States from "../../../../frontend/constants/States";
export default {
    props: {
        initial_supplier: {
            type: Object,
            required: true
        },
        initial_application: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            application: this.initial_application,
            supplier: this.initial_supplier,
            identification: this.initial_application.identifications[0] || {
                identification_type: null,
                identification_number: null,
                issuing_state: null
            }
        };
    },
    mounted() {
        if (!this.application.customer.extra_attributes.residential_address) {
            this.application.customer.extra_attributes = {
                residential_address: {
                    formatted_address: ""
                }
            };
        }
    },
    computed: {
        options() {
            return Object.keys(IdentificationMethods).map(key => {
                return IdentificationMethods[key];
            });
        },
        states() {
            return Object.keys(States).map(key => {
                return States[key];
            });
        }
    },
    methods: {}
};
</script>
