<template>
    <div class="container-fluid mk-application pb-5" style="padding-top: 20px;">
        <div class="row mb-4">
            <div class="col-12 elv-progress-bar">
                <el-progress
                    class="w-100 pr-0 mr-0"
                    :percentage="progress"
                    :format="format"
                ></el-progress>
            </div>
        </div>

        <introduction
            v-if="step == 1"
            :step="step"
            key="introduction"
            @next="next"
        ></introduction>

        <lead
            key="lead"
            @next="next"
            @previous="before"
            :step="step"
            :initial_application="application"
            :initial_supplier="supplier"
            :fund-purposes="fundPurposes"
            v-else-if="step == 2"
        ></lead>

        <identity
            key="identity"
            :step="step"
            @next="next"
            @previous="before"
            @update-application="value => (application = value)"
            :initial_application="application"
            :initial_supplier="supplier"
            v-else-if="step == 3"
        ></identity>

        <verify-identity
            key="verify_identity"
            :step="step"
            @previous="before"
            @update-application="value => (application = value)"
            :initial_application="application"
            :initial_supplier="supplier"
            :link-to-face="linkToFace"
            v-else-if="
                step == 4 &&
                    !application.skip_identity &&
                    !application.media
                        .filter(
                            m =>
                                m.collection_name == 'face_proof' ||
                                m.collection_name == 'documents'
                        )
                        .pop()
            "
        >
        </verify-identity>

        <step2-identity
            key="step2_identity"
            :step="step"
            @next="next"
            @previous="before"
            @update-application="value => (application = value)"
            :initial_application="application"
            :initial_supplier="supplier"
            :link-to-face="linkToFace"
            v-else-if="
                step == 4 &&
                    !application.skip_identity &&
                    application.media
                        .filter(
                            m =>
                                m.collection_name == 'face_proof' ||
                                m.collection_name == 'documents'
                        )
                        .pop()
            "
        >
        </step2-identity>

        <skiping-identity
            v-else-if="step == 4 && application.skip_identity"
            @next="next"
            @previous="before"
        >
        </skiping-identity>

        <property
            key="property"
            :step="step"
            @next="next"
            @previous="before"
            @update-application="value => (application = value)"
            :initial_application="application"
            :initial_supplier="supplier"
            v-else-if="step == 5"
        >
        </property>

        <personal-declaration
            key="personal-declaration"
            :step="step"
            @next="next"
            @previous="before"
            @update-application="value => (application = value)"
            :initial_application="application"
            :initial_supplier="supplier"
            :income-ranges="incomeRanges"
            :employment-types="employmentTypes"
            :employment-durations="employmentDurations"
            v-else-if="step == 6"
        >
        </personal-declaration>

        <application-summary
            key="app-summary"
            :step="step"
            @next="next"
            @previous="before"
            :initial_application="application"
            :initial_supplier="supplier"
            v-else-if="step == 7"
        >
        </application-summary>

        <thank-you v-else-if="step == 8"></thank-you>
    </div>
</template>

<script>
export default {
    props: {
        initial_application: {
            type: Object,
            required: true
        },
        supplier: {
            type: Object,
            required: true
        },
        incomeRanges: {
            type: Array,
            required: true
        },
        employmentTypes: {
            type: Array,
            required: true
        },
        employmentDurations: {
            type: Array,
            required: true
        },
        linkToFace: {
            type: String,
            required: true
        },
        fundPurposes: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            step: 1,
            application: this.initial_application
        };
    },
    methods: {
        before() {
            if (this.step > 1) this.step--;
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        next() {
            this.step++;
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        format(percentage) {
            return "";
        }
    },
    computed: {
        progress() {
            return ((this.step - 1) / 7) * 100;
        }
    }
};
</script>
