<template>
    <div>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(save)">
                <div
                    class="container-fluid bg-white card application-card mk-fe-create-lead"
                >
                    <div class="row">
                        <div v-if="form.errors.any()" class="col-12">
                            <div class="show-form-error">
                                <div
                                    v-for="(errors, index) in form.errors.all()"
                                    :key="index"
                                >
                                    <ul class="mb-0">
                                        <li
                                            v-for="(error, i) in errors"
                                            :key="i"
                                        >
                                            {{ error }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 position-relative">
                            <div class="app-card-decoration"></div>
                            <h1>Let's get started</h1>
                        </div>
                        <!-- <div class="col-12">
                            <p class="app-card-intro">
                                Here's what detail we already have. Just hit
                                confirm and continue. If you need to change any
                                field, hit support and we'll be in touch
                                shortly.
                            </p>
                        </div> -->

                        <div class="col-12">
                            <p class="mb-3 app-card-intro">
                                Quickly tell us about the funding you require.
                            </p>
                        </div>

                        <!-- what required for -->
                        <div class="col-12 mb-4">
                            <div class="row">
                                <div
                                    class="col-12 ml-0 col-md-5 d-flex flex-column flex-md-row align-items-center"
                                >
                                    <label class="mb-1" for="property_address"
                                        >The property address where the
                                        requested funds will be invested
                                    </label>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div style="min-width: 280px;">
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            name="address"
                                            rules="required"
                                        >
                                            <p class="mb-0 is-error">
                                                {{ errors[0] }}
                                            </p>
                                            <el-input
                                                :class="{
                                                    'is-error':
                                                        form.errors.has(
                                                            'extra_attributes.address.formatted_address'
                                                        ) || errors[0]
                                                }"
                                                v-model="
                                                    application.lead
                                                        .extra_attributes
                                                        .address
                                                        .formatted_address
                                                "
                                            ></el-input>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- how much -->
                        <div class="col-12 mb-4">
                            <div class="row">
                                <div
                                    class="col-12 ml-0 col-md-5 d-flex flex-column flex-md-row align-items-center"
                                >
                                    <label class="mb-1" for="amount_requested"
                                        >How much do you require to promote your
                                        property</label
                                    >
                                </div>
                                <div class="col-12 col-md-4">
                                    <div style="min-width: 280px;">
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            name="amount"
                                            rules="required|numeric|min_value:1"
                                        >
                                            <p class="mb-0 is-error">
                                                {{ errors[0] }}
                                            </p>
                                            <currency-input
                                                class="el-input__inner"
                                                :class="{
                                                    'is-error':
                                                        form.errors.has(
                                                            'requested_amount'
                                                        ) || errors[0]
                                                }"
                                                v-model="
                                                    application.lead
                                                        .dollar_requested_amount
                                                "
                                                @change="
                                                    value =>
                                                        (application.lead.requested_amount =
                                                            value * 100)
                                                "
                                            />
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- when to start -->
                        <div class="col-12 mb-4">
                            <div class="row">
                                <div
                                    class="col-12 ml-0 col-md-5 d-flex flex-column flex-md-row align-items-center"
                                >
                                    <label class="mb-1" for="start_date"
                                        >When would you like your 107 Day Plan
                                        to start</label
                                    >
                                </div>

                                <div class="col-12 col-md-4">
                                    <div style="min-width: 280px;">
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            name="start_date"
                                            rules="required|date"
                                        >
                                            <p class="mb-0 is-error">
                                                {{ errors[0] }}
                                            </p>
                                            <el-date-picker
                                                format="dd/MM/yyyy"
                                                value-format="yyyy-MM-dd"
                                                class="w-100"
                                                type="date"
                                                :class="{
                                                    'is-error': form.errors.has(
                                                        'start_date'
                                                    )
                                                }"
                                                v-model="
                                                    application.lead.start_date
                                                "
                                            >
                                            </el-date-picker>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- funding req for -->
                        <div class="col-12 mb-4">
                            <div class="row">
                                <div
                                    class="col-12 ml-0 col-md-5 d-flex flex-column flex-md-row align-items-center"
                                >
                                    <label class="mb-1" for="fund_required_for"
                                        >Funding required for</label
                                    >
                                </div>

                                <div class="col-12 col-md-4">
                                    <div style="min-width: 280px;">
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            name="fund_purpose_ids"
                                            rules="option_required"
                                        >
                                            <p class="mb-0 is-error">
                                                {{ errors[0] }}
                                            </p>
                                            <el-select
                                                v-model="
                                                    application.lead
                                                        .fund_purpose_ids
                                                "
                                                class="w-100 funds-req-select"
                                                multiple
                                            >
                                                <el-option
                                                    v-for="purpose in fundPurposes"
                                                    :key="purpose.id"
                                                    :value="purpose.id"
                                                    :label="purpose.name"
                                                ></el-option>
                                            </el-select>
                                        </ValidationProvider>
                                        <el-input
                                            v-model="
                                                application.lead.other_purpose
                                            "
                                            class="mt-1"
                                            v-if="
                                                application.lead
                                                    .fund_purpose_ids &&
                                                    application.lead.fund_purpose_ids.filter(
                                                        fp => fp == 4
                                                    ).length > 0
                                            "
                                        ></el-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- start buttons -->
                <div
                    class="d-flex flex-md-row flex-column align-items-center justify-content-between mb-5 px-3 px-md-0"
                >
                    <button
                        class="btn-white-green-text mutable-mobile-width order-2 order-md-1"
                        @click="$emit('previous', true)"
                    >
                        Back
                    </button>

                    <button
                        type="submit"
                        class="btn btn-primary mutable-mobile-width order-1 order-md-2 mb-3 mb-md-0">
                        Confirm and continue
                    </button>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import Supplier from "../../../models/Supplier";
import Application from "../../../models/Application";
import Lead from "../../../models/Lead";
import ApplicationLead from "../../../models/ApplicationLead";
import axios from "axios";
import Form from "form-backend-validation";
import CreateLead from "../../../modules/CreateLead";
export default {
    props: {
        initial_supplier: {
            type: Object,
            required: true
        },
        initial_application: {
            type: Object,
            required: true
        },
        step: {
            type: Number,
            required: true
        },
        fundPurposes: {
            type: Array,
            required: true
        },
        leadForm: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            application: this.initial_application,
            supplier: this.initial_supplier,
            form: new Form()
        };
    },
    mounted() {
        if (this.application.lead && this.application.lead.fund_purposes)
            this._parsedArray();
    },
    methods: {
        _parsedArray() {
            this.application.lead.fund_purpose_ids = this.application.lead.fund_purposes.map(
                fp => fp.id
            );
        },
        async _saveLead() {
            try {
                this.form.clear();
                const res = await axios.post(
                    `/api/v1/customer/suppliers/${this.supplier.id}/applications/${this.application.id}/application_leads`,
                    this.application.lead
                );
                this.application = res.data;
                this._parsedArray();

                this.$emit("update-application", this.application);
                this.$emit("next", true);
            } catch (err) {
                console.error(err);
                this.form.errors.record(err.response.data.errors);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
        },
        async _createLead() {
            try {
                this.form.clear();
                const createLead = new CreateLead(
                    this.supplier,
                    this.application,
                    this.application.customer
                );
                const lead = await createLead.save();
                const application = await createLead.getApplication(
                    lead.applications[0]
                );
                this.application = application;
                this.$emit("update-application", this.application);
                this.$emit("next", true);
            } catch (err) {
                console.error(err);
                this.form.errors.record(err.response.data.errors);
                this.$emit("next", true); // allow to go next if validation error happened in next page;
            }
        },
        async save() {
            if (this.application.id) this._saveLead();
            else {
                if (this.createNow) this._createLead();
                else {
                    this.application.lead.lead_form_id = this.leadForm.id;
                    this.application.lead.fund_purposes = this.application.lead.fund_purpose_ids.map(
                        fund_purpose_id => {
                            return { id: fund_purpose_id };
                        }
                    );
                    this.$store.commit("Lead/setLead", this.application.lead);
                    this.$emit("update-application", this.application);
                    this.$emit("next", true);
                }
            }
        }
    },
    computed: {
        createNow() {
            return (
                this.application.customer.email &&
                this.application.customer.first_name &&
                this.application.customer.phone
            );
        }
    }
};
</script>
