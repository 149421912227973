<template>
    <transition name="slide-fade">
        <div
            ref="email_variables_view"
            class="sidebar_view"
            id="email_variables_view"
            v-if="open"
        >
            <div class="sidebar-body">
                <div class="row mb-3">
                    <div class="col-6">
                        <h3>Email Variables</h3>
                    </div>
                    <div
                        class="col-6 d-flex align-items-center justify-content-end"
                    >
                        <a @click.prevent="open = false" href="#">
                            <i class="fas fa-times text-muted"></i>
                        </a>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-5">
                        <el-input
                            type="text"
                            v-model="newKey"
                            placeholder="Key"
                        >
                        </el-input>
                    </div>
                    <div class="col">
                        <el-input
                            v-model="newValue"
                            placeholder="Value"
                        ></el-input>
                    </div>
                    <div
                        class="col-1 d-flex justify-content-start align-items-center"
                    >
                        <a @click.prevent="addVariable" href="#">
                            <i class="fas fa-plus text-blue"> </i
                        ></a>
                    </div>
                </div>
                <div
                    class="row row-table py-3"
                    v-for="(variable, index) in variables"
                    :key="index"
                >
                    <div class="col-5">
                        <span v-if="variable.types == 0">{{
                            variable.name
                        }}</span>
                        <el-input
                            v-model="variable.name"
                            placeholder="Key name"
                            v-else
                            @change="updateContent(variable, index)"
                        ></el-input>
                    </div>
                    <div class="col" v-if="variable.types != 0">
                        <el-input
                            v-model="variable.value"
                            placeholder="value"
                            @change="updateContent(variable, index)"
                        ></el-input>
                    </div>
                    <div class="col-7" v-else-if="variable.types == 0">
                        <span class="text-muted">Automation Value</span>
                    </div>

                    <div
                        class="col-1 d-flex justify-content-end align-items-center"
                        v-if="variable.types != 0"
                    >
                        <a
                            @click.prevent="deleteVariable(variable, index)"
                            href="#"
                        >
                            <i class="fas fa-trash text-danger"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Supplier from "../../../models/Supplier";
import EmailVariable from "../../../models/EmailVariable";

export default {
    props: {
        openDrawer: {
            require: true,
            type: Boolean
        },
        supplier: {
            require: true,
            type: Object
        }
    },
    async mounted() {
        const res = await new EmailVariable()
            .for(new Supplier(this.supplier))
            .get();

        this.variables = res;
    },
    data() {
        return {
            open: this.openDrawer,
            variables: [],
            newKey: null,
            newValue: null
        };
    },
    methods: {
        async deleteVariable(variable, index) {
            try {
            } catch (err) {
                console.error(err);
            }
        },
        async addVariable() {
            try {
                let variable = {
                    value: this.newValue,
                    tenant_id: this.supplier.id,
                    name: this.newKey
                };
                variable = await this.save(variable);
                this.variables.unshift(variable);
                this.newKey = null;
                this.newValue = null;
                this.$emit("updated-variables", true);
            } catch (err) {
                var errorText = "";
                _.forEach(err.response.data.errors, function(value) {
                    errorText += "" + value + " ";
                });
                this.$message({
                    type: "error",
                    message: errorText
                });
            }
        },
        async updateContent(variable, index) {
            try {
                if (variable.name) await this.save(variable);
                this.$emit("updated-variables", true);
            } catch (err) {
                console.error(err);
            }
        },
        async save(variable) {
            return new Promise(async (resolve, reject) => {
                try {
                    const res = await new EmailVariable(variable)
                        .for(new Supplier(this.supplier))
                        .save();
                    resolve(res);
                } catch (err) {
                    reject(err);
                }
            });
        }
    },
    watch: {
        openDrawer(to, from) {
            this.open = to;
        },
        open(to, from) {
            this.$emit("update:openDrawer", to);
        }
    }
};
</script>
