<template>
    <div class="container-fluid bg-white card py-3 my-3">
        <div class="d-flex justify-content-between align-items-center">
            <h2 class="mb-3">Supplier Contact</h2>
            <el-button type="primary" @click="addUser">
                <i class="fas fa-user-plus"></i>
            </el-button>
        </div>
        <div class="row">
            <supplier-user-table
                class="col-12"
                :supplier="supplier"
                @update-user="editUser"
                @delete-user="deleteUser"
            >
            </supplier-user-table>
        </div>
        <edit-user
            :supplier="supplier"
            :initial_user="selectedUser"
            :is-open.sync="opened"
            :key="keyModal"
            @saved="saved"
            @close="closed"
        >
        </edit-user>
    </div>
</template>

<script>
import Supplier from "@/backend/models/Supplier";
import User from "@/backend/models/User";
export default {
    props: {
        supplier: {
            type: Object
        }
    },
    data() {
        return {
            timeout: null,
            opened: false,
            keyModal: 0,
            selectedUser: null
        };
    },
    methods: {
        closed() {
            this.selectedUser = null;
            this.opened = false;
            this.keyModal = 0;
        },
        saved(value) {
            this.opened = false;
            this.keyModal = 0;
            const event = new CustomEvent("update-user-data", {
                detail: true
            });
            document.dispatchEvent(event);
        },
        addUser(user) {
            this.selectedUser = null;
            this.keyModal = 0;
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.opened = true;
            }, 50);
        },
        editUser(user) {
            this.selectedUser = user;
            this.keyModal = user.id;
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.opened = true;
            }, 50);
        },
        async deleteUser(user) {
            try {
                const res = await new User(user)
                    .for(new Supplier(this.supplier))
                    .delete();
                const event = new CustomEvent("update-user-data", {
                    detail: true
                });
                document.dispatchEvent(event);
            } catch (err) {
                console.error(err);
            }
        }
    }
};
</script>
