<template>
    <div>
        <div class="container-fluid px-0">
            <div class="row py-3">
                <div
                    class="col-8 d-flex justify-content-between align-items-center"
                >
                    <h1 class="mb-0">Lead</h1>
                </div>
                <div
                    class="col-4 d-flex justify-content-end align-items-center"
                >
                    <el-button
                        @click="openModal = true"
                        v-if="lead.id"
                        type="info"
                        class="mr-2"
                    >
                        <i class="fas fa-envelope mr-3"></i>
                        Send Email
                    </el-button>

                    <el-popconfirm
                        confirm-button-text="Delete"
                        cancel-button-text="Don't Delete"
                        icon="far fa-times-circle"
                        icon-color="#F56C6C"
                        title="Are you sure to delete this lead?"
                        @onConfirm="deleteLead"
                        v-if="lead.id"
                    >
                        <el-button
                            type="danger"
                            v-if="lead.id"
                            class="mr-2"
                            slot="reference"
                        >
                            Delete
                        </el-button>
                    </el-popconfirm>

                    <!-- <el-button type="primary" @click="handleSubmit">
                        Save
                    </el-button> -->
                    <el-dropdown
                        split-button
                        type="primary"
                        @click="handleSubmit"
                        @command="handleItemClicked"
                    >
                        Save
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                command="disable_email"
                                v-if="!lead.disable_email"
                            >
                                <i class="fas fa-times"></i>
                                Disable Email</el-dropdown-item
                            >
                            <el-dropdown-item
                                command="enable_email"
                                v-else-if="lead.disable_email"
                            >
                                <i class="fas fa-check"></i>
                                Enable Email</el-dropdown-item
                            >
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>

        <div class="container-fluid bg-white">
            <div class="row py-3">
                <div class="form-group col-md-6">
                    <label for="name">First Name</label>
                    <el-input
                        placeholder="John"
                        v-model="lead.customer.first_name"
                    ></el-input>
                </div>
                <div class="form-group col-md-6">
                    <label for="name">Last Name</label>
                    <el-input
                        placeholder="Doe"
                        v-model="lead.customer.last_name"
                    ></el-input>
                </div>
                <div class="form-group col-md-6">
                    <label for="email">Email</label>
                    <el-input
                        placeholder="Email"
                        v-model="lead.customer.email"
                    ></el-input>
                </div>
                <div class="form-group col-md-6">
                    <label for="phone">Phone</label>

                    <vue-tel-input
                        mode="international"
                        :class="['w-100 form-control-borders']"
                        :onlyCountries="['AU', 'GB', 'NZ', 'US']"
                        style="min-height:38px !important"
                        v-model="lead.customer.phone"
                    ></vue-tel-input>
                </div>
                <div class="form-group col-md-6">
                    <label for="requested_amount">Requested Amount</label>
                    <!-- <el-input
                        placeholder="Amount"
                        type="number"
                        v-model="lead.dollar_requested_amount"
                    ></el-input> -->
                    <currency-input
                        class="el-input__inner"
                        placeholder="Amount"
                        v-model="lead.dollar_requested_amount"
                    />
                </div>
                <div class="form-group col-md-6">
                    <label for="property_address">Property Address</label>
                    <el-input
                        placeholder="Property Address"
                        v-model="
                            lead.extra_attributes.address.formatted_address
                        "
                    ></el-input>
                </div>
                <div class="form-group col-md-6">
                    <label for="sales_representative"
                        >Sales Representative</label
                    >
                    <el-input
                        placeholder="Sales Representative"
                        v-model="lead.sales_representative"
                    ></el-input>
                </div>

                <div class="form-group col-md-6 d-flex flex-column">
                    <label for="start_date">Estimated Start Date</label>
                    <el-date-picker
                        class="w-100"
                        placeholder="Estimated Start Date"
                        type="date"
                        v-model="lead.start_date"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                </div>
                <div class="form-group col-md-6 d-flex flex-column">
                    <label for="fund_purpose_ids">Fund Purpose</label>
                    <el-select
                        v-model="lead.fund_purpose_ids"
                        class="w-100"
                        multiple
                    >
                        <el-option
                            v-for="purpose in fundPurposes"
                            :key="purpose.id"
                            :value="purpose.id"
                            :label="purpose.name"
                        ></el-option>
                    </el-select>
                    <el-input
                        type="text"
                        class="mt-3"
                        v-if="
                            lead.fund_purpose_ids &&
                                lead.fund_purpose_ids.filter(id => id == 4)
                                    .length > 0
                        "
                        v-model="lead.other_purpose"
                        placeholder="Other Purpose"
                    >
                    </el-input>
                </div>
            </div>
        </div>

        <lead-email-queue-index
            :supplier="supplier"
            :lead="lead"
            v-if="lead.id"
        >
        </lead-email-queue-index>
        <email-form
            :lead="lead"
            v-if="lead.id"
            :supplier="supplier"
            :open-modal.sync="openModal"
        >
        </email-form>
    </div>
</template>
<script>
import Supplier from "../../models/Supplier";
import LeadForm from "../../models/LeadForm";
import Lead from "../../models/Lead";
export default {
    props: {
        supplier: {
            type: Object,
            required: true
        },
        leadForms: {
            type: Array,
            required: true
        },
        fundPurposes: {
            type: Array,
            required: true
        },
        initial_lead: {
            type: Object,
            default: () => {
                return {
                    lead_form_id: null,
                    sales_representative: null,
                    customer: {
                        first_name: "",
                        last_name: "",
                        email: "",
                        phone: ""
                    },
                    requested_amount: 0,
                    dollar_requested_amount: 0,
                    fund_purpose_ids: [],
                    other_purpose: "",
                    extra_attributes: {
                        address: { formatted_address: "" }
                    }
                };
            }
        }
    },
    data() {
        return {
            openModal: false,
            lead: this.initial_lead
        };
    },
    mounted() {
        if (this.lead.fund_purposes) {
            this.lead.fund_purpose_ids = this.lead.fund_purposes.map(
                fp => fp.id
            );
        }

        // check for empty object extra attributes
        if (
            this.lead.extra_attributes &&
            Array.isArray(this.lead.extra_attributes) &&
            this.lead.extra_attributes.length == 0
        ) {
            this.lead.extra_attributes = { address: { formatted_address: "" } };
        }
    },
    methods: {
        handleItemClicked(value) {
            if (value == "disable_email") this.lead.disable_email = true;
            else if (value == "enable_email") this.lead.disable_email = false;
            this.handleSubmit();
        },
        handleSubmit() {
            this.lead.requested_amount =
                this.lead.dollar_requested_amount * 100;

            // assign supplier lead form id
            if (!this.lead.lead_form_id)
                this.lead.lead_form_id = this.leadForms[0].id;
            new Lead(this.lead)
                .for(new Supplier(this.supplier))
                .save()
                .then(res => {
                    this.$message({
                        type: "success",
                        message: "Saved"
                    });

                    if (!this.initial_lead.id) {
                        window.location.replace(
                            `/admin/suppliers/${this.supplier.id}/leads/${res.id}/edit`
                        );
                        window.history.pushState(
                            null,
                            null,
                            `/admin/suppliers/${this.supplier.id}/leads/${res.id}/edit`
                        );
                    }
                })
                .catch(err => {
                    console.error(err);
                    var errorText = "";
                    _.forEach(err.response.data.errors, function(value) {
                        errorText += "" + value + " ";
                    });
                    this.$message({
                        type: "error",
                        message: errorText
                    });
                });
        },
        async deleteLead() {
            try {
                await new Lead(this.lead)
                    .for(new Supplier(this.supplier))
                    .delete();
                window.location.replace(
                    `/admin/suppliers/${this.supplier.id}/edit`
                );
                window.history.pushState(
                    null,
                    null,
                    `/admin/suppliers/${this.supplier.id}/edit`
                );
            } catch (err) {
                console.error(err);
            }
        }
    }
};
</script>
