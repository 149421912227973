var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "container-fluid mk-application pb-5",
        staticStyle: { "padding-top": "20px" }
      },
      [
        _c("div", { staticClass: "row mb-4" }, [
          _c("div", { staticClass: "col-12 elv-progress-bar" })
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "container-fluid bg-white card application-card mk-introduction"
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 position-relative" }, [
                _c("div", { staticClass: "app-card-decoration" }),
                _vm._v(" "),
                _c("h1", { staticClass: "mb-4" }, [_vm._v("Welcome")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12" }, [
                _c("p", { staticClass: "app-card-intro" }, [
                  _c("strong", [
                    _vm._v(
                      "\n                        You're application has been submitted and in\n                        processed.\n                    "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", {}, [
                  _vm._v(
                    "\n                    If you have any questions or need to review your\n                    application please contact\n                    "
                  ),
                  _c(
                    "a",
                    { attrs: { href: "mailto:hello@elevarepayeasy.com.au" } },
                    [
                      _vm._v(
                        "\n                        hello@elevarepayeasy.com.au\n                    "
                      )
                    ]
                  )
                ])
              ])
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }