var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mk-application-form" }, [
    _c(
      "div",
      {
        staticClass: "container-fluid",
        staticStyle: { "padding-top": "20px" }
      },
      [
        _c("div", { staticClass: "row mb-4" }, [
          _c(
            "div",
            { staticClass: "col-12 elv-progress-bar" },
            [
              _c("el-progress", {
                staticClass: "w-100 pr-0 mr-0",
                attrs: { percentage: _vm.progress, format: _vm.format }
              })
            ],
            1
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "container-fluid pb-5 px-0 px-sm-3" },
      [
        _vm.step == 1
          ? _c("introduction", {
              key: "introduction",
              attrs: { step: _vm.step },
              on: { next: _vm.next }
            })
          : _vm.step == 2
          ? _c("create-lead", {
              key: "lead",
              attrs: {
                step: _vm.step,
                initial_application: _vm.application,
                initial_supplier: _vm.supplier,
                "lead-form": _vm.leadForm,
                "fund-purposes": _vm.fundPurposes
              },
              on: {
                next: _vm.next,
                previous: _vm.before,
                "update-application": function(value) {
                  return (_vm.application = value)
                }
              }
            })
          : _vm.step == 3
          ? _c("lead-identity", {
              key: "identify",
              attrs: {
                step: _vm.step,
                initial_application: _vm.application,
                initial_supplier: _vm.supplier
              },
              on: {
                next: _vm.next,
                previous: _vm.before,
                "update-application": function(value) {
                  return (_vm.application = value)
                }
              }
            })
          : _vm.step == 4 &&
            !_vm.application.skip_identity &&
            _vm.linkToFace &&
            !_vm.application.media
              .filter(function(m) {
                return (
                  m.collection_name == "face_proof" ||
                  m.collection_name == "documents"
                )
              })
              .pop()
          ? _c("verify-identity", {
              key: "verify_identity",
              attrs: {
                step: _vm.step,
                initial_application: _vm.application,
                initial_supplier: _vm.supplier,
                "link-to-face": _vm.linkToFace
              },
              on: {
                previous: _vm.before,
                "update-application": function(value) {
                  return (_vm.application = value)
                }
              }
            })
          : _vm.step == 4 &&
            _vm.linkToFace &&
            !_vm.application.skip_identity &&
            _vm.application.media
              .filter(function(m) {
                return (
                  m.collection_name == "face_proof" ||
                  m.collection_name == "documents"
                )
              })
              .pop()
          ? _c("step2-identity", {
              key: "step2_identity",
              attrs: {
                step: _vm.step,
                initial_application: _vm.application,
                initial_supplier: _vm.supplier,
                "link-to-face": _vm.linkToFace
              },
              on: {
                next: _vm.next,
                previous: _vm.before,
                "update-application": function(value) {
                  return (_vm.application = value)
                }
              }
            })
          : _vm.step == 4 && _vm.application.skip_identity
          ? _c("skiping-identity", {
              on: { next: _vm.next, previous: _vm.before }
            })
          : _vm.step == 5
          ? _c("property", {
              key: "property",
              attrs: {
                step: _vm.step,
                initial_application: _vm.application,
                initial_supplier: _vm.supplier
              },
              on: {
                next: _vm.next,
                previous: _vm.before,
                "update-application": function(value) {
                  return (_vm.application = value)
                }
              }
            })
          : _vm.step == 6
          ? _c("personal-declaration", {
              key: "personal-declaration",
              attrs: {
                step: _vm.step,
                initial_application: _vm.application,
                initial_supplier: _vm.supplier,
                "income-ranges": _vm.incomeRanges,
                "employment-types": _vm.employmentTypes,
                "employment-durations": _vm.employmentDurations
              },
              on: {
                next: _vm.next,
                previous: _vm.before,
                "update-application": function(value) {
                  return (_vm.application = value)
                }
              }
            })
          : _vm.step == 7
          ? _c("application-summary", {
              key: "app-summary",
              attrs: {
                step: _vm.step,
                initial_application: _vm.application,
                initial_supplier: _vm.supplier
              },
              on: { next: _vm.next, previous: _vm.before }
            })
          : _vm.step == 8
          ? _c("thank-you")
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }