import Supplier from "../models/Supplier";
import Application from "../models/Application";
import Lead from "../models/Lead";

export default class CreateLead {

    constructor(supplier, application, customer) {
        this.supplier = supplier;
        this.application = application;
    }

    async save() {
        return new Promise(async (resolve, reject) => {
            try {
                this.application.lead.source = "application"; // indicating this lead sourced from application embeded;
                const res = await new Lead({
                        ...this.application.lead,
                        customer: this.application.customer,
                        disable_email: true
                    })
                    .for(new Supplier(this.supplier))
                    .save();
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    async getApplication(newApplication) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await new Application()
                    .for(new Supplier(this.supplier))
                    .find(newApplication.id);

                this.application = res;
                this.application.lead.fund_purpose_ids = this.application.lead.fund_purposes.map(
                    fp => fp.id
                );
                resolve(this.application);
            } catch (err) {
                reject(err);
            }
        });
    }
}
